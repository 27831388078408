import { QueryDocumentSnapshot } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext } from 'react';

import { VentureDoc } from '../../collections/Ventures';

type Props = {
  ventureSnap: QueryDocumentSnapshot<VentureDoc>;
};

const VentureSnapContext = createContext<null | QueryDocumentSnapshot<VentureDoc>>(null);

export const useVentureSnap = () => {
  const ctx = useContext(VentureSnapContext);

  if (!ctx) {
    throw new Error('VentureSnapProvider');
  }

  return ctx;
};

export default function VentureSnapProvider({
  children,
  ventureSnap,
}: PropsWithChildren<Props>) {
  return (
    <VentureSnapContext.Provider value={ventureSnap}>
      {children}
    </VentureSnapContext.Provider>
  );
}
