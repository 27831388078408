import { QueryDocumentSnapshot } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext } from 'react';

import { MessageDoc } from '../../collections/Messages';

type Props = {
  messageSnap: QueryDocumentSnapshot<MessageDoc>;
};

const MessageSnapContext = createContext<null | QueryDocumentSnapshot<MessageDoc>>(null);

export const useMessageSnap = () => {
  const ctx = useContext(MessageSnapContext);

  if (!ctx) {
    throw new Error('MessageSnapProvider');
  }

  return ctx;
};

export default function MessageSnapProvider({
  children,
  messageSnap,
}: PropsWithChildren<Props>) {
  return (
    <MessageSnapContext.Provider value={messageSnap}>
      {children}
    </MessageSnapContext.Provider>
  );
}
