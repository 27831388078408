import { Badge, BadgeProps } from '@chakra-ui/react';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CupSize from '../../common/CupSize';
import Gender from '../../common/Gender';
import Catch from '../Catch';
import { useProfileSnap } from '../snapProviders/ProfileSnapProvider';

export type Props = BadgeProps;

export function CupSizeBadgeMain({
  ...badgeProps
}: Props) {
  const profileSnap = useProfileSnap();
  const profileDoc = useMemo(() => profileSnap.data(), [profileSnap]);

  const { t } = useTranslation('CupSize');

  if (profileDoc.gender !== Gender.FEMALE) {
    return null;
  }

  switch (profileDoc.cupSize) {
    case CupSize.AA: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('AA.label')}
        </Badge>
      );
    }
    case CupSize.A: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('A.label')}
        </Badge>
      );
    }
    case CupSize.B: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('B.label')}
        </Badge>
      );
    }
    case CupSize.C: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('C.label')}
        </Badge>
      );
    }
    case CupSize.D: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('D.label')}
        </Badge>
      );
    }
    case CupSize.DD: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('DD.label')}
        </Badge>
      );
    }
    case CupSize.E: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('E.label')}
        </Badge>
      );
    }
    default: {
      return null;
    }
  }
}

export default function CupSizeBadge(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <CupSizeBadgeMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
