import { Helmet } from 'react-helmet';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import ChatScreen from './screens/ChatScreen';
import ChatsScreen from './screens/ChatsScreen';
import CheckMyProfileSnapLayout from './screens/CheckMyProfileSnapLayout';
import CheckProfileRefLayout from './screens/CheckProfileRefLayout';
import ConversationParticipantsScreen from './screens/ConversationParticipantsScreen';
import DestinationCreateScreen from './screens/DestinationCreateScreen';
import DestinationUpdateScreen from './screens/DestinationUpdateScreen';
import LoginScreen from './screens/LoginScreen';
import MainLayout from './screens/MainLayout';
import MyProfileScreen from './screens/MyProfileScreen';
import MyProfileUpdateScreen from './screens/MyProfileUpdateScreen';
import ProfileScreen from './screens/ProfileScreen';
import ReservationCreateInitScreen from './screens/ReservationCreateInitScreen';
import ReservationCreateScreen from './screens/ReservationCreateScreen';
import ReservationPaymentScreen from './screens/ReservationPaymentScreen';
import ReservationPaymentSuccessScreen from './screens/ReservationPaymentSuccessScreen';
import ReservationsScreen from './screens/ReservationsScreen';
import SettingsScreen from './screens/SettingsScreen';
import TelegramLoginScreen from './screens/TelegramLoginScreen';
import TripCreateInitScreen from './screens/TripCreateInitScreen';
import TripCreateScreen from './screens/TripCreateScreen';
import TripPaymentScreen from './screens/TripPaymentScreen';
import TripPaymentSuccessScreen from './screens/TripPaymentSuccessScreen';
import TripsScreen from './screens/TripsScreen';
import VentureCreateInitScreen from './screens/VentureCreateInitScreen';
import VentureCreateScreen from './screens/VentureCreateScreen';
import VenturesScreen from './screens/VenturesScreen';
import VenueCreateScreen from './screens/VenueCreateScreen';
import VenuesScreen from './screens/VenuesScreen';
import VenueUpdateScreen from './screens/VenueUpdateScreen';
import WelcomeScreen from './screens/WelcomeScreen';

export default function Navigation() {
  return (
    <>
      <Helmet defaultTitle="Qupidu - Exclusive Travel Dating" titleTemplate="%s | Qupidu" />

      <BrowserRouter>
        <Routes>
          <Route element={<LoginScreen />} path="login" />
          <Route element={<TelegramLoginScreen />} path="telegram/login" />

          <Route element={<CheckProfileRefLayout fallback={<Navigate replace to="/login" />} telegramFallback={<Navigate replace to="/telegram/login" />} />}>
            <Route element={<WelcomeScreen />} path="welcome" />

            <Route element={<CheckMyProfileSnapLayout fallback={<Navigate replace to="/welcome" />} />}>
              <Route element={<MainLayout />} path="/">
                <Route element={<Navigate replace to="trips" />} index />

                <Route path="trips">
                  <Route element={<TripsScreen />} index />

                  <Route path=":tripId">
                    <Route element={<TripsScreen />} index />

                    <Route path="payment">
                      <Route element={<TripPaymentScreen />} index />
                      <Route element={<TripPaymentSuccessScreen />} path="success" />
                    </Route>
                  </Route>

                  <Route path="add">
                    <Route element={<TripCreateInitScreen />} index />
                    <Route element={<TripCreateScreen />} path=":destinationId" />
                  </Route>
                </Route>

                <Route path="reservations">
                  <Route element={<ReservationsScreen />} index />

                  <Route path=":reservationId">
                    <Route element={<ReservationsScreen />} index />

                    <Route path="payment">
                      <Route element={<ReservationPaymentScreen />} index />
                      <Route element={<ReservationPaymentSuccessScreen />} path="success" />
                    </Route>
                  </Route>

                  <Route path="add">
                    <Route element={<ReservationCreateInitScreen />} index />
                    <Route element={<ReservationCreateScreen />} path=":venueId" />
                  </Route>
                </Route>

                <Route path="ventures">
                  <Route element={<VenturesScreen />} index />

                  <Route path="add">
                    <Route element={<VentureCreateInitScreen />} index />
                    <Route element={<VentureCreateScreen />} path=":venueId" />
                  </Route>
                </Route>

                <Route path="chats">
                  <Route element={<ChatsScreen />} index />

                  <Route path=":conversationId">
                    <Route element={<ChatScreen />} index />
                    <Route element={<ConversationParticipantsScreen />} path="participants" />
                  </Route>
                </Route>

                <Route path="profile">
                  <Route element={<MyProfileScreen />} index />
                  <Route element={<MyProfileUpdateScreen />} path="update" />
                  <Route element={<SettingsScreen />} path="settings" />
                </Route>

                <Route path="profiles">
                  <Route element={<Navigate replace to="/" />} index />
                  <Route element={<ProfileScreen />} path=":profileId" />
                </Route>

                <Route path="venues">
                  <Route element={<VenuesScreen />} index />
                  <Route element={<VenueCreateScreen />} path="add" />
                  <Route element={<VenueUpdateScreen />} path=":venueId" />
                </Route>

                <Route path="destinations">
                  <Route element={<DestinationCreateScreen />} path="add" />
                  <Route element={<DestinationUpdateScreen />} path=":destinationId" />
                </Route>

                <Route element={<Navigate replace to="/" />} path="applications/*" />
                <Route element={<Navigate replace to="/" />} path="invitations/*" />
                <Route element={<Navigate replace to="/" />} path="conversations/*" />
                <Route element={<Navigate replace to="/" />} path="plans/*" />
                <Route element={<Navigate replace to="/" />} path="wishlist/*" />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
