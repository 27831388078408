import {
  Center,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import { Suspense } from 'react';
import { LuSkull } from 'react-icons/lu';
import { useFirestoreDoc } from 'reactfire';

import { ProfileDoc } from '../../collections/Profiles';
import Catch from '../../components/Catch';
import LogoIcon from '../../components/LogoIcon';
import ProfileCard, { Props as ProfileCardProps } from '../../components/ProfileCard';
import ErrorFallbackScreen from '../ErrorFallbackScreen';

export type Props = {
  profileRef: DocumentReference<ProfileDoc>;
} & Omit<ProfileCardProps, 'profileSnap'>;

export function ProfileItemMain({ profileRef, ...profileCardProps }: Props) {
  const { data: profileSnap } = useFirestoreDoc(profileRef);

  if (profileSnap.exists()) {
    return (
      <ProfileCard
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...profileCardProps}
        profileSnap={profileSnap}
      />
    );
  }

  return (
    <Center height="100%">
      <VStack>
        <Icon as={LuSkull} boxSize={16} />
        <Text>Profile does not exist</Text>
      </VStack>
    </Center>
  );
}

export default function ProfileItem(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <ProfileItemMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
