import { VStack } from '@chakra-ui/react';
import { RecaptchaVerifier, User } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'reactfire';

import { getProfileHiddenRef, useProfilesCollectionRef } from '../../../collections/Profiles';
import i18n from '../../../i18n';
import PhoneNumberForm, { PhoneNumberFormLoading } from './PhoneNumberForm';
import VerificationCodeForm from './VerificationCodeForm';

export default function Login() {
  const auth = useAuth();
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier>();

  useEffect(
    () => {
      const rv = new RecaptchaVerifier(
        auth,
        'recaptcha-container',
        {
          size: 'invisible',
          theme: 'dark',
        },
      );
      setRecaptchaVerifier(rv);

      return () => {
        rv.clear();
      };
    },
    [auth],
  );

  const [
    phoneNumberFormResponse,
    setPhoneNumberFormResponse,
  ] = useState<{
    countryCode: string;
    phoneNumber: string;
    verificationId: string;
  }>();

  const onPhoneNumberFormComplete = useCallback(
    (value: {
      countryCode: string;
      phoneNumber: string;
      verificationId: string;
    }) => {
      setPhoneNumberFormResponse(value);
    },
    [],
  );

  const profilesCollectionRef = useProfilesCollectionRef();

  const navigate = useNavigate();

  const onVerificationCodeFormComplete = useCallback(
    (values: { user: User }) => {
      getDoc(
        getProfileHiddenRef(
          doc(profilesCollectionRef, values.user.uid),
        ),
      )
        // eslint-disable-next-line consistent-return
        .then((profileHiddenSnap) => {
          const profileHiddenDoc = profileHiddenSnap.data();

          if (profileHiddenDoc?.appLanguage) {
            return i18n.changeLanguage(profileHiddenDoc.appLanguage);
          }
        })
        .then(() => navigate('/'))
        .catch(() => { });
    },
    [navigate, profilesCollectionRef],
  );

  const onVerificationCodeFormCancel = useCallback(
    () => {
      setPhoneNumberFormResponse(undefined);
    },
    [],
  );

  return (
    <VStack alignItems="stretch" gap={2}>
      {!recaptchaVerifier ? (
        <PhoneNumberFormLoading />
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {!phoneNumberFormResponse ? (
            <PhoneNumberForm
              onComplete={onPhoneNumberFormComplete}
              recaptchaVerifier={recaptchaVerifier}
            />
          ) : (
            <VerificationCodeForm
              onCancel={onVerificationCodeFormCancel}
              onComplete={onVerificationCodeFormComplete}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...phoneNumberFormResponse}
            />
          )}
        </>
      )}
    </VStack>
  );
}
