import { QuerySnapshot } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext } from 'react';

import { ConversationDoc } from '../../collections/Conversations';

type Props = {
  conversationsSnap: QuerySnapshot<ConversationDoc>;
};

const MyConversationsSnapContext = createContext<null | QuerySnapshot<ConversationDoc>>(null);

export const useMyConversationsSnap = () => {
  const ctx = useContext(MyConversationsSnapContext);

  if (!ctx) {
    throw new Error('MyConversationsSnapProviderProvider');
  }

  return ctx;
};

export default function MyConversationsSnapProvider({
  children,
  conversationsSnap,
}: PropsWithChildren<Props>) {
  return (
    <MyConversationsSnapContext.Provider value={conversationsSnap}>
      {children}
    </MyConversationsSnapContext.Provider>
  );
}
