import {
  AspectRatio,
  Card,
  Center,
  CircularProgress,
  Icon,
  IconButton,
  Image,
  Input,
} from '@chakra-ui/react';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { useField } from 'formik';
import mixpanel from 'mixpanel-browser';
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useRef,
  useState,
} from 'react';
import { LuImagePlus, LuTrash } from 'react-icons/lu';
import { useStorage, useUser } from 'reactfire';
import { v4 as uuid } from 'uuid';

import getBlurHash from '../../common/getBlurHash';
import getCroppedImage from '../../common/getCroppedImage';
import { getPhotoSizeUrl } from '../../common/getPhotoSizeUrl';
import loadImage from '../../common/loadImage';
import { StoragePicture } from '../../common/StoragePicture';
import { storageRefToImgixUrl } from '../../common/storageRefToImgixUrl';
import useBlur from '../../hooks/useBlur';
import useShowError from '../../hooks/useShowError';

interface Props {
  name: string;
}

export default function PictureInput({ name }: Props) {
  const { data: user } = useUser();

  if (!user) {
    throw new Error('Unauthenticated');
  }

  const showError = useShowError();

  const [input, , helper] = useField<StoragePicture | undefined>(name);

  const inputFile = useRef<HTMLInputElement>(null);

  const storage = useStorage();

  const [cropping, setCropping] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number | undefined>(undefined);
  const [imageDataUri, setImageDataUri] = useState<string | undefined>(undefined);

  const handleImageChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files?.length) {
        return;
      }

      const file: File = e.target.files[0];

      setUploadProgress(0);
      setImageDataUri(URL.createObjectURL(file));

      setCropping(true);
      const originalImage = await loadImage(URL.createObjectURL(file));
      const { blob: data, dataUrl } = await getCroppedImage(originalImage);
      const croppedImage = await loadImage(dataUrl);
      const blurHash = getBlurHash(croppedImage);
      setImageDataUri(dataUrl);
      setCropping(false);

      const dest = ref(storage, `assets/${user.uid}/${uuid()}.webp`);
      const uploadTask = uploadBytesResumable(dest, data, { contentType: 'image/webp' });

      uploadTask.on('state_changed', {
        complete: () => {
          helper
            .setValue({
              blurHash,
              imgixUrl: storageRefToImgixUrl({
                storage,
                storageRef: dest.toString(),
              }),
              storageRef: dest.toString(),
            })
            .then(() => {
              mixpanel.track('Picture Uploaded');
              setUploadProgress(undefined);
            })
            .catch(showError);
        },
        error: () => {
          setUploadProgress(undefined);
        },
        next: (snap) => {
          setUploadProgress(snap.bytesTransferred / snap.totalBytes);
        },
      });
    },
    [helper, showError, storage, user.uid],
  );

  const handleUploadButtonClick = useCallback(() => {
    if (inputFile.current === null) {
      return;
    }

    inputFile.current.click();
  }, [inputFile]);

  const handleRemoveButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      helper.setValue(undefined).catch(showError);
      e.stopPropagation();
    },
    [helper, showError],
  );

  const fallbackSrc = useBlur({
    blurHash: input.value?.blurHash ?? null,
    height: 32,
    width: 18,
  });

  return (
    <AspectRatio ratio={9 / 16} w="100%">
      <Card
        cursor="pointer"
        h={320}
        onClick={handleUploadButtonClick}
        overflow="hidden"
        position="relative"
        w={180}
      >
        {typeof uploadProgress === 'number' ? (
          <>
            {imageDataUri ? (
              <Image
                alt=""
                h="100%"
                objectFit="cover"
                position="absolute"
                src={imageDataUri}
                w="100%"
              />
            ) : null}

            <Center
              h="100%"
              position="absolute"
              w="100%"
              zIndex={10}
            >
              {cropping ? (
                <CircularProgress isIndeterminate />
              ) : (
                <CircularProgress value={uploadProgress} />
              )}
            </Center>
          </>
        ) : null}

        {typeof uploadProgress !== 'number' && input.value ? (
          <>
            <Image
              alt=""
              fallbackSrc={fallbackSrc}
              h="100%"
              objectFit="cover"
              src={getPhotoSizeUrl({
                faces: true,
                height: 320,
                uri: input.value.imgixUrl,
                width: 180,
              })}
              w="100%"
            />

            <IconButton
              aria-label="Remove"
              bottom={3}
              icon={<Icon as={LuTrash} />}
              onClick={handleRemoveButtonClick}
              position="absolute"
              right={3}
              zIndex={10}
            />
          </>
        ) : null}

        {typeof uploadProgress !== 'number' && !input.value ? (
          <Center h="100%">
            <Icon as={LuImagePlus} boxSize={6} />
          </Center>
        ) : null}

        <Input
          accept="image/*"
          id="file"
          onChange={(v) => { handleImageChange(v).catch(showError); }}
          ref={inputFile}
          style={{ display: 'none' }}
          type="file"
        />
      </Card>
    </AspectRatio>
  );
}
