import { Button, Icon } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LuCreditCard } from 'react-icons/lu';

import Gender from '../../common/Gender';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import useStripeCreateBillingPortalSession from '../../functions/useStripeCreateBillingPortalSession';

export default function BillingPortalButton() {
  const { t } = useTranslation('SettingsScreen', { keyPrefix: 'BillingPortalButton' });

  const [url, setUrl] = useState<null | string>();

  const stripeCreateBillingPortalSession = useStripeCreateBillingPortalSession();

  useEffect(
    () => {
      stripeCreateBillingPortalSession({
        returnUrl: 'https://app.qupidu.com/profile/settings',
      })
        .then(({ data }) => setUrl(data))
        .catch(() => { });
    },
    [stripeCreateBillingPortalSession],
  );

  const myProfileSnap = useMyProfileSnap();
  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  if (myProfileDoc?.gender !== Gender.MALE) {
    return null;
  }

  return (
    <Button
      as="a"
      href={url ?? undefined}
      isLoading={!url}
      justifyContent="start"
      leftIcon={<Icon as={LuCreditCard} />}
      loadingText={t('default')}
    >
      {t('default')}
    </Button>
  );
}
