import {
  AspectRatio,
  Box,
  HStack,
  Icon,
  Image,
  Skeleton,
} from '@chakra-ui/react';
import { Suspense, useMemo } from 'react';
import { TbMichelinBibGourmand, TbMichelinStar } from 'react-icons/tb';
import { Link } from 'react-router-dom';

import { VenueDistinction } from '../../../collections/Venues';
import { getPhotoSizeUrl } from '../../../common/getPhotoSizeUrl';
import VenueAlgoliaSearchRecord from '../../../common/VenueAlgoliaSearchRecord';
import Catch from '../../../components/Catch';
import useBlur from '../../../hooks/useBlur';
import VenueDetails from './VenueDetails';

export type Props = {
  venueRecord: VenueAlgoliaSearchRecord;
};

export function VenueMain({
  venueRecord,
}: Props) {
  const src = useMemo(
    () => getPhotoSizeUrl({
      height: 320,
      uri: venueRecord.pictures[0].imgixUrl,
      width: 180,
    }),
    [venueRecord.pictures],
  );

  const fallbackSrc = useBlur({
    blurHash: venueRecord.pictures[0].blurHash,
    height: 32,
    width: 18,
  });

  return (
    <AspectRatio ratio={9 / 16}>
      <Box
        as={Link}
        borderRadius="md"
        boxShadow="base"
        h="100%"
        overflow="hidden"
        position="relative"
        to={`./${venueRecord.objectID}`}
        w="100%"
      >
        <Image
          fallbackSrc={fallbackSrc}
          h="100%"
          objectFit="cover"
          position="absolute"
          src={src}
          w="100%"
        />

        <HStack gap={0} position="absolute" right={2} top={2}>
          {venueRecord.distinctions.includes(VenueDistinction.MICHELIN_BIB_GOURMAND) && (
            <Icon as={TbMichelinBibGourmand} />
          )}

          {venueRecord.distinctions.includes(VenueDistinction.MICHELIN_ONE_STAR) && (
            <Icon as={TbMichelinStar} />
          )}

          {venueRecord.distinctions.includes(VenueDistinction.MICHELIN_TWO_STAR) && (
            <>
              <Icon as={TbMichelinStar} />
              <Icon as={TbMichelinStar} />
            </>
          )}

          {venueRecord.distinctions.includes(VenueDistinction.MICHELIN_THREE_STAR) && (
            <>
              <Icon as={TbMichelinStar} />
              <Icon as={TbMichelinStar} />
              <Icon as={TbMichelinStar} />
            </>
          )}
        </HStack>

        <VenueDetails
          _dark={{
            backgroundColor: 'rgb(from var(--chakra-colors-black) r g b / 0.5)',
          }}
          _light={{
            backgroundColor: 'rgb(from var(--chakra-colors-white) r g b / 0.5)',
          }}
          backdropFilter="saturate(180%) blur(20px)"
          bottom={0}
          left={0}
          position="absolute"
          right={0}
          venueRecord={venueRecord}
        />
      </Box>
    </AspectRatio>
  );
}

export function VenueLoading() {
  return (
    <AspectRatio ratio={9 / 16}>
      <Skeleton h="100%" w="100%" />
    </AspectRatio>
  );
}

export default function Venue(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={<VenueLoading />}>
        <VenueMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
