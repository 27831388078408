import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';
import { deleteDoc } from 'firebase/firestore';
import {
  Suspense,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuSkull } from 'react-icons/lu';

import Catch from '../../components/Catch';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import useShowError from '../../hooks/useShowError';

export function ProfileRemoveButtonMain() {
  const { t } = useTranslation('SettingsScreen', { keyPrefix: 'ProfileRemoveButton' });

  const showError = useShowError();

  const myProfileSnap = useMyProfileSnap();

  const [isConfirming, setConfirming] = useState<boolean>(false);
  const handleConfirm = useCallback(
    () => {
      setConfirming(true);
      deleteDoc(myProfileSnap.ref).finally(() => setConfirming(false)).catch(showError);
    },
    [myProfileSnap.ref, showError],
  );

  const { isOpen, onClose, onOpen } = useDisclosure();

  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <AlertDialogContent
          bg="chakra-body-bg"
          mx={4}
        >
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t('confirmationAlert.title')}
          </AlertDialogHeader>

          <AlertDialogCloseButton />

          <AlertDialogBody>
            {t('confirmationAlert.body')}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              onClick={onClose}
              ref={cancelRef}
              variant="ghost"
            >
              {t('confirmationAlert.cancelButton.default')}
            </Button>

            <Button
              colorScheme="red"
              isLoading={isConfirming}
              leftIcon={<Icon as={LuSkull} />}
              ml={3}
              onClick={handleConfirm}
            >
              {t('confirmationAlert.confirmButton.default')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Button
        colorScheme="red"
        justifyContent="start"
        leftIcon={<Icon as={LuSkull} />}
        loadingText={t('removeButton.loading')}
        onClick={onOpen}
        variant="ghost"
      >
        {t('removeButton.default')}
      </Button>
    </>
  );
}

export default function ProfileRemoveButton() {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <ProfileRemoveButtonMain />
      </Suspense>
    </Catch>
  );
}
