import {
  Button,
  Container,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { logEvent, setUserProperties } from 'firebase/analytics';
import {
  doc,
  getDoc,
  QueryDocumentSnapshot,
  Timestamp,
  writeBatch,
} from 'firebase/firestore';
import { Formik } from 'formik';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuChevronLeft } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import { useAnalytics, useFirestore } from 'reactfire';
import * as yup from 'yup';

import { useAirportsCollectionRef } from '../../collections/Airports';
import {
  getProfileHiddenRef,
  getProfileVersionsCollectionRef,
  ProfileVersionDoc,
  ProfileVersionStatus,
} from '../../collections/Profiles';
import AppLanguage from '../../common/AppLanguage';
import CupSize from '../../common/CupSize';
import dateToZodiac from '../../common/dateToZodiac';
import EyeColor from '../../common/EyeColor';
import Gender from '../../common/Gender';
import HairColor from '../../common/HairColor';
import HairLength from '../../common/HairLength';
import Language from '../../common/Language';
import PantiesSize from '../../common/PantiesSize';
import RelationshipStyle from '../../common/RelationshipStyle';
import Sexuality from '../../common/Sexuality';
import { StoragePicture } from '../../common/StoragePicture';
import Wealth from '../../common/Wealth';
import AirportFormControl from '../../components/AirportFormControl';
import CupSizeFormControl from '../../components/CupSizeFormControl';
import GenderFormControl from '../../components/GenderFormControl';
import HairLengthFormControl from '../../components/HairLengthFormControl';
import { useInsets } from '../../components/InsetsProvider';
import InstagramFormControl from '../../components/InstagramFormControl';
import { useIPData } from '../../components/IPDataProvider';
import LanguagesFormControl from '../../components/LanguagesFormControl';
import LinkedinFormControl from '../../components/LinkedinFormControl';
import NumberFormControl from '../../components/NumberFormControl';
import PantiesSizeFormControl from '../../components/PantiesSizeFormControl';
import PicturesFormControl from '../../components/PicturesFormControl';
import { useMyProfileRef } from '../../components/refProviders/MyProfileRefProvider';
import RelationshipStyleFormControl from '../../components/RelationshipStyleFormControl';
import SelectFormControl from '../../components/SelectFormControl';
import SexualityFormControl from '../../components/SexualityFormControl';
import TextFormControl from '../../components/TextFormControl';
import TiktokFormControl from '../../components/TiktokFormControl';
import WealthFormControl from '../../components/WealthFormControl';
import useValidateFemalePictures from '../../functions/useValidateFemalePictures';
import useValidateMalePictures from '../../functions/useValidateMalePictures';
import useValidatePicture from '../../functions/useValidatePicture';
import useValidateProfileName from '../../functions/validateProfileName';
import i18n from '../../i18n';

export type Props = {
  lastProfileVersionSnap: QueryDocumentSnapshot<ProfileVersionDoc> | undefined;
  onBack: () => void;
};

export function ProfileFormMain({ lastProfileVersionSnap, onBack }: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileForm' });
  const { t: ect } = useTranslation('EyeColor');
  const { t: hct } = useTranslation('HairColor');

  const myProfileRef = useMyProfileRef();

  const birthDateText = useCallback(
    (
      value?: string,
    ) => {
      if (!value) {
        return false;
      }

      const current = moment(value);

      if (!current.isValid()) {
        return false;
      }

      const lowest = moment().subtract(16, 'years').startOf('day');
      const highest = moment().subtract(70, 'years').endOf('day');

      const daysOlderThanLowest = moment.duration(moment(lowest).diff(current)).asDays();
      const daysYoungerThanHighest = moment.duration(moment(current).diff(highest)).asDays();

      if (daysOlderThanLowest < 0) {
        return false;
      }

      if (daysYoungerThanHighest < 0) {
        return false;
      }

      return true;
    },
    [],
  );

  const validatePicture = useValidatePicture();

  const picturesItemTest = useCallback(async (
    value: { imgixUrl: string },
    context: yup.TestContext,
  ) => {
    const cache = localStorage.getItem(`validatePicture::${JSON.stringify(value.imgixUrl)}`);

    let validationResult: Record<string, boolean>;

    try {
      if (cache) {
        validationResult = JSON.parse(cache) as Record<string, boolean>;
      } else {
        validationResult = (await validatePicture({ imageUrl: value.imgixUrl })).data;
        localStorage.setItem(`validatePicture::${JSON.stringify(value.imgixUrl)}`, JSON.stringify(validationResult));
      }
    } catch (err) {
      throw new yup.ValidationError(
        'Unknown error',
        value,
        context.path,
      );
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const k of Object.keys(validationResult)) {
      if (!validationResult[k]) {
        throw new yup.ValidationError(
          t(`pictures.item.${k}Error`),
          value,
          context.path,
        );
      }
    }

    return true;
  }, [t, validatePicture]);

  const validateFemalePictures = useValidateFemalePictures();

  const picturesFemaleTest = useCallback(async (
    value: { imgixUrl: string }[],
    context: yup.TestContext,
  ) => {
    const imageUrls = _.compact(value.map(({ imgixUrl }) => imgixUrl));
    const cache = localStorage.getItem(`validateFemalePictures::${JSON.stringify(imageUrls)}`);

    let validationResult: Record<string, boolean>;

    try {
      if (cache) {
        validationResult = JSON.parse(cache) as Record<string, boolean>;
      } else {
        validationResult = (await validateFemalePictures({ imageUrls })).data;
        localStorage.setItem(`validateFemalePictures::${JSON.stringify(imageUrls)}`, JSON.stringify(validationResult));
      }
    } catch (err) {
      throw new yup.ValidationError(
        'Unknown error',
        value,
        context.path,
      );
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const k of Object.keys(validationResult)) {
      if (!validationResult[k]) {
        throw new yup.ValidationError(
          t(`pictures.${k}Error`),
          value,
          context.path,
        );
      }
    }

    return true;
  }, [t, validateFemalePictures]);

  const validateMalePictures = useValidateMalePictures();

  const picturesMaleTest = useCallback(async (
    value: { imgixUrl: string }[],
    context: yup.TestContext,
  ) => {
    const imageUrls = _.compact(value.map(({ imgixUrl }) => imgixUrl));
    const cache = localStorage.getItem(`validateMalePictures::${JSON.stringify(imageUrls)}`);

    let validationResult: Record<string, boolean>;

    try {
      if (cache) {
        validationResult = JSON.parse(cache) as Record<string, boolean>;
      } else {
        validationResult = (await validateMalePictures({ imageUrls })).data;
        localStorage.setItem(`validateMalePictures::${JSON.stringify(imageUrls)}`, JSON.stringify(validationResult));
      }
    } catch (err) {
      throw new yup.ValidationError(
        'Unknown error',
        value,
        context.path,
      );
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const k of Object.keys(validationResult)) {
      if (!validationResult[k]) {
        throw new yup.ValidationError(
          t(`pictures.${k}Error`),
          value,
          context.path,
        );
      }
    }

    return true;
  }, [t, validateMalePictures]);

  const validateProfileName = useValidateProfileName();

  const nameTest = useCallback(async (
    value: string,
    context: yup.TestContext,
  ) => {
    const cache = localStorage.getItem(`validateProfileName::${JSON.stringify(value)}`);

    let validationResult: Record<string, boolean>;

    try {
      if (cache) {
        validationResult = JSON.parse(cache) as Record<string, boolean>;
      } else {
        validationResult = (await validateProfileName({ name: value })).data;
        localStorage.setItem(`validateProfileName::${JSON.stringify(value)}`, JSON.stringify(validationResult));
      }
    } catch (err) {
      throw new yup.ValidationError(
        'Unknown error',
        value,
        context.path,
      );
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const k of Object.keys(validationResult)) {
      if (!validationResult[k]) {
        throw new yup.ValidationError(
          t(`name.${k}Error`),
          value,
          context.path,
        );
      }
    }

    return true;
  }, [t, validateProfileName]);

  const schema = useMemo(
    () => yup.object().shape({
      birthDate: yup
        .string()
        .label(t('birthDate.label'))
        .test(birthDateText)
        .required(),
      cupSize: yup
        .number()
        .label(t('cupSize.label'))
        .integer()
        .min(CupSize.AA)
        .max(CupSize.E)
        .when('gender', {
          is: Gender.FEMALE,
          otherwise: (s) => s.transform(() => undefined),
          then: (s) => s.required(),
        }),
      eyeColor: yup
        .string()
        .label(t('eyeColor.label'))
        .oneOf(Object.values(EyeColor))
        .when('gender', {
          is: Gender.FEMALE,
          otherwise: (s) => s.transform(() => undefined),
          then: (s) => s.required(),
        }),
      gender: yup
        .string()
        .label(t('gender.label'))
        .oneOf(Object.values(Gender))
        .required(),
      hairColor: yup
        .string()
        .label(t('hairColor.label'))
        .oneOf(Object.values(HairColor))
        .when('gender', {
          is: Gender.FEMALE,
          otherwise: (s) => s.transform(() => undefined),
          then: (s) => s.required(),
        }),
      hairLength: yup
        .number()
        .label(t('hairLength.label'))
        .integer()
        .min(HairLength.BUZZ_CUT)
        .max(HairLength.TAILBONE)
        .when('gender', {
          is: Gender.FEMALE,
          otherwise: (s) => s.transform(() => undefined),
          then: (s) => s.required(),
        }),
      height: yup
        .number()
        .label(t('height.label'))
        .integer()
        .min(30)
        .max(300)
        .required(),
      instagramTag: yup
        .string()
        .matches(/^[a-zA-Z0-9._]+$/, t('instagramTag.matchesError'))
        .label(t('instagramTag.label'))
        .required(),
      languages: yup
        .array(yup
          .string()
          .label(t('languages.item.label'))
          .oneOf(Object.values(Language))
          .required())
        .label(t('languages.label'))
        .min(1)
        .required(),
      linkedinTag: yup
        .string()
        .matches(/^[a-zA-Z0-9._]+$/, t('linkedinTag.matchesError'))
        .label(t('linkedinTag.label'))
        .optional(),
      name: yup
        .string()
        .label(t('name.label'))
        .required()
        .trim()
        .test(nameTest),
      originId: yup
        .string()
        .label(t('originId.label'))
        .required(),
      pantiesSize: yup
        .number()
        .label(t('pantiesSize.label'))
        .integer()
        .min(PantiesSize.XXS)
        .max(PantiesSize.XXL)
        .when('gender', {
          is: Gender.FEMALE,
          otherwise: (s) => s.transform(() => undefined),
          then: (s) => s.required(),
        }),
      pictures: yup
        .array()
        .label(t('pictures.label'))
        .compact()
        .min(3)
        .max(6)
        .required()
        .of(
          yup
            .object()
            .label(t('pictures.item.label'))
            .required()
            .shape({
              blurHash: yup.string(),
              imgixUrl: yup.string().required(),
              storageRef: yup.string().required(),
            })
            .test(picturesItemTest),
        )
        .when('gender', {
          is: Gender.FEMALE,
          otherwise: (s) => s.test(picturesMaleTest),
          then: (s) => s.test(picturesFemaleTest),
        }),
      relationshipStyle: yup
        .string()
        .label(t('relationshipStyle.label'))
        .oneOf(Object.values(RelationshipStyle))
        .required(),
      sexuality: yup
        .string()
        .label(t('sexuality.label'))
        .oneOf(Object.values(Sexuality))
        .required(),
      tiktokTag: yup
        .string()
        .matches(/^[a-zA-Z0-9._]+$/, t('tiktokTag.matchesError'))
        .label(t('tiktokTag.label'))
        .optional(),
      wealth: yup
        .number()
        .label(t('wealth.label'))
        .integer()
        .min(Wealth.BROKE)
        .max(Wealth.MILLIONAIRE)
        .when('gender', {
          is: Gender.MALE,
          otherwise: (s) => s.transform(() => undefined),
          then: (s) => s.required(),
        }),
      weight: yup
        .number()
        .label(t('weight.label'))
        .integer()
        .min(30)
        .max(300)
        .required(),
    }),
    [
      t,
      birthDateText,
      nameTest,
      picturesItemTest,
      picturesMaleTest,
      picturesFemaleTest,
    ],
  );

  const ipData = useIPData();

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => {
      const lastProfileVersionDoc = lastProfileVersionSnap?.data();

      const ipDataLanguages: Language[] = ipData?.languages.map(
        (l) => (l as unknown as { code: Language }).code,
      ) ?? [];

      const navigatorLanguages: Language[] = ([navigator.language, ...navigator.languages])
        .map((l) => l.split('-')[0] as Language);

      const languages: Language[] = _.uniq([
        ...ipDataLanguages,
        ...navigatorLanguages,
      ]).sort();

      return {
        birthDate: lastProfileVersionDoc?.birthDate ?? '',
        cupSize: lastProfileVersionDoc?.cupSize ?? CupSize.AA,
        eyeColor: lastProfileVersionDoc?.eyeColor ?? EyeColor.BLUE,
        gender: lastProfileVersionDoc?.gender ?? Gender.FEMALE,
        hairColor: lastProfileVersionDoc?.hairColor ?? HairColor.WHITE,
        hairLength: lastProfileVersionDoc?.hairLength ?? HairLength.BUZZ_CUT,
        height: lastProfileVersionDoc?.height ?? 170,
        instagramTag: lastProfileVersionDoc?.instagramTag ?? '',
        languages: lastProfileVersionDoc?.languages ?? languages,
        linkedinTag: lastProfileVersionDoc?.linkedinTag ?? '',
        name: lastProfileVersionDoc?.name ?? '',
        originId: lastProfileVersionDoc?.originRef?.id ?? '',
        pantiesSize: lastProfileVersionDoc?.pantiesSize ?? PantiesSize.XXS,
        pictures: lastProfileVersionDoc?.pictures ?? [],
        relationshipStyle: lastProfileVersionDoc?.relationshipStyle ?? RelationshipStyle.MONOGAMY,
        sexuality: lastProfileVersionDoc?.sexuality ?? Sexuality.STRAIGHT,
        tiktokTag: lastProfileVersionDoc?.tiktokTag ?? '',
        wealth: lastProfileVersionDoc?.wealth ?? Wealth.BROKE,
        weight: lastProfileVersionDoc?.weight ?? 50,
      };
    },
    [ipData?.languages, lastProfileVersionSnap],
  );

  const firestore = useFirestore();
  const airportsCollectionRef = useAirportsCollectionRef();

  const analytics = useAnalytics();
  const navigate = useNavigate();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      const originRef = doc(airportsCollectionRef, values.originId);
      const originSnap = await getDoc(originRef);
      const originDoc = originSnap.data();

      if (!originSnap.exists() || !originDoc) {
        throw new Error('City not found');
      }

      const birthDate = moment.utc(values.birthDate).startOf('day');

      const age = Math.floor(
        moment.duration(
          moment().diff(birthDate),
        ).asYears(),
      );

      const zodiac = dateToZodiac(birthDate.toDate());

      const pictures = _.compact(values.pictures) as [StoragePicture, ...StoragePicture[]];

      const batch = writeBatch(firestore);

      const profileVersionRef = doc(getProfileVersionsCollectionRef(myProfileRef));

      batch.set(
        profileVersionRef,
        {
          _v: 1,
          birthDate: birthDate.format('YYYY-MM-DD'),
          cupSize: values.cupSize,
          eyeColor: values.eyeColor,
          gender: values.gender,
          hairColor: values.hairColor,
          hairLength: values.hairLength,
          height: values.height,
          instagramTag: values.instagramTag,
          languages: values.languages,
          linkedinTag: typeof values.linkedinTag === 'string' && values.linkedinTag !== '' ? values.linkedinTag : undefined,
          name: values.name,
          originRef,
          pantiesSize: values.pantiesSize,
          pictures,
          relationshipStyle: values.relationshipStyle,
          sentAt: Timestamp.now(),
          sexuality: values.sexuality,
          status: ProfileVersionStatus.SENT,
          tiktokTag: typeof values.tiktokTag === 'string' && values.tiktokTag !== '' ? values.tiktokTag : undefined,
          wealth: values.wealth,
          weight: values.weight,
        },
      );

      batch.set(
        getProfileHiddenRef(myProfileRef),
        {
          _v: 1,
          appLanguage: i18n.language as AppLanguage,
          timezone: originDoc.timezone,
        },
      );

      await batch.commit();

      fbq('track', 'CompleteRegistration');

      mixpanel.people.set({
        $avatar: pictures[0].imgixUrl,
        $name: values.name,
        age,
        birthDate: birthDate.format('YYYY-MM-DD'),
        gender: values.gender,
        height: values.height,
        instagramTag: values.instagramTag,
        languages: values.languages,
        linkedinTag: values.linkedinTag,
        sexuality: values.sexuality,
        tiktokTag: values.tiktokTag,
        timezone: originDoc.timezone,
        weight: values.weight,
        zodiac,
      });

      mixpanel.track('My Profile Created', {
        age,
        avatar: pictures[0].imgixUrl,
        birthDate: birthDate.format('YYYY-MM-DD'),
        gender: values.gender,
        height: values.height,
        instagramTag: values.instagramTag,
        languages: values.languages,
        linkedinTag: values.linkedinTag,
        name: values.name,
        sexuality: values.sexuality,
        tiktokTag: values.tiktokTag,
        timezone: originDoc.timezone,
        weight: values.weight,
        zodiac,
      });

      logEvent(analytics, 'create_profile');
      setUserProperties(analytics, {
        age,
        languages: values.languages,
        sexuality: values.sexuality,
        zodiac,
      });

      navigate('/');
    },
    [
      airportsCollectionRef,
      analytics,
      firestore,
      myProfileRef,
      navigate,
    ],
  );

  const insets = useInsets();

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack gap={4}>
          <Heading
            flex={1}
            fontSize="4xl"
            lineHeight="40px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {t('title')}
          </Heading>
        </HStack>
      </Container>

      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validateOnBlur={validateAll}
        validateOnChange={validateAll}
        validationSchema={schema}
      >
        {({
          handleSubmit,
          isSubmitting,
          isValid,
          isValidating,
          values,
        }) => (
          <VStack
            alignItems="stretch"
            as="form"
            flex={1}
            gap={0}
            minH={0}
            noValidate
            onSubmit={(e) => {
              setValidateAll(true);
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Container
              flex={1}
              maxW="lg"
              overflowY="auto"
              py={2}
            >
              <VStack alignItems="stretch" flex={1} gap={8} overflow="auto">
                <PicturesFormControl
                  helperText={t('pictures.helperText')}
                  label={t('pictures.label')}
                  name="pictures"
                />

                <TextFormControl
                  autoComplete="given-name"
                  helperText={t('name.helperText')}
                  label={t('name.label')}
                  name="name"
                  type="text"
                />

                <AirportFormControl
                  aroundLatLngViaIP
                  helperText={t('originId.helperText')}
                  label={t('originId.label')}
                  name="originId"
                  placeholder={t('originId.placeholder')}
                />

                <GenderFormControl
                  helperText={t('gender.helperText')}
                  label={t('gender.label')}
                  name="gender"
                />

                <SexualityFormControl
                  gender={values.gender}
                  helperText={t('sexuality.helperText')}
                  label={t('sexuality.label')}
                  name="sexuality"
                />

                <RelationshipStyleFormControl
                  helperText={t('relationshipStyle.helperText')}
                  label={t('relationshipStyle.label')}
                  name="relationshipStyle"
                />

                <TextFormControl
                  autoComplete="bday"
                  helperText={t('birthDate.helperText')}
                  label={t('birthDate.label')}
                  name="birthDate"
                  type="date"
                />

                <Grid columnGap={4} rowGap={2} templateColumns="repeat(2, 1fr)">
                  <NumberFormControl
                    label={t('height.label')}
                    max={250}
                    min={150}
                    name="height"
                    right={t('height.unit')}
                    step={1}
                  />

                  <NumberFormControl
                    label={t('weight.label')}
                    max={120}
                    min={45}
                    name="weight"
                    right={t('weight.unit')}
                    step={1}
                  />

                  <GridItem colSpan={2}>
                    <Text
                      _dark={{ color: 'whiteAlpha.600' }}
                      fontSize="sm"
                    >
                      {t('heightAndWeight.helperText')}
                    </Text>
                  </GridItem>
                </Grid>

                {values.gender === Gender.FEMALE ? (
                  <Grid columnGap={4} rowGap={2} templateColumns="repeat(2, 1fr)">
                    <CupSizeFormControl
                      label={t('cupSize.label')}
                      name="cupSize"
                    />

                    <PantiesSizeFormControl
                      label={t('pantiesSize.label')}
                      name="pantiesSize"
                    />

                    <GridItem colSpan={2}>
                      <Text
                        _dark={{ color: 'whiteAlpha.600' }}
                        fontSize="sm"
                      >
                        {t('bikiniSize.helperText')}
                      </Text>
                    </GridItem>
                  </Grid>
                ) : null}

                {values.gender === Gender.FEMALE ? (
                  <Grid columnGap={4} rowGap={2} templateColumns="repeat(2, 1fr)">
                    <SelectFormControl
                      label={t('eyeColor.label')}
                      name="eyeColor"
                      options={{
                        /* eslint-disable perfectionist/sort-objects */
                        [EyeColor.BLUE]: ect('BLUE.label'),
                        [EyeColor.GRAY]: ect('GRAY.label'),
                        [EyeColor.GREEN]: ect('GREEN.label'),
                        [EyeColor.HAZEL]: ect('HAZEL.label'),
                        [EyeColor.AMBER]: ect('AMBER.label'),
                        [EyeColor.BROWN]: ect('BROWN.label'),
                        /* eslint-enable perfectionist/sort-objects */
                      }}
                    />

                    <SelectFormControl
                      label={t('hairColor.label')}
                      name="hairColor"
                      options={{
                        /* eslint-disable perfectionist/sort-objects */
                        [HairColor.WHITE]: hct('WHITE.label'),
                        [HairColor.GRAY]: hct('GRAY.label'),
                        [HairColor.BLONDE]: hct('BLONDE.label'),
                        [HairColor.CHESTNUT]: hct('CHESTNUT.label'),
                        [HairColor.BROWN]: hct('BROWN.label'),
                        [HairColor.RED]: hct('RED.label'),
                        [HairColor.BLACK]: hct('BLACK.label'),
                        [HairColor.OTHER]: hct('OTHER.label'),
                        /* eslint-enable perfectionist/sort-objects */
                      }}
                    />

                    <GridItem colSpan={2}>
                      <HairLengthFormControl
                        label={t('hairLength.label')}
                        name="hairLength"
                      />
                    </GridItem>

                    <GridItem colSpan={2}>
                      <Text
                        _dark={{ color: 'whiteAlpha.600' }}
                        fontSize="sm"
                      >
                        {t('eyesAndHair.helperText')}
                      </Text>
                    </GridItem>
                  </Grid>
                ) : null}

                {values.gender === Gender.MALE ? (
                  <WealthFormControl
                    helperText={t('wealth.helperText')}
                    label={t('wealth.label')}
                    name="wealth"
                  />
                ) : null}

                <LanguagesFormControl
                  helperText={t('languages.helperText')}
                  label={t('languages.label')}
                  name="languages"
                  placeholder={t('languages.placeholder')}
                />

                <InstagramFormControl
                  helperText={t('instagramTag.helperText')}
                  label={t('instagramTag.label')}
                  name="instagramTag"
                  type="text"
                />

                <TiktokFormControl
                  helperText={t('tiktokTag.helperText')}
                  label={t('tiktokTag.label')}
                  name="tiktokTag"
                  type="text"
                />

                <LinkedinFormControl
                  helperText={t('linkedinTag.helperText')}
                  label={t('linkedinTag.label')}
                  name="linkedinTag"
                  type="text"
                />
              </VStack>
            </Container>

            <Container
              maxW="lg"
              py={2}
            >
              <HStack gap={2}>
                <IconButton
                  aria-label={t('backButton.loading')}
                  icon={<Icon as={LuChevronLeft} />}
                  onClick={onBack}
                />

                <Button
                  colorScheme={isValid ? undefined : 'red'}
                  flex={1}
                  isLoading={isValidating || isSubmitting}
                  loadingText={isValidating ? t('sendButton.validating') : t('sendButton.loading')}
                  type="submit"
                >
                  {isValid ? t('sendButton.default') : t('sendButton.invalid')}
                </Button>
              </HStack>
            </Container>
          </VStack>
        )}
      </Formik>
    </VStack>
  );
}

export default function ProfileForm(props: Props) {
  return (
    <Suspense fallback={null}>
      <ProfileFormMain
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Suspense>
  );
}
