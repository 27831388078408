import { Center } from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import { Suspense, useEffect, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import { TripStatus, useTripsCollectionRef } from '../../collections/Trips';
import Catch from '../../components/Catch';
import LogoIcon from '../../components/LogoIcon';
import ErrorFallbackScreen from '../ErrorFallbackScreen';

export function TripPaymentSuccessScreenMain() {
  useEffect(() => { mixpanel.track_pageview({ screen: 'TripPaymentSuccessScreen' }); }, []);

  const { tripId } = useParams<{ tripId: string }>();

  const tripsCollectionRef = useTripsCollectionRef();
  const tripRef = useMemo(
    () => doc(tripsCollectionRef, tripId),
    [tripId, tripsCollectionRef],
  );

  const { data: tripSnap } = useFirestoreDoc(tripRef);

  if (!tripSnap.exists()) {
    throw new Error('Trip does not exist');
  }

  const tripDoc = useMemo(() => tripSnap.data(), [tripSnap]);

  if (tripDoc.status === TripStatus.PUBLISHED) {
    <Navigate to="/trips" />;
  }

  return (
    <Center h="100%">
      <LogoIcon boxSize={16} />
    </Center>
  );
}

export default function TripPaymentSuccessScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <TripPaymentSuccessScreenMain />
      </Suspense>
    </Catch>
  );
}
