import { Box, BoxProps, Image } from '@chakra-ui/react';
import { useMemo } from 'react';

import { getPhotoSizeUrl } from '../common/getPhotoSizeUrl';
import { StoragePicture } from '../common/StoragePicture';
import useBlur from '../hooks/useBlur';

export type Props = {
  height: number;
  isHidden: boolean;
  picture: StoragePicture;
  width: number;
} & BoxProps;

export default function StoragePictureCell({
  height,
  isHidden = false,
  picture,
  width,
  ...boxProps
}: Props) {
  const src = useMemo(
    () => getPhotoSizeUrl({
      blur: isHidden,
      faces: true,
      height,
      uri: picture.imgixUrl,
      width,
    }),
    [height, isHidden, picture.imgixUrl, width],
  );

  const fallbackSrc = useBlur({
    blurHash: picture.blurHash,
    height: 32,
    width: 18,
  });

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <Image
        alt=""
        fallbackSrc={fallbackSrc}
        h={`${height}px`}
        objectFit="cover"
        scrollSnapAlign="start"
        scrollSnapStop="always"
        src={src}
        w={`${width}px`}
      />
    </Box>
  );
}
