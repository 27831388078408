import {
  Badge,
  BoxProps,
  HStack,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { Suspense } from 'react';

import VenueAlgoliaSearchRecord from '../../../common/VenueAlgoliaSearchRecord';
import Catch from '../../../components/Catch';
import TierIcon from '../../../components/TierIcon';

export type Props = {
  venueRecord: VenueAlgoliaSearchRecord;
} & BoxProps;

export function VenueDetailsMain({ venueRecord, ...boxProps }: Props) {
  return (
    <VStack
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      alignItems="stretch"
      gap={2}
      p={2}
    >
      <VStack alignItems="stretch" gap={1}>
        <HStack>
          <TierIcon tier={venueRecord.tier} />

          <Text
            flex={1}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {venueRecord.name}
          </Text>
        </HStack>

        <Wrap>
          {venueRecord.distinctions.map((distinction) => (
            <Badge key={distinction}>{distinction}</Badge>
          ))}

          {venueRecord.labels.map((label) => (
            <Badge key={label}>{label}</Badge>
          ))}
        </Wrap>
      </VStack>
    </VStack>
  );
}

export default function VenueDetails(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <VenueDetailsMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
