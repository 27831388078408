import {
  AspectRatio,
  Box,
  Image,
  Skeleton,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';

import { ApplicationDoc } from '../../../collections/Applications';
import { isProfileComplete } from '../../../collections/Profiles';
import { getPhotoSizeUrl } from '../../../common/getPhotoSizeUrl';
import Catch from '../../../components/Catch';
import useBlur from '../../../hooks/useBlur';

export type Props = {
  applicationSnap: QueryDocumentSnapshot<ApplicationDoc>;
};

export function ApplicationMain({
  applicationSnap,
}: Props) {
  const applicationDoc = useMemo(() => applicationSnap.data(), [applicationSnap]);

  const { data: applicantSnap } = useFirestoreDoc(applicationDoc.applicantRef);

  if (!applicantSnap.exists()) {
    throw new Error('Applicant does not exist');
  }

  const applicantDoc = useMemo(() => applicantSnap.data(), [applicantSnap]);

  if (!isProfileComplete(applicantDoc)) {
    throw new Error('Applicant is not complete');
  }

  const src = useMemo(
    () => getPhotoSizeUrl({
      height: 240,
      uri: applicantDoc.pictures[0].imgixUrl,
      width: 135,
    }),
    [applicantDoc.pictures],
  );

  const fallbackSrc = useBlur({
    blurHash: applicantDoc.pictures[0].blurHash,
    height: 32,
    width: 18,
  });

  return (
    <AspectRatio ratio={9 / 16}>
      <Box
        borderRadius="md"
        boxShadow="base"
        h="100%"
        overflow="hidden"
        position="relative"
        w="100%"
      >
        <Image
          fallbackSrc={fallbackSrc}
          h="100%"
          objectFit="cover"
          position="absolute"
          src={src}
          w="100%"
        />
      </Box>
    </AspectRatio>
  );
}

export function ApplicationLoading() {
  return (
    <AspectRatio ratio={9 / 16}>
      <Skeleton h="100%" w="100%" />
    </AspectRatio>
  );
}

export default function Application(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={<ApplicationLoading />}>
        <ApplicationMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
