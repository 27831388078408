import {
  Button,
  Center,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { LuAlertTriangle, LuRefreshCw } from 'react-icons/lu';

import { useError } from '../../components/Catch';

export default function ErrorFallbackScreen() {
  const error = useError();

  return (
    <Center h="100%">
      <VStack>
        <Icon as={LuAlertTriangle} boxSize={10} />

        <Text>Unknown error</Text>

        {error?.message ? (
          <Text fontSize="xs">{error.message}</Text>
        ) : null}

        <Button
          leftIcon={<Icon as={LuRefreshCw} />}
          onClick={() => window.location.reload()}
        >
          Reload
        </Button>
      </VStack>
    </Center>
  );
}
