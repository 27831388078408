import {
  Badge,
  Box,
  BoxProps,
  Container,
  HStack,
  Icon,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { LuLanguages, LuSparkles } from 'react-icons/lu';
import { PiGenderIntersexBold } from 'react-icons/pi';

import { useProfilesCollectionRef } from '../../../collections/Profiles';
import AppLanguage from '../../../common/AppLanguage';
import Gender from '../../../common/Gender';
import Sexuality from '../../../common/Sexuality';
import TripAlgoliaSearchRecord from '../../../common/TripAlgoliaSearchRecord';
import TierIcon from '../../../components/TierIcon';
import OrganizerAvatar from './OrganizerAvatar';
import WealthBadge from './WealthBadge';

export type Props = {
  queryId: string | undefined;
  searchResultPosition: number;
  tripRecord: TripAlgoliaSearchRecord;
} & BoxProps;

export default function InfoCell({
  queryId,
  searchResultPosition,
  tripRecord,
  ...boxProps
}: Props) {
  const { i18n, t } = useTranslation('TheirTripsScreen', { keyPrefix: 'Trip.InfoCell' });
  const { t: lt } = useTranslation('Languages');
  const { t: st } = useTranslation('Sexuality');
  const { t: zt } = useTranslation('Zodiac');

  const profilesCollectionRef = useProfilesCollectionRef();

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      className="tripInfo"
    >
      <Container
        h="100%"
        maxW="lg"
        pb="calc(max(env(safe-area-inset-bottom), var(--chakra-space-4)) + var(--chakra-space-12) + var(--chakra-space-4))"
        pr={12}
        pt="calc(max(env(safe-area-inset-top), var(--chakra-space-4)) + var(--chakra-space-12) + var(--chakra-space-4))"
      >
        <VStack
          alignItems="stretch"
          gap={4}
          h="100%"
        >
          <Wrap>
            <Badge>
              {tripRecord.destination.name[i18n.language as AppLanguage]}
            </Badge>

            <Badge>
              {tripRecord.destination.country.name[i18n.language as AppLanguage]}
            </Badge>
          </Wrap>

          <Wrap>
            {
              tripRecord.organizer.sexuality === Sexuality.BISEXUAL ? (
                <Badge alignItems="center" colorScheme="purple" display="flex" gap={1}>
                  <Icon as={PiGenderIntersexBold} size={5} />
                  {st(tripRecord.organizer.sexuality)}
                </Badge>
              ) : null
            }

            {tripRecord.organizer.languages.map((l) => (
              <Badge alignItems="center" display="flex" gap={1} key={l}>
                <Icon as={LuLanguages} size={5} />
                {lt(l)}
              </Badge>
            ))}

            <Badge>
              {tripRecord.organizer.height}
              cm
            </Badge>

            <Badge>
              {tripRecord.organizer.weight}
              kg
            </Badge>

            <Badge>
              {tripRecord.organizer.age}
              y
            </Badge>

            {tripRecord.organizer.gender === Gender.MALE && tripRecord.organizer.wealth ? (
              <WealthBadge wealth={tripRecord.organizer.wealth} />
            ) : null}

            <Badge alignItems="center" display="flex" gap={1}>
              <Icon as={LuSparkles} size={5} />
              {zt(tripRecord.organizer.zodiac)}
            </Badge>
          </Wrap>

          <Text
            flex={1}
            fontSize="sm"
            minH={0}
            overflow="hidden"
          >
            {/* eslint-disable-next-line max-len */}
            {tripRecord.translations?.description[i18n.language as AppLanguage] ?? tripRecord.description}
          </Text>

          <HStack gap={4}>
            <OrganizerAvatar
              organizerRef={doc(profilesCollectionRef, tripRecord.organizer.id)}
            />

            <VStack alignItems="stretch" flex={1} gap={1} minW={0}>
              <HStack>
                <TierIcon boxSize={4} tier={tripRecord.tier} />

                <Text
                  flexShrink={1}
                  fontSize="md"
                  lineHeight={1}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {/* eslint-disable-next-line max-len */}
                  {tripRecord.organizer.translations?.name[i18n.language as AppLanguage] ?? tripRecord.organizer.name}
                  {', '}
                  {tripRecord.organizer.age}
                </Text>
              </HStack>

              <Text fontSize="xs" lineHeight={1} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                {_.compact([
                  tripRecord.destination.country.emoji,
                  _.compact([
                    tripRecord.destination.name[i18n.language as AppLanguage],
                    tripRecord.destination.country.name[i18n.language as AppLanguage],
                  ]).join(', '),
                ]).join(' ')}
              </Text>

              <Text fontSize="xs" lineHeight={1} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                {t('departure', {
                  departure: moment(tripRecord.departure).toDate(),
                  formatParams: { departure: { day: 'numeric', month: 'long' } },
                })}
              </Text>
            </VStack>
          </HStack>
        </VStack>
      </Container>
    </Box>
  );
}
