import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import { useField } from 'formik';
import mixpanel from 'mixpanel-browser';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FaSearch } from 'react-icons/fa';
import { LuFileEdit } from 'react-icons/lu';

import { useAirportsCollectionRef } from '../../collections/Airports';
import AirportAlgoliaSearchRecord from '../../common/AirportAlgoliaSearchRecord';
import useShowError from '../../hooks/useShowError';
import { useAlgoliaSearchClient } from '../AlgoliaSearchClientProvider';
import AirportRow from './AirportRow';

export interface Props {
  // eslint-disable-next-line react/require-default-props
  aroundLatLngViaIP?: boolean;
  // eslint-disable-next-line react/require-default-props
  helperText?: string;
  label: string;
  name: string;
  placeholder: string;
}

export default function AirportFormControl({
  aroundLatLngViaIP = false, helperText, label, name, placeholder,
}: Props) {
  const showError = useShowError();
  const [input, meta, helper] = useField<string>(name);

  const client = useAlgoliaSearchClient();
  const airportsIndex = useMemo(() => client.initIndex('airports'), [client]);

  const [query, setQuery] = useState<string>('');
  const [airportsResult, setAirportsResult] = useState<AirportAlgoliaSearchRecord[]>();

  useEffect(
    () => {
      airportsIndex
        .search<AirportAlgoliaSearchRecord>(
        query,
        {
          aroundLatLngViaIP,
          hitsPerPage: 10,
        },
      )
        .then(
          ({ hits }) => {
            setAirportsResult(hits);
          },
        )
        .catch(showError);
    },
    [airportsIndex, aroundLatLngViaIP, query, showError],
  );

  const { isOpen, onClose, onOpen } = useDisclosure();

  const airportsCollectionRef = useAirportsCollectionRef();

  const handleChange = useCallback(
    (id: string) => {
      helper.setValue(id)
        .catch(showError);
      onClose();
      mixpanel.track('Origin Selected');
    },
    [helper, onClose, showError],
  );

  return (
    <>
      <FormControl isInvalid={!!meta.error}>
        <FormLabel>{label}</FormLabel>

        <Box
          borderRadius="md"
          cursor="pointer"
          onClick={onOpen}
          px={4}
          py={2}
          style={{
            border: '1px solid',
            borderColor: 'inherit',
          }}
        >
          <HStack spacing={4}>
            {input.value ? (
              <AirportRow airportRef={doc(airportsCollectionRef, input.value)} />
            ) : (
              <Text flex={1}>
                {placeholder}
              </Text>
            )}

            <Icon as={LuFileEdit} />
          </HStack>
        </Box>

        {helperText ? (
          <FormHelperText>
            {helperText}
          </FormHelperText>
        ) : null}

        <FormErrorMessage>
          {meta.error}
        </FormErrorMessage>
      </FormControl>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
        <ModalOverlay
          backdropFilter="saturate(180%) blur(20px)"
          backgroundColor="rgb(from var(--chakra-colors-chakra-body-bg) r g b / 0.5)"
        />

        <ModalContent
          mx={4}
        >
          <ModalHeader>
            <InputGroup>
              <InputLeftElement>
                <Icon as={FaSearch} />
              </InputLeftElement>

              <Input
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Paris, France"
                value={query}
              />
            </InputGroup>
          </ModalHeader>

          <ModalBody
            maxH="calc(100vh - 262px)"
            overflow="auto"
          >
            <VStack alignItems="stretch" spacing={4}>

              <RadioGroup
                onChange={handleChange}
                value={input.value}
              >
                <VStack alignItems="stretch" spacing={4}>
                  {airportsResult?.map(({ objectID }) => (
                    <Radio
                      key={objectID}
                      value={objectID}
                    >
                      <AirportRow airportRef={doc(airportsCollectionRef, objectID)} />
                    </Radio>
                  ))}
                </VStack>
              </RadioGroup>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} variant="ghost">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
