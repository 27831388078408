import {
  Button,
  Center,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { orderBy, query, where } from 'firebase/firestore';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { LuCalendarPlus } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import { useReservationsCollectionRef } from '../../collections/Reservations';
import Catch from '../../components/Catch';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import ReservationSnapProvider from '../../components/snapProviders/ReservationSnapProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Reservation, { ReservationLoading } from './Reservation';

export function ReservationListMain() {
  const { t } = useTranslation('MyReservationsScreen', { keyPrefix: 'ReservationList' });

  const myProfileSnap = useMyProfileSnap();

  const reservationsCollectionRef = useReservationsCollectionRef();
  const { data: publishedReservationsSnap } = useFirestoreCollection(
    query(
      reservationsCollectionRef,
      where('organizerRef', '==', myProfileSnap.ref),
      orderBy('startsAt', 'asc'),
    ),
  );

  if (!publishedReservationsSnap.docs.length) {
    return (
      <VStack alignItems="stretch" className="myReservationsList" flex={1} gap={4} h="100%">
        <Center flex={1}>
          <Text textAlign="center">{t('emptyList.body')}</Text>
        </Center>

        <Button
          as={Link}
          className="createReservationButton"
          leftIcon={<Icon as={LuCalendarPlus} />}
          to="add"
        >
          {t('addReservationButton.default')}
        </Button>
      </VStack>
    );
  }

  return (
    <VStack alignItems="stretch" className="myReservationsList" flex={1} gap={4} h="100%">
      {publishedReservationsSnap.docs.map((reservationSnap) => (
        <ReservationSnapProvider key={reservationSnap.id} reservationSnap={reservationSnap}>
          <Reservation />
        </ReservationSnapProvider>
      ))}
    </VStack>
  );
}

export function ReservationListLoading() {
  return (
    <VStack alignItems="stretch" className="myReservationsList" flex={1} gap={4} h="100%">
      <ReservationLoading />
    </VStack>
  );
}

export default function ReservationList() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<ReservationListLoading />}>
        <ReservationListMain />
      </Suspense>
    </Catch>
  );
}
