import { DocumentReference } from 'firebase/firestore';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestoreDoc } from 'reactfire';

import { ProfileDoc } from '../../collections/Profiles';
import AppLanguage from '../../common/AppLanguage';
import Catch from '../../components/Catch';

export type Props = {
  participantRef: DocumentReference<ProfileDoc>;
};

export function ParticipantNameMain({ participantRef }: Props) {
  const { i18n } = useTranslation('ChatScreen', { keyPrefix: 'ParticipantName' });

  const { data: participantSnap } = useFirestoreDoc(participantRef);

  if (!participantSnap.exists()) {
    throw new Error('Participant does not exist');
  }

  const participantDoc = useMemo(() => participantSnap.data(), [participantSnap]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {participantDoc.translations?.name[i18n.language as AppLanguage] ?? participantDoc.name}
    </>
  );
}

export default function ParticipantName(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <ParticipantNameMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
