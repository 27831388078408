import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
} from '@chakra-ui/react';
import { useField } from 'formik';

import { StoragePicture } from '../../common/StoragePicture';
import PictureInput from './PictureInput';

interface Props {
  // eslint-disable-next-line react/require-default-props
  helperText?: string;
  label: string;
  name: string;
}

export default function PictureFormControl({ helperText, label, name }: Props) {
  const [, meta] = useField<StoragePicture>(name);

  return (
    <FormControl isInvalid={!!meta.error}>
      <FormLabel>{label}</FormLabel>

      <Grid gap={2} templateColumns="repeat(3, 1fr)" width="100%">
        <PictureInput name={`${name}.0`} />
        <PictureInput name={`${name}.1`} />
        <PictureInput name={`${name}.2`} />
        <PictureInput name={`${name}.3`} />
        <PictureInput name={`${name}.4`} />
        <PictureInput name={`${name}.5`} />
      </Grid>

      {helperText ? (
        <FormHelperText>
          {helperText}
        </FormHelperText>
      ) : null}

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}
