import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  VStack,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { ChangeEvent, Suspense, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Tier from '../common/Tier';
import useShowError from '../hooks/useShowError';
import Catch from './Catch';

export interface Props {
  label: string;
  name: string;
}

export function TierFormControlMain({
  label, name,
}: Props) {
  const { t } = useTranslation('Tier');
  const showError = useShowError();

  const [input, meta, helper] = useField<number>(name);

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      helper.setValue(parseFloat(e.target.value))
        .catch(showError);
    },
    [helper, showError],
  );

  const handleSliderChange = useCallback(
    (value: number) => {
      helper.setValue(value)
        .catch(showError);
    },
    [helper, showError],
  );

  return (
    <FormControl isInvalid={!!meta.error}>
      <FormLabel>{label}</FormLabel>

      <VStack alignItems="stretch" spacing={4}>
        <Slider
          focusThumbOnChange={false}
          max={Tier.ZEUS}
          min={Tier.HERMES}
          onChange={handleSliderChange}
          step={1}
          value={input.value}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>

          <SliderThumb />
        </Slider>

        <Select onChange={handleInputChange} value={input.value}>
          <option value={Tier.HERMES}>{t('HERMES.label')}</option>
          <option value={Tier.APOLLO}>{t('APOLLO.label')}</option>
          <option value={Tier.POSEIDON}>{t('POSEIDON.label')}</option>
          <option value={Tier.ZEUS}>{t('ZEUS.label')}</option>
        </Select>
      </VStack>

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}

export default function TierFormControl(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <TierFormControlMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
