import {
  Avatar,
  AvatarBadge,
  AvatarProps,
  Icon,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import { Suspense, useMemo } from 'react';
import { LuUser } from 'react-icons/lu';
import { useFirestoreDoc } from 'reactfire';

import { isProfileComplete, ProfileDoc } from '../../../collections/Profiles';
import { getPhotoSizeUrl } from '../../../common/getPhotoSizeUrl';
import getPrimaryPhoto from '../../../common/getPrimaryPhoto';
import Catch from '../../../components/Catch';
import useCurrentTime from '../../../hooks/useCurrentTime';

export type Props = {
  participantRef: DocumentReference<ProfileDoc>;
} & AvatarProps;

export function ParticipantAvatarMain({ participantRef, ...avatarProps }: Props) {
  const { data: participantSnap } = useFirestoreDoc(participantRef);

  if (!participantSnap.exists()) {
    throw new Error('Participant does not exist');
  }

  const participantDoc = useMemo(() => participantSnap.data(), [participantSnap]);

  if (!isProfileComplete(participantDoc)) {
    throw new Error('Profile is not complete');
  }

  const primaryPictureSrc = useMemo(
    () => {
      const uri = getPrimaryPhoto(
        participantDoc.pictures,
        participantDoc.primaryPictureIndex,
      ).imgixUrl;

      if (!uri) {
        return undefined;
      }

      return getPhotoSizeUrl({
        faces: true,
        height: 48,
        uri,
        width: 48,
      });
    },
    [participantDoc.pictures, participantDoc.primaryPictureIndex],
  );

  const currentTime = useCurrentTime();

  return (
    <Avatar
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...avatarProps}
      icon={<Icon as={LuUser} />}
      loading="lazy"
      src={primaryPictureSrc}
    >
      {currentTime.getTime() - 1000 * 60 < (
        participantDoc.lastSeenAt?.toMillis() ?? 0
      ) ? (
        <AvatarBadge bg="green.500" border="none" boxSize={3} transform="none" />
        ) : null}
    </Avatar>
  );
}

export default function ParticipantAvatar(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <ParticipantAvatarMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
