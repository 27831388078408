import { IconType } from 'react-icons';
import {
  GiCrown,
  GiImperialCrown,
  GiJewelCrown,
  GiQueenCrown,
} from 'react-icons/gi';

import Tier from './Tier';

/* eslint-disable perfectionist/sort-objects */
const TierMap: Record<Tier, { colorScheme: string; icon: IconType; }> = {
  [Tier.HERMES]: { colorScheme: 'cyan', icon: GiJewelCrown },
  [Tier.APOLLO]: { colorScheme: 'lightBlue', icon: GiCrown },
  [Tier.POSEIDON]: { colorScheme: 'indigo', icon: GiQueenCrown },
  [Tier.ZEUS]: { colorScheme: 'deepPurple', icon: GiImperialCrown },
};
/* eslint-enable perfectionist/sort-objects */

export default TierMap;
