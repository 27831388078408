import { Icon, IconProps } from '@chakra-ui/react';

import Tier from '../common/Tier';
import TierMap from '../common/TierMap';

export type Props = {
  tier: Tier;
} & IconProps;

export default function TierIcon({
  tier,
  ...iconProps
}: Props) {
  if (tier === Tier.HERMES) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Icon as={TierMap[Tier.HERMES].icon} {...iconProps} />
    );
  }

  if (tier === Tier.APOLLO) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Icon as={TierMap[Tier.APOLLO].icon} {...iconProps} />
    );
  }

  if (tier === Tier.POSEIDON) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Icon as={TierMap[Tier.POSEIDON].icon} {...iconProps} />
    );
  }

  if (tier === Tier.ZEUS) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Icon as={TierMap[Tier.ZEUS].icon} {...iconProps} />
    );
  }

  return null;
}
