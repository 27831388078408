import {
  BoxProps,
  HStack,
  Icon,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  doc,
  getCountFromServer,
  query,
  where,
} from 'firebase/firestore';
import {
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuPlane } from 'react-icons/lu';

import { useDestinationsCollectionRef } from '../../../collections/Destinations';
import { useTripsCollectionRef } from '../../../collections/Trips';
import AppLanguage from '../../../common/AppLanguage';
import DestinationAlgoliaSearchRecord from '../../../common/DestinationAlgoliaSearchRecord';
import Catch from '../../../components/Catch';

export type Props = {
  destinationRecord: DestinationAlgoliaSearchRecord;
} & BoxProps;

export function DestinationDetailsMain({ destinationRecord, ...boxProps }: Props) {
  const { i18n } = useTranslation('TripCreateInitScreen', { keyPrefix: 'Destination.DestinationDetails' });

  const destinationsCollectionRef = useDestinationsCollectionRef();
  const destinationRef = useMemo(
    () => doc(destinationsCollectionRef, destinationRecord.objectID),
    [destinationsCollectionRef, destinationRecord.objectID],
  );

  const tripsCollectionRef = useTripsCollectionRef();
  const [tripsCount, setTripsCount] = useState<number>();
  useEffect(
    () => {
      getCountFromServer(
        query(
          tripsCollectionRef,
          where('destinationRef', '==', destinationRef),
        ),
      ).then((countSnap) => setTripsCount(countSnap.data().count)).catch(() => { });
    },
    [destinationRef, tripsCollectionRef],
  );

  return (
    <VStack
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      alignItems="stretch"
      gap={2}
      p={2}
    >
      <VStack alignItems="stretch" gap={0}>
        <Text
          flex={1}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {destinationRecord.name[i18n.language as AppLanguage]}
        </Text>

        <HStack gap={1}>
          <Text
            flexShrink={0}
            fontSize="sm"
          >
            {destinationRecord.countryEmoji}
          </Text>

          <Text
            flex={1}
            fontSize="sm"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {destinationRecord.countryName[i18n.language as AppLanguage]}
          </Text>

          <HStack flexShrink={0} gap={1}>
            {typeof tripsCount === 'number' ? (
              <Text>
                {tripsCount}
              </Text>
            ) : (
              <Skeleton h={4} w={3} />
            )}

            <Text>
              &times;
            </Text>

            <Icon as={LuPlane} />
          </HStack>
        </HStack>
      </VStack>
    </VStack>
  );
}

export default function DestinationDetails(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <DestinationDetailsMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
