import { Icon, IconButton, IconButtonProps } from '@chakra-ui/react';
import { DocumentReference, refEqual } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import { MouseEvent, Suspense, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LuHeart, LuHeartCrack } from 'react-icons/lu';

import { ProfileDoc } from '../collections/Profiles';
import Catch from './Catch';
import { useMyProfileSnap } from './snapProviders/MyProfileSnapProvider';
import { useOnSwipeLeft, useOnSwipeRight } from './SwipeActionsProvider';

export type Props = {
  profileRef: DocumentReference<ProfileDoc>;
} & Omit<IconButtonProps, 'aria-label' | 'colorScheme' | 'icon' | 'isDisabled' | 'isLoading' | 'onClick'>;

export function ProfileActionCellMain({
  profileRef,
  ...iconButtonProps
}: Props) {
  const { t } = useTranslation('ProfilesScreen', { keyPrefix: 'Profile' });

  const onDislike = useOnSwipeLeft();
  const onLike = useOnSwipeRight();

  const myProfileSnap = useMyProfileSnap();

  const handleDislikeClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();

      if (!onDislike) {
        return;
      }

      mixpanel.track('Profile Hide Clicked', { profileId: profileRef.id });

      onDislike(profileRef);
    },
    [onDislike, profileRef],
  );

  const handleLikeClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();

      if (!onLike) {
        return;
      }

      mixpanel.track('Profile Invite Clicked', { profileId: profileRef.id });

      onLike(profileRef);
    },
    [onLike, profileRef],
  );

  if (refEqual(myProfileSnap.ref, profileRef)) {
    return null;
  }

  return (
    <>
      {onDislike ? (
        <IconButton
          aria-label={t('dislikeButton.default')}
          icon={<Icon as={LuHeartCrack} />}
          onClick={handleDislikeClick}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...iconButtonProps}
        />
      ) : null}

      {onLike ? (
        <IconButton
          aria-label={t('likeButton.default')}
          colorScheme="pink"
          icon={<Icon as={LuHeart} />}
          onClick={handleLikeClick}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...iconButtonProps}
        />
      ) : null}
    </>
  );
}

export default function ProfileActionCell({
  profileRef,
  ...iconButtonProps
}: Props) {
  return (
    <Catch fallback={null}>
      <Suspense
        fallback={(
          <IconButton
            aria-label="Loading"
            colorScheme="pink"
            icon={<Icon as={LuHeart} />}
            isLoading
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...iconButtonProps}
          />
        )}
      >
        <ProfileActionCellMain
          profileRef={profileRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...iconButtonProps}
        />
      </Suspense>
    </Catch>
  );
}
