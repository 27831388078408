import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import AppLanguage from '../common/AppLanguage';
import { StoragePicture } from '../common/StoragePicture';
import Tier from '../common/Tier';
import { CityDoc } from './Cities';

export enum VenueDistinction {
  MICHELIN_BIB_GOURMAND = 'MICHELIN_BIB_GOURMAND',
  MICHELIN_ONE_STAR = 'MICHELIN_ONE_STAR',
  MICHELIN_SELECTED = 'MICHELIN_SELECTED',
  MICHELIN_THREE_STAR = 'MICHELIN_THREE_STAR',
  MICHELIN_TWO_STAR = 'MICHELIN_TWO_STAR',
}

export enum VenueLabel {
  ASIAN_INFLUENCES = 'ASIAN_INFLUENCES',
  CONTEMPORARY = 'CONTEMPORARY',
  CREATIVE = 'CREATIVE',
  INNOVATIVE = 'INNOVATIVE',
  ITALIAN = 'ITALIAN',
  JAPANESE = 'JAPANESE',
  MEATS_AND_GRILLS = 'MEATS_AND_GRILLS',
  MEDITERRANEAN = 'MEDITERRANEAN',
  MODERN = 'MODERN',
  SEAFOOD = 'SEAFOOD',
  SOUTH_AMERICAN = 'SOUTH_AMERICAN',
  TRADITIONAL = 'TRADITIONAL',
}

export enum VenueType {
  NIGHTCLUB = 'NIGHTCLUB',
  RESTAURANT = 'RESTAURANT',
}

export interface VenueDoc {
  _v: 1;
  cityRef: DocumentReference<CityDoc>;
  createdAt: Timestamp;
  description: Record<AppLanguage, string>;
  distinctions: VenueDistinction[];
  instagramTag?: string;
  labels: VenueLabel[];
  name: string;
  pictures: StoragePicture[];
  tier: Tier;
  types: VenueType[];
  updatedAt: Timestamp;
}

export const isVenueDoc = (
  data?: DocumentData,
): data is VenueDoc => true;

export const isVenueRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<VenueDoc> => ref.parent.id === 'venues';

function isVenueSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<VenueDoc>;
function isVenueSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<VenueDoc>;
function isVenueSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<VenueDoc> | QueryDocumentSnapshot<VenueDoc> {
  return isVenueRef(snap.ref);
}

export { isVenueSnap };

export const useVenuesCollectionRef = () => {
  const firestore = useFirestore();
  return collection(firestore, 'venues') as CollectionReference<VenueDoc>;
};
