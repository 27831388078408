import { QueryDocumentSnapshot } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext } from 'react';

import { ProfileDoc } from '../../collections/Profiles';

type Props = {
  profileSnap: QueryDocumentSnapshot<ProfileDoc>;
};

const ProfileSnapContext = createContext<null | QueryDocumentSnapshot<ProfileDoc>>(null);

export const useProfileSnap = () => {
  const ctx = useContext(ProfileSnapContext);

  if (!ctx) {
    throw new Error('ProfileSnapProvider');
  }

  return ctx;
};

export default function ProfileSnapProvider({
  children,
  profileSnap,
}: PropsWithChildren<Props>) {
  return (
    <ProfileSnapContext.Provider value={profileSnap}>
      {children}
    </ProfileSnapContext.Provider>
  );
}
