import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export interface Data {
  name: string;
}

export type Result = Record<string, boolean>;

export default function useValidateProfileName() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'validateProfileName'), [functions]);
}
