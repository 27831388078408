import { Button, Icon } from '@chakra-ui/react';
import { setDoc, Timestamp } from 'firebase/firestore';
import { Suspense, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuEye, LuEyeOff } from 'react-icons/lu';

import { ProfileStatus } from '../../collections/Profiles';
import Catch from '../../components/Catch';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import useShowError from '../../hooks/useShowError';

export function ProfileStatusButtonMain() {
  const { t } = useTranslation('SettingsScreen', { keyPrefix: 'ProfileStatusButton' });

  const showError = useShowError();

  const myProfileSnap = useMyProfileSnap();
  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  const handlePublishClick = useCallback(
    () => {
      setDoc(
        myProfileSnap.ref,
        { status: ProfileStatus.PUBLISHED, updatedAt: Timestamp.now() },
        { merge: true },
      )
        .catch(showError);
    },
    [myProfileSnap.ref, showError],
  );

  const handleUnpublishClick = useCallback(
    () => {
      setDoc(
        myProfileSnap.ref,
        { status: ProfileStatus.UNPUBLISHED, updatedAt: Timestamp.now() },
        { merge: true },
      )
        .catch(showError);
    },
    [myProfileSnap.ref, showError],
  );

  if (myProfileDoc.status === ProfileStatus.UNPUBLISHED) {
    return (
      <Button
        justifyContent="start"
        leftIcon={<Icon as={LuEye} />}
        loadingText={t('publishButton.loading')}
        onClick={handlePublishClick}
        variant="ghost"
      >
        {t('publishButton.default')}
      </Button>
    );
  }

  if (myProfileDoc.status === ProfileStatus.PUBLISHED) {
    return (
      <Button
        justifyContent="start"
        leftIcon={<Icon as={LuEyeOff} />}
        loadingText={t('unpublishButton.loading')}
        onClick={handleUnpublishClick}
        variant="ghost"
      >
        {t('unpublishButton.default')}
      </Button>
    );
  }

  return null;
}

export default function ProfileStatusButton() {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <ProfileStatusButtonMain />
      </Suspense>
    </Catch>
  );
}
