import { HStack } from '@chakra-ui/react';
import { refEqual } from 'firebase/firestore';
import _ from 'lodash';
import { Suspense, useMemo } from 'react';

import Catch from '../../components/Catch';
import { useConversationSnap } from '../../components/snapProviders/ConversationSnapProvider';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import TypingParticipant from './TypingParticipant';

export function TypingParticipantsMain() {
  const conversationSnap = useConversationSnap();
  const conversationDoc = useMemo(() => conversationSnap.data(), [conversationSnap]);
  const myProfileSnap = useMyProfileSnap();

  const typingParticipantRefs = useMemo(
    () => _.filter(
      conversationDoc.typingParticipantRefs,
      (ref) => !refEqual(ref, myProfileSnap.ref),
    ),
    [conversationDoc.typingParticipantRefs, myProfileSnap.ref],
  );

  if (!typingParticipantRefs.length) {
    return null;
  }

  return (
    <HStack>
      {typingParticipantRefs.map((typingParticipantRef) => (
        <TypingParticipant key={typingParticipantRef.id} profileRef={typingParticipantRef} />
      ))}
    </HStack>
  );
}

export default function TypingParticipants() {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <TypingParticipantsMain />
      </Suspense>
    </Catch>
  );
}
