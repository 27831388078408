import {
  AvatarGroup,
  Badge,
  HStack,
  Icon,
  Skeleton,
  SkeletonCircle,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  limit,
  orderBy,
  query,
  refEqual,
  where,
} from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuCheck, LuCheckCheck } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import { useMessagesCollectionRef } from '../../../collections/Messages';
import AppLanguage from '../../../common/AppLanguage';
import Catch from '../../../components/Catch';
import DocumentLoader from '../../../components/DocumentLoader';
import { useConversationSnap } from '../../../components/snapProviders/ConversationSnapProvider';
import { useMyProfileSnap } from '../../../components/snapProviders/MyProfileSnapProvider';
import ParticipantAvatar from './ParticipantAvatar';

export function ConversationMain() {
  const conversationSnap = useConversationSnap();
  const { i18n, t } = useTranslation('ChatsScreen', { keyPrefix: 'Conversation' });

  const myProfileSnap = useMyProfileSnap();

  const conversationDoc = useMemo(() => conversationSnap.data(), [conversationSnap]);

  const messagesCollectionRef = useMessagesCollectionRef();
  const { data: messagesSnap } = useFirestoreCollection(
    query(
      messagesCollectionRef,
      where('conversationRef', '==', conversationSnap.ref),
      orderBy('createdAt', 'desc'),
      limit(1),
    ),
  );

  const { data: unreadMessagesSnap } = useFirestoreCollection(
    query(
      messagesCollectionRef,
      where('conversationRef', '==', conversationSnap.ref),
      where('notReadByRefs', 'array-contains', myProfileSnap.ref),
    ),
  );

  const unreadMessagesCount = useMemo(
    () => unreadMessagesSnap.docs.length,
    [unreadMessagesSnap.docs.length],
  );

  const lastMessageSnap = useMemo(() => _.first(messagesSnap.docs), [messagesSnap.docs]);
  const lastMessageDoc = useMemo(() => lastMessageSnap?.data(), [lastMessageSnap]);

  const participantRefs = useMemo(
    () => _.filter(
      conversationDoc.participantRefs,
      (participantRef) => !refEqual(participantRef, myProfileSnap.ref),
    ),
    [conversationDoc.participantRefs, myProfileSnap.ref],
  );

  const lastMessageTimestamp = useMemo(
    () => (lastMessageDoc ? t('lastMessageTimestamp', {
      createdAt: lastMessageDoc.createdAt.toDate(),
      formatParams: {
        createdAt: moment(lastMessageDoc.createdAt.toDate()).endOf('day').isBefore(moment())
          ? { day: 'numeric', month: 'numeric', year: 'numeric' }
          : { hour: 'numeric', minute: 'numeric' },
      },
    }) : ''),
    [lastMessageDoc, t],
  );

  const isRead = useMemo(
    () => (lastMessageDoc?.readByRefs ?? []).length > 0,
    [lastMessageDoc?.readByRefs],
  );

  return (
    <HStack
      alignItems="stretch"
      as={Link}
      cursor="pointer"
      spacing={4}
      to={conversationSnap.id}
    >
      <AvatarGroup max={2}>
        {participantRefs.map((participantRef) => (
          <ParticipantAvatar key={participantRef.id} participantRef={participantRef} />
        ))}
      </AvatarGroup>

      <VStack alignItems="stretch" flex={1} minW={0} spacing={0}>
        <HStack>
          <Text
            flex={1}
            fontWeight="bold"
            overflow="hidden"
            size="sm"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {participantRefs.map((participantRef) => (
              <DocumentLoader key={participantRef.id} reference={participantRef}>
                {(snap) => {
                  const doc = snap.data();

                  return (
                    <Text as="span">
                      {doc.translations?.name[i18n.language as AppLanguage] ?? doc.name}
                    </Text>
                  );
                }}
              </DocumentLoader>
            ))}
          </Text>

          {lastMessageDoc ? (
            <Text color="gray.500" size="sm">
              {lastMessageTimestamp}
            </Text>
          ) : null}
        </HStack>

        {lastMessageDoc ? (
          <HStack>
            {!refEqual(lastMessageDoc.senderRef, myProfileSnap.ref) ? (
              <DocumentLoader reference={lastMessageDoc.senderRef}>
                {(snap) => {
                  const doc = snap.data();

                  return (
                    <Text color="gray.500" size="sm">
                      {t('lastMessageSenderOther', {
                        nickname: doc.translations?.name[i18n.language as AppLanguage] ?? doc.name,
                      })}
                    </Text>
                  );
                }}
              </DocumentLoader>
            ) : (
              <Text color="gray.500" size="sm">
                {t('lastMessageSenderSelf')}
              </Text>
            )}

            <Text
              color="gray.300"
              flex={1}
              overflow="hidden"
              size="sm"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {lastMessageDoc.text}
            </Text>

            {refEqual(lastMessageDoc.senderRef, myProfileSnap.ref) ? (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {isRead ? (
                  <Icon as={LuCheckCheck} />
                ) : (
                  <Icon as={LuCheck} />
                )}
              </>
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {unreadMessagesCount > 0 ? (
                  <Badge colorScheme="red">
                    {unreadMessagesCount}
                  </Badge>
                ) : null}
              </>
            )}
          </HStack>
        ) : null}

        {!lastMessageSnap ? (
          <Text
            color="gray.500"
            flex={1}
            fontStyle="italic"
            overflow="hidden"
            size="sm"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {t('lastMessageNone')}
          </Text>
        ) : null}
      </VStack>
    </HStack>
  );
}

export function ConversationLoading() {
  return (
    <HStack alignItems="stretch" spacing={4}>
      <SkeletonCircle boxSize={12} />
      <Skeleton flex={1} h={12} />
    </HStack>
  );
}

export default function Conversation() {
  return (
    <Catch fallback={null}>
      <Suspense fallback={<ConversationLoading />}>
        <ConversationMain />
      </Suspense>
    </Catch>
  );
}
