import { Icon, IconButton, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { LuWine } from 'react-icons/lu';
import { Link, useMatch } from 'react-router-dom';

export default function ReservationsButton() {
  const { t } = useTranslation('MainLayout');

  const match = useMatch('/reservations/*');

  return (
    <VStack alignItems="center" spacing={0}>
      <IconButton
        aria-label={t('reservationsButton.default')}
        as={Link}
        className="reservationsButton"
        icon={<Icon as={LuWine} boxSize={6} />}
        isActive={!!match}
        isRound
        size="lg"
        to="/reservations"
        variant="ghost"
      />
    </VStack>
  );
}
