import {
  Center,
  Container,
  Grid,
  Portal,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  doc,
  limit,
  orderBy,
  query,
  setDoc,
  Timestamp,
  where,
} from 'firebase/firestore';
import { defaults, slice } from 'lodash';
import moment from 'moment';
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { List, ListRowProps } from 'react-virtualized';
import { useFirestoreCollection } from 'reactfire';

import { ApplicationStatus, useApplicationsCollectionRef } from '../../collections/Applications';
import { useProfilesCollectionRef } from '../../collections/Profiles';
import { ReservationStatus, useReservationsCollectionRef } from '../../collections/Reservations';
import Gender from '../../common/Gender';
import ReservationAlgoliaSearchRecord from '../../common/ReservationAlgoliaSearchRecord';
import { useAlgoliaSearchClient } from '../../components/AlgoliaSearchClientProvider';
import Catch from '../../components/Catch';
import LogoIcon from '../../components/LogoIcon';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import useShowError from '../../hooks/useShowError';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import ReservationRow, { ReservationRowLoading } from './ReservationRow';
import ReservationWrapper from './ReservationWrapper';

export type Props = {
  height: number;
  width: number;
};

export function ReservationGridMain({ height, width }: Props) {
  const { t } = useTranslation('TheirReservationsScreen', { keyPrefix: 'ReservationList' });
  const showError = useShowError();

  const { reservationId } = useParams<{ reservationId?: string }>();

  const client = useAlgoliaSearchClient();
  const reservationsIndex = useMemo(() => client.initIndex('reservations'), [client]);

  const myProfileSnap = useMyProfileSnap();
  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  const preferences = useMemo(
    () => defaults(
      {},
      myProfileDoc?.preferences ?? {},
      {
        age: {
          max: 100,
          min: 18,
        },
        height: {
          max: 300,
          min: 100,
        },
        weight: {
          max: 300,
          min: 30,
        },
      },
    ),
    [myProfileDoc?.preferences],
  );

  const filters = useMemo(
    () => {
      const nextFilters: (string | string[])[] = [];
      nextFilters.push(`status: ${ReservationStatus.PUBLISHED}`);
      nextFilters.push(`organizer.gender: ${Gender.MALE}`);

      return nextFilters.join(' AND ');
    },
    [],
  );

  const numericFilters = useMemo(
    () => {
      const nextOptionalFilters: (string | string[])[] = [];

      nextOptionalFilters.push(`organizer.age: ${preferences.age.min} TO ${preferences.age.max}`);
      nextOptionalFilters.push(`organizer.height: ${preferences.height.min} TO ${preferences.height.max}`);
      nextOptionalFilters.push(`organizer.weight: ${preferences.weight.min} TO ${preferences.weight.max}`);

      return nextOptionalFilters.join(', ');
    },
    [
      preferences.age.max,
      preferences.age.min,
      preferences.height.max,
      preferences.height.min,
      preferences.weight.max,
      preferences.weight.min,
    ],
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reservations, setReservations] = useState<ReservationAlgoliaSearchRecord[]>([]);

  const applicationsCollectionRef = useApplicationsCollectionRef();
  const { data: applicationsSnap } = useFirestoreCollection(
    query(
      applicationsCollectionRef,
      where('applicantRef', '==', myProfileSnap.ref),
      orderBy('sentAt', 'desc'),
      limit(1000),
    ),
  );

  useEffect(
    () => {
      setIsLoading(true);
      reservationsIndex.search<ReservationAlgoliaSearchRecord>('', {
        clickAnalytics: true,
        // enablePersonalization: true,
        filters,
        length: 1000,
        numericFilters,
        offset: 0,
        userToken: myProfileSnap.ref.id,
      }).finally(() => {
        setIsLoading(false);
      }).then((response) => {
        setReservations(response.hits);
      }).catch(showError);
    },
    [filters, numericFilters, myProfileSnap.ref.id, showError, reservationsIndex],
  );

  const { height: wHeight, width: wWidth } = useWindowDimensions();

  const [openReservations, setOpenReservations] = useState<ReservationAlgoliaSearchRecord[]>([]);

  useEffect(
    () => {
      if (!reservationId) {
        return;
      }

      reservationsIndex
        .getObject<ReservationAlgoliaSearchRecord>(reservationId)
        .finally(() => {
          setIsLoading(false);
        }).then((response) => {
          setOpenReservations([response]);
        }).catch(showError);
    },
    [showError, reservationId, reservationsIndex],
  );

  const [startIndex, setStartIndex] = useState<number>(0);

  const handleClick = useCallback(
    (index: number) => {
      setStartIndex(index);
      setOpenReservations(slice(reservations, index, Math.min(reservations.length, index + 5)));
    },
    [reservations],
  );

  const handleExit = useCallback(
    () => {
      setStartIndex(0);
      setOpenReservations([]);
    },
    [],
  );

  const handleSwipeLeft = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async () => {
      setStartIndex(startIndex + 1);
      setOpenReservations(slice(
        reservations,
        startIndex + 1,
        Math.min(reservations.length, startIndex + 6),
      ));
    },
    [startIndex, reservations],
  );

  const profilesCollectionRef = useProfilesCollectionRef();
  const reservationsCollectionRef = useReservationsCollectionRef();

  const handleApply = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (reservationRecord: ReservationAlgoliaSearchRecord) => {
      const applicationRef = doc(applicationsCollectionRef);

      await setDoc(
        applicationRef,
        {
          _v: 1,
          applicantRef: myProfileSnap.ref,
          expiresAt: Timestamp.fromDate(moment.utc().add(1, 'year').toDate()),
          organizerRef: doc(profilesCollectionRef, reservationRecord.organizer.id),
          reservationRef: doc(reservationsCollectionRef, reservationRecord.objectID),
          sentAt: Timestamp.now(),
          status: ApplicationStatus.SENT,
        },
      );
    },
    [
      applicationsCollectionRef,
      myProfileSnap.ref,
      profilesCollectionRef,
      reservationsCollectionRef,
    ],
  );

  const handleSwipeRight = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (reservationRecord: ReservationAlgoliaSearchRecord) => {
      await handleApply(reservationRecord);

      setStartIndex(startIndex + 1);
      setOpenReservations(slice(
        reservations,
        startIndex + 1,
        Math.min(reservations.length, startIndex + 6),
      ));
    },
    [handleApply, startIndex, reservations],
  );

  const rowRenderer = useCallback(
    ({ index, style }: ListRowProps) => (
      <ReservationRow
        // eslint-disable-next-line max-len
        applicationStatus={applicationsSnap.docs.find((s) => s.data().reservationRef?.id === reservations[index].objectID)?.data().status}
        key={reservations[index].objectID}
        onClick={() => handleClick(index)}
        onSwipeRight={() => { handleApply(reservations[index]).catch(() => { }); }}
        reservationRecord={reservations[index]}
        style={style}
      />
    ),
    [applicationsSnap.docs, handleApply, handleClick, reservations],
  );

  if (reservations.length) {
    return (
      <>
        <List
          height={height}
          overscanRowCount={3}
          rowCount={reservations.length}
          rowHeight={200}
          // eslint-disable-next-line react/no-unstable-nested-components
          rowRenderer={rowRenderer}
          width={width}
        />

        <Portal>
          {openReservations.map((reservationRecord, i) => (
            <ReservationWrapper
              // eslint-disable-next-line max-len
              applicationStatus={applicationsSnap.docs.find((s) => s.data().reservationRef?.id === reservationRecord.objectID)?.data().status}
              height={wHeight}
              index={i}
              key={reservationRecord.objectID}
              onExit={handleExit}
              onSwipeLeft={handleSwipeLeft}
              onSwipeRight={handleSwipeRight}
              queryId=""
              reservationRecord={reservationRecord}
              searchResultPosition={0}
              top={0}
              width={wWidth}
              zIndex={(openReservations.length - i) * 10 + 100}
            />
          ))}
        </Portal>
      </>
    );
  }

  if (isLoading) {
    return (
      <Container height="100%" maxW="lg">
        <Center height="100%">
          <LogoIcon boxSize={16} />
        </Center>
      </Container>
    );
  }

  return (
    <Container height="100%" maxW="lg">
      <Center height="100%">
        <Text textAlign="center">
          {t('emptyList.body')}
        </Text>
      </Center>
    </Container>
  );
}

export function ReservationGridLoading() {
  return (
    <VStack alignItems="stretch" className="myReservationsList" gap={8}>
      <VStack alignItems="stretch" gap={2}>
        <Skeleton h={5} />

        <Grid gap={4} gridAutoRows="1fr" templateColumns="repeat(2, 1fr)">
          <ReservationRowLoading />
          <ReservationRowLoading />
          <ReservationRowLoading />
          <ReservationRowLoading />
        </Grid>
      </VStack>
    </VStack>
  );
}

export default function ReservationGrid(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<ReservationGridLoading />}>
        <ReservationGridMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
