import {
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  deleteField,
  query,
  QueryDocumentSnapshot,
  setDoc,
  where,
} from 'firebase/firestore';
import { Suspense, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LuTrash } from 'react-icons/lu';
import { useFirestoreCollection } from 'reactfire';

import { TelegramUserDoc, useTelegramUsersCollectionRef } from '../../collections/TelegramUsers';
import Catch from '../../components/Catch';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import TelegramButton from './TelegramButton';

export function TelegramUsersMain() {
  const { t } = useTranslation('SettingsScreen', { keyPrefix: 'TelegramUsers' });

  const telegramUsersCollectionRef = useTelegramUsersCollectionRef();

  const myProfileSnap = useMyProfileSnap();

  const { data: telegramUsersSnap } = useFirestoreCollection(
    query(
      telegramUsersCollectionRef,
      where('uid', '==', myProfileSnap.ref.id),
    ),
  );

  const handleRemoveClick = useCallback(
    (snap: QueryDocumentSnapshot<TelegramUserDoc>) => {
      setDoc(
        snap.ref,
        { uid: deleteField() },
        { merge: true },
      ).catch(() => { });
    },
    [],
  );

  return (
    <VStack alignItems="stretch">
      <Text fontSize="sm">
        {t('title')}
      </Text>

      {telegramUsersSnap.docs.map((snap) => (
        <HStack key={snap.id} px={4}>
          <Text flex={1}>{snap.data().telegramUser.first_name}</Text>

          <IconButton
            aria-label={t('disconnectButton.default')}
            colorScheme="red"
            icon={<Icon as={LuTrash} />}
            onClick={() => handleRemoveClick(snap)}
            size="sm"
            variant="ghost"
          />
        </HStack>
      ))}

      <TelegramButton />
    </VStack>
  );
}

export default function TelegramUsers() {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <TelegramUsersMain />
      </Suspense>
    </Catch>
  );
}
