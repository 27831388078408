import { Center } from '@chakra-ui/react';
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import LogoIcon from './components/LogoIcon';
import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

function base64URLdecode(str: string): string {
  const base64Encoded = str.replace(/-/g, '+').replace(/_/g, '/');
  const padding = str.length % 4 === 0 ? '' : '='.repeat(4 - (str.length % 4));
  const base64WithPadding = base64Encoded + padding;
  return atob(base64WithPadding)
    .split('')
    .map((char) => String.fromCharCode(char.charCodeAt(0)))
    .join('');
}

if (Telegram.WebApp.initData !== '') {
  const params = new URLSearchParams(Telegram.WebApp.initData);

  const startParam = params.get('start_param');

  if (startParam) {
    try {
      const decoded = base64URLdecode(startParam);
      const data = JSON.parse(decoded) as { href?: string };
      if (data.href) {
        if (window.location.href !== data.href) {
          window.location.replace(data.href);
        }
      }
    } catch (err) {
      /* do nothing */
    }
  }
}

root.render(
  <StrictMode>
    <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
      <App />
    </Suspense>
  </StrictMode>,
);
