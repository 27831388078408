import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import AppLanguage from '../common/AppLanguage';
import { type ProfileDoc } from './Profiles';
import { type VenueDoc } from './Venues';

export enum ReservationStatus {
  CREATED = 'CREATED',
  EXPIRED = 'EXPIRED',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export type ReservationDoc = {
  _v: 1;
  checkoutSessionUrl?: string;
  createdAt: Timestamp;
  description: string;
  indexedAt?: Timestamp;
  organizerRef: DocumentReference<ProfileDoc>;
  publishedAt?: Timestamp;
  startsAt: Timestamp;
  status: ReservationStatus;
  translations?: {
    description: Record<AppLanguage, string>;
  };
  unpublishedAt?: Timestamp;
  updatedAt: Timestamp;
  venueRef: DocumentReference<VenueDoc>;
};

export const isReservationDoc = (
  doc?: DocumentData,
): doc is ReservationDoc => true;

export const isReservationRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ReservationDoc> => ref.parent.id === 'reservations';

function isReservationSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<ReservationDoc>;
function isReservationSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<ReservationDoc>;
function isReservationSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<ReservationDoc> | QueryDocumentSnapshot<ReservationDoc> {
  return isReservationRef(snap.ref);
}

export { isReservationSnap };

export const getReservationsCollectionRef = (firestore: Firestore) => collection(firestore, 'reservations') as CollectionReference<ReservationDoc>;

export const useReservationsCollectionRef = () => {
  const firestore = useFirestore();
  return getReservationsCollectionRef(firestore);
};
