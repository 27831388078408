import {
  Box,
  HStack,
  Icon,
  Text,
} from '@chakra-ui/react';
import {
  arrayRemove,
  arrayUnion,
  refEqual,
  writeBatch,
} from 'firebase/firestore';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuCheck, LuCheckCheck } from 'react-icons/lu';
import { useFirestore } from 'reactfire';

import { useMessageSnap } from '../../components/snapProviders/MessageSnapProvider';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';

export default function TextMessage() {
  const messageSnap = useMessageSnap();
  const { t } = useTranslation('ChatScreen');
  const myProfileSnap = useMyProfileSnap();

  const messageDoc = useMemo(() => messageSnap.data(), [messageSnap]);

  const firestore = useFirestore();

  useEffect(
    () => {
      if (
        !_.some(messageDoc.readByRefs, (ref) => refEqual(ref, myProfileSnap.ref))
        && !refEqual(messageDoc.senderRef, myProfileSnap.ref)
      ) {
        const batch = writeBatch(firestore);

        batch.set(messageSnap.ref, {
          notReadByRefs: arrayRemove(myProfileSnap.ref),
          readByRefs: arrayUnion(myProfileSnap.ref),
        }, { merge: true });

        batch.commit().catch(() => { });
      }
    },
    [firestore, messageDoc.readByRefs, messageDoc.senderRef, messageSnap.ref, myProfileSnap.ref],
  );

  const isRead = useMemo(
    () => (messageDoc.readByRefs ?? []).length > 0,
    [messageDoc.readByRefs],
  );

  const myMessage = useMemo(
    () => refEqual(messageDoc.senderRef, myProfileSnap.ref),
    [messageDoc.senderRef, myProfileSnap.ref],
  );

  if (!myMessage) {
    return (
      <HStack
        flexDirection="row"
        gap={4}
        justifyContent="left"
        style={{
          overflowAnchor: 'none',
        }}
      >
        <Box
          background="gray.300"
          borderRadius="3xl"
          color="gray.800"
          px={4}
          py={2}
        >
          <Text>
            {messageDoc.text}
          </Text>
        </Box>

        <Text color="gray.500" fontSize="xs">
          {t('createdAt', {
            createdAt: messageDoc.createdAt.toDate(),
            formatParams: { createdAt: { hour: 'numeric', minute: 'numeric' } },
          })}
        </Text>
      </HStack>
    );
  }

  return (
    <HStack
      flexDirection="row-reverse"
      gap={4}
      justifyContent="right"
      style={{
        overflowAnchor: 'none',
      }}
    >
      <Box
        background="blue.300"
        borderRadius="3xl"
        color="gray.800"
        px={4}
        py={2}
      >
        <Text>
          {messageDoc.text}
        </Text>
      </Box>

      <Text color="gray.500" fontSize="xs">
        {t('createdAt', {
          createdAt: messageDoc.createdAt.toDate(),
          formatParams: { createdAt: { hour: 'numeric', minute: 'numeric' } },
        })}
      </Text>

      {isRead ? (
        <Icon as={LuCheckCheck} />
      ) : (
        <Icon as={LuCheck} />
      )}
    </HStack>
  );
}
