import { Badge, BadgeProps } from '@chakra-ui/react';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Gender from '../../common/Gender';
import HairLength from '../../common/HairLength';
import Catch from '../Catch';
import { useProfileSnap } from '../snapProviders/ProfileSnapProvider';

export type Props = BadgeProps;

export function HairLengthBadgeMain({
  ...badgeProps
}: Props) {
  const profileSnap = useProfileSnap();
  const profileDoc = useMemo(() => profileSnap.data(), [profileSnap]);

  const { t } = useTranslation('HairLength');

  if (profileDoc.gender !== Gender.FEMALE) {
    return null;
  }

  switch (profileDoc.hairLength) {
    case HairLength.BUZZ_CUT: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('BUZZ_CUT.label')}
        </Badge>
      );
    }
    case HairLength.EAR: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('EAR.label')}
        </Badge>
      );
    }
    case HairLength.CHIN: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('CHIN.label')}
        </Badge>
      );
    }
    case HairLength.SHOULDER: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('SHOULDER.label')}
        </Badge>
      );
    }
    case HairLength.ARMPIT: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('ARMPIT.label')}
        </Badge>
      );
    }
    case HairLength.MID_BACK: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('MID_BACK.label')}
        </Badge>
      );
    }
    case HairLength.TAILBONE: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('TAILBONE.label')}
        </Badge>
      );
    }
    default: {
      return null;
    }
  }
}

export default function HairLengthBadge(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <HairLengthBadgeMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
