/* eslint-disable perfectionist/sort-switch-case */
/* eslint-disable perfectionist/sort-enums */
enum Tier {
  HERMES = 0,
  APOLLO = 1,
  POSEIDON = 2,
  ZEUS = 3,
}

export default Tier;
