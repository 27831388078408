import { Badge, BadgeProps } from '@chakra-ui/react';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import Wealth from '../../../common/Wealth';
import Catch from '../../../components/Catch';

export type Props = { wealth: Wealth } & BadgeProps;

export function WealthBadgeMain({
  wealth,
  ...badgeProps
}: Props) {
  const { t } = useTranslation('Wealth');

  switch (wealth) {
    case Wealth.BROKE: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('BROKE.label')}
        </Badge>
      );
    }
    case Wealth.AVERAGE: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('AVERAGE.label')}
        </Badge>
      );
    }
    case Wealth.RICH: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('RICH.label')}
        </Badge>
      );
    }
    case Wealth.AFFLUENT: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('AFFLUENT.label')}
        </Badge>
      );
    }
    case Wealth.WEALTHY: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('WEALTHY.label')}
        </Badge>
      );
    }
    case Wealth.MILLIONAIRE: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('MILLIONAIRE.label')}
        </Badge>
      );
    }
    default: {
      return null;
    }
  }
}

export default function WealthBadge(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <WealthBadgeMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
