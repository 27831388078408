import {
  BoxProps,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestoreDoc } from 'reactfire';

import AppLanguage from '../../common/AppLanguage';
import Catch from '../../components/Catch';
import { useTripSnap } from '../../components/snapProviders/TripSnapProvider';

export type Props = BoxProps;

export function TripDetailsMain({ ...boxProps }: Props) {
  const tripSnap = useTripSnap();
  const tripDoc = useMemo(() => tripSnap.data(), [tripSnap]);

  const { i18n, t } = useTranslation('PlansScreen', { keyPrefix: 'Trip.TripDetails' });

  const { data: destinationSnap } = useFirestoreDoc(tripDoc.destinationRef);

  if (!destinationSnap.exists()) {
    throw new Error('Destination does not exist');
  }

  const destinationDoc = useMemo(() => destinationSnap.data(), [destinationSnap]);

  return (
    <VStack
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      alignItems="stretch"
      gap={2}
      minW={0}
    >
      <Text
        fontSize="lg"
        fontWeight="bold"
      >
        {destinationDoc.name[i18n.language as AppLanguage]}
      </Text>

      <HStack gap={1}>
        <Text
          flexShrink={0}
          fontSize="sm"
        >
          {destinationDoc.countryEmoji}
        </Text>

        <Text
          flex={1}
          fontSize="sm"
        >
          {destinationDoc.countryName[i18n.language as AppLanguage]}
        </Text>
      </HStack>

      <Text
        fontSize="sm"
      >
        {t('departure', {
          departure: moment(tripDoc.departure).toDate(),
          formatParams: { departure: { day: 'numeric', month: 'short' } },
        })}
      </Text>
    </VStack>
  );
}

export default function TripDetails(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <TripDetailsMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
