import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import Language from '../common/Language';

export type TelegramUser = {
  added_to_attachment_menu?: true;
  allows_write_to_pm?: boolean;
  first_name: string;
  id: number;
  is_bot: boolean;
  is_premium?: boolean;
  language_code?: Language;
  last_name?: string;
  username?: string;
};

export type TelegramUserDoc = {
  telegramUser: TelegramUser;
  uid: string;
};

export const isTelegramUserDoc = (
  doc?: DocumentData,
): doc is TelegramUserDoc => true;

export const isTelegramUserRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<TelegramUserDoc> => ref.parent.id === 'telegramUsers';

function isTelegramUserSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<TelegramUserDoc>;
function isTelegramUserSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<TelegramUserDoc>;
function isTelegramUserSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<TelegramUserDoc> | QueryDocumentSnapshot<TelegramUserDoc> {
  return isTelegramUserRef(snap.ref);
}

export { isTelegramUserSnap };

export const getTelegramUsersCollectionRef = (firestore: Firestore) => collection(firestore, 'telegramUsers') as CollectionReference<TelegramUserDoc>;

export const useTelegramUsersCollectionRef = () => {
  const firestore = useFirestore();
  return getTelegramUsersCollectionRef(firestore);
};
