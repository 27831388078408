import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import { type ProfileDoc } from './Profiles';
import { type ReservationDoc } from './Reservations';
import { type TripDoc } from './Trips';
import { type VentureDoc } from './Ventures';

export enum ConversationStatus {
  CLOSED = 'CLOSED',
  OPENED = 'OPENED',
}

export enum ConversationClosedBy {
  GUEST = 'GUEST',
  HOST = 'HOST',
  SYSTEM = 'SYSTEM',
}

export interface ConversationDoc {
  _v: 1;
  closedAt?: Timestamp;
  closedBy?: ConversationClosedBy;
  expiresAt: Timestamp;
  lastActionAt: Timestamp;
  notReadByRefs: DocumentReference<ProfileDoc>[];
  openedAt?: Timestamp;
  participantRefs: DocumentReference<ProfileDoc>[];
  readByRefs: DocumentReference<ProfileDoc>[];
  reservationRef?: DocumentReference<ReservationDoc>;
  status: ConversationStatus;
  tripRef?: DocumentReference<TripDoc>;
  typingParticipantRefs: DocumentReference<ProfileDoc>[];
  ventureRef?: DocumentReference<VentureDoc>;
}

export const isConversationDoc = (
  data?: DocumentData,
): data is ConversationDoc => true;

export const isConversationRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ConversationDoc> => ref.parent.id === 'conversations';

function isConversationSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<ConversationDoc>;
function isConversationSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<ConversationDoc>;
function isConversationSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<ConversationDoc> | QueryDocumentSnapshot<ConversationDoc> {
  return isConversationRef(snap.ref);
}

export { isConversationSnap };

export const getConversationsCollectionRef = (firestore: Firestore) => collection(firestore, 'conversations') as CollectionReference<ConversationDoc>;

export const useConversationsCollectionRef = () => {
  const firestore = useFirestore();
  return getConversationsCollectionRef(firestore);
};
