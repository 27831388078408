import {
  Box,
  HStack,
  Radio,
  Text,
  useRadio,
  UseRadioProps,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export type Props = UseRadioProps;

export default function MonogamyCard(props: Props) {
  const { t } = useTranslation('RelationshipStyle', { keyPrefix: 'MONOGAMY' });

  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label" flex={1} userSelect="none">
      <input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...input}
      />
      <Box
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...checkbox}
        _dark={{
          _checked: {
            backgroundColor: 'rgb(from var(--chakra-colors-green-200) r g b / 0.16)',
            color: 'var(--chakra-colors-green-200)',
          },
          _disabled: {
            backgroundColor: 'rgb(from var(--chakra-colors-gray-600) r g b / 0.16)',
            color: 'var(--chakra-colors-gray-600)',
          },
          backgroundColor: 'rgb(from var(--chakra-colors-gray-200) r g b / 0.16)',
          color: 'var(--chakra-colors-gray-200)',
        }}
        _disabled={{
          cursor: 'not-allowed',
        }}
        _light={{
          _checked: {
            backgroundColor: 'var(--chakra-colors-green-100)',
            color: 'var(--chakra-colors-green-800)',
          },
          _disabled: {
            backgroundColor: 'var(--chakra-colors-gray-100)',
            color: 'var(--chakra-colors-gray-500)',
          },
          backgroundColor: 'var(--chakra-colors-gray-100)',
          color: 'var(--chakra-colors-gray-800)',
        }}
        borderRadius="md"
        cursor="pointer"
        height="100%"
        px={4}
        py={2}
      >
        <VStack alignItems="stretch" height="100%">
          <HStack alignItems="center">
            <Radio
              colorScheme="green"
              // eslint-disable-next-line react/destructuring-assignment
              isChecked={props.isChecked}
            />

            <Text flex={1} fontSize="xl" fontWeight="bold">
              {t('label')}
            </Text>
          </HStack>

          <Text fontSize="sm">
            {t('description')}
          </Text>
        </VStack>
      </Box>
    </Box>
  );
}
