import { Center } from '@chakra-ui/react';
import { Suspense, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import { getProfileHiddenRef } from '../collections/Profiles';
import Catch from '../components/Catch';
import LogoIcon from '../components/LogoIcon';
import { useMyProfileRef } from '../components/refProviders/MyProfileRefProvider';
import MyProfileHiddenSnapProvider from '../components/snapProviders/MyProfileHiddenSnapProvider';
import MyProfileSnapProvider from '../components/snapProviders/MyProfileSnapProvider';
import ErrorFallbackScreen from './ErrorFallbackScreen';

export type Props = {
  fallback: React.ReactElement;
};

export function CheckMyProfileSnapLayoutMain({ fallback }: Props) {
  const myProfileRef = useMyProfileRef();
  const myProfileHiddenRef = useMemo(() => getProfileHiddenRef(myProfileRef), [myProfileRef]);

  const { data: myProfileSnap } = useFirestoreDoc(myProfileRef);
  const { data: myProfileHiddenSnap } = useFirestoreDoc(myProfileHiddenRef);

  if (!myProfileSnap.exists() || !myProfileHiddenSnap.exists()) {
    return fallback;
  }

  return (
    <MyProfileSnapProvider profileSnap={myProfileSnap}>
      <MyProfileHiddenSnapProvider profileHiddenSnap={myProfileHiddenSnap}>
        <Outlet />
      </MyProfileHiddenSnapProvider>
    </MyProfileSnapProvider>
  );
}

export default function CheckMyProfileSnapLayout(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <CheckMyProfileSnapLayoutMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
