import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { useCallback } from 'react';

import RelationshipStyle from '../../common/RelationshipStyle';
import useShowError from '../../hooks/useShowError';
import RelationshipStyleRadioGroup from './RelationshipStyleRadioGroup';

export interface Props {
  // eslint-disable-next-line react/require-default-props
  helperText?: string;
  label: string;
  name: string;
}

export default function RelationshipStyleFormControl({
  helperText,
  label,
  name,
}: Props) {
  const showError = useShowError();

  const [input, meta, helper] = useField<RelationshipStyle>(name);

  const handleChange = useCallback(
    (value: RelationshipStyle) => {
      helper.setValue(value)
        .catch(showError);
    },
    [helper, showError],
  );

  return (
    <FormControl isInvalid={!!meta.error}>
      <FormLabel>{label}</FormLabel>
      <RelationshipStyleRadioGroup name={name} onChange={handleChange} value={input.value} />

      {helperText ? (
        <FormHelperText>
          {helperText}
        </FormHelperText>
      ) : null}

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}
