import {
  Box,
  BoxProps,
  Center,
  Icon,
  VStack,
} from '@chakra-ui/react';
import { animated, useSprings } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { clamp } from 'lodash';
import { Suspense, useMemo, useRef } from 'react';
import { LuHexagon } from 'react-icons/lu';

import { isProfileComplete, ProfileDoc } from '../../collections/Profiles';
import ErrorFallbackScreen from '../../screens/ErrorFallbackScreen';
import Catch from '../Catch';
import InsetsProvider, { useInsets } from '../InsetsProvider';
import LogoIcon from '../LogoIcon';
import ProfileSnapProvider from '../snapProviders/ProfileSnapProvider';
import StoragePictureCell from '../StoragePictureCell';
import InfoCell from './InfoCell';

export type Props = {
  height: number;
  profileSnap: QueryDocumentSnapshot<ProfileDoc>;
  width: number;
} & BoxProps;

export function ProfileCardMain({
  height,
  profileSnap,
  width,
  ...boxProps
}: Props) {
  const insets = useInsets();

  const ref = useRef<HTMLDivElement>(null);

  const animationIndex = useRef(0);

  if (!profileSnap.exists()) {
    throw new Error('Profile not found');
  }

  const profileDoc = useMemo(() => profileSnap.data(), [profileSnap]);

  if (!isProfileComplete(profileDoc)) {
    throw new Error('Profile is not complete');
  }

  const [pages, api] = useSprings(profileDoc.pictures.length, (i) => ({
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: `rgb(from var(--chakra-colors-white) r g b / ${i === 0 ? 1 : (i === 1 ? 0.5 : 0.25)})`,
    display: 'block',
    y: i * height,
  }));

  const bind = useDrag(({
    active, cancel, direction: [,yDir], movement: [,my],
  }) => {
    if (active && Math.abs(my) > 100) {
      animationIndex.current = clamp(
        animationIndex.current + (yDir > 0 ? -1 : 1),
        0,
        profileDoc.pictures.length - 1,
      );
      cancel();
    }

    api.start((i) => {
      if (i < animationIndex.current - 1 || i > animationIndex.current + 1) {
        return {
          backgroundColor: 'rgb(from var(--chakra-colors-white) r g b / 0.25)',
          display: 'none',
        };
      }

      const y = (i - animationIndex.current) * height + (active ? my : 0);
      const backgroundColor = `rgb(from var(--chakra-colors-white) r g b / ${i !== animationIndex.current ? 0.5 : 1})`;
      return {
        backgroundColor,
        display: 'block',
        y,
      };
    });
  }, {
    axis: 'y',
    preventDefault: true,
    threshold: 10,
  });

  return (
    <ProfileSnapProvider profileSnap={profileSnap}>
      <Box
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...boxProps}
        className="profile"
        h={`${height}px`}
        position="relative"
        w={`${width}px`}
      >
        <VStack
          gap={4}
          position="absolute"
          right={4}
          top={`calc(${insets.top} + var(--chakra-space-4))`}
          w={10}
          zIndex={10}
        >
          {pages.map(({ backgroundColor }, i) => (
            <Box
              as={animated.div}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              style={{ color: backgroundColor }}
              transition="color 0.15s ease-in-out"
            >
              <Icon as={LuHexagon} boxSize={4} display="block" />
            </Box>
          ))}
        </VStack>

        <Box
          h={`${height}px`}
          overflow="hidden"
          position="relative"
          ref={ref}
          w={`${width}px`}
        >
          <InsetsProvider
            bottom={insets.bottom}
            left={insets.left}
            right={insets.right}
            top={`calc(${insets.top} + var(--chakra-space-2) + var(--chakra-space-0-5))`}
          >
            {pages.map(({ display, y }, i) => (
              <StoragePictureCell
                as={animated.div}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...bind()}
                height={height}
                key={profileDoc.pictures[i].imgixUrl}
                picture={profileDoc.pictures[i]}
                position="absolute"
                style={{
                  display: display as unknown as string,
                  touchAction: 'none',
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  /* @ts-ignore */
                  y,
                }}
                width={width}
              />
            ))}
          </InsetsProvider>
        </Box>

        <InfoCell
          left={0}
          position="absolute"
          right={0}
          style={{
            bottom: `calc(${insets.bottom} + var(--chakra-space-2))`,
          }}
          zIndex={20}
        />
      </Box>
    </ProfileSnapProvider>
  );
}

export default function ProfileCard(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <ProfileCardMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
