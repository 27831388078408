import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import { useField } from 'formik';
import mixpanel from 'mixpanel-browser';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LuFileEdit } from 'react-icons/lu';

import { useCitiesCollectionRef } from '../../collections/Cities';
import CityAlgoliaSearchRecord from '../../common/CityAlgoliaSearchRecord';
import useShowError from '../../hooks/useShowError';
import { useAlgoliaSearchClient } from '../AlgoliaSearchClientProvider';
import CityRow from './CityRow';

export interface Props {
  // eslint-disable-next-line react/require-default-props
  aroundLatLngViaIP?: boolean;
  // eslint-disable-next-line react/require-default-props
  helperText?: string;
  label: string;
  name: string;
  placeholder: string;
}

export default function CityFormControl({
  aroundLatLngViaIP = false, helperText, label, name, placeholder,
}: Props) {
  const showError = useShowError();
  const [input, meta, helper] = useField<string>(name);

  const client = useAlgoliaSearchClient();
  const citiesIndex = useMemo(() => client.initIndex('cities'), [client]);

  const [query, setQuery] = useState<string>(input.value);
  const [citiesResult, setCitiesResult] = useState<CityAlgoliaSearchRecord[]>();

  useEffect(
    () => {
      citiesIndex
        .search<CityAlgoliaSearchRecord>(
        query,
        {
          aroundLatLngViaIP,
          hitsPerPage: 5,
        },
      )
        .then(
          ({ hits }) => {
            setCitiesResult(hits);
          },
        )
        .catch(showError);
    },
    [aroundLatLngViaIP, citiesIndex, query, showError],
  );

  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleChange = useCallback(
    (id: string) => {
      helper.setValue(id)
        .catch(showError);
      onClose();
      mixpanel.track('Origin Selected');
    },
    [helper, onClose, showError],
  );

  const citiesCollectionRef = useCitiesCollectionRef();
  const cityRef = useMemo(
    () => (input.value ? doc(citiesCollectionRef, input.value) : undefined),
    [citiesCollectionRef, input.value],
  );

  return (
    <>
      <FormControl isInvalid={!!meta.error}>
        <FormLabel>{label}</FormLabel>

        <VStack alignItems="stretch">
          <Box
            borderRadius="md"
            cursor="pointer"
            onClick={onOpen}
            px={4}
            py={2}
            style={{
              border: '1px solid',
              borderColor: 'inherit',
            }}
          >
            <HStack spacing={4}>
              {cityRef ? (
                <CityRow cityRef={cityRef} />
              ) : (
                <Text flex={1}>
                  {placeholder}
                </Text>
              )}

              <Icon as={LuFileEdit} />
            </HStack>
          </Box>
        </VStack>

        {helperText ? (
          <FormHelperText>
            {helperText}
          </FormHelperText>
        ) : null}

        <FormErrorMessage>
          {meta.error}
        </FormErrorMessage>
      </FormControl>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
        <ModalOverlay
          backdropFilter="saturate(180%) blur(20px)"
          backgroundColor="rgb(from var(--chakra-colors-chakra-body-bg) r g b / 0.5)"
        />

        <ModalContent
          mx={4}
        >
          <ModalHeader>
            Cities
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody
            maxH="calc(100vh - 262px)"
            overflow="auto"
          >
            <VStack alignItems="stretch" spacing={4}>
              <Input
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Paris, France"
                value={query}
              />

              <RadioGroup
                onChange={handleChange}
                value={input.value}
              >
                <VStack alignItems="stretch" spacing={4}>
                  {citiesResult?.map(({ objectID }) => (
                    <Radio
                      key={objectID}
                      value={objectID}
                    >
                      <CityRow cityRef={doc(citiesCollectionRef, objectID)} />
                    </Radio>
                  ))}
                </VStack>
              </RadioGroup>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} variant="ghost">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
