import {
  Button,
  Center,
  Container,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { signOut } from 'firebase/auth';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuLogOut } from 'react-icons/lu';
import { useAuth } from 'reactfire';

import { ProfileVersionDoc } from '../../collections/Profiles';
import { useInsets } from '../../components/InsetsProvider';
import LogoFull from '../../components/LogoFull';
import useShowError from '../../hooks/useShowError';

export type Props = {
  lastProfileVersionSnap: QueryDocumentSnapshot<ProfileVersionDoc>;
  onReapplyClick: () => void;
};

export default function ProfileVersionRejected({ lastProfileVersionSnap, onReapplyClick }: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileVersionRejected' });

  const lastProfileVersionDoc = useMemo(
    () => lastProfileVersionSnap.data(),
    [lastProfileVersionSnap],
  );

  const showError = useShowError();

  const insets = useInsets();

  const auth = useAuth();

  const handleLogOut = useCallback(
    () => {
      signOut(auth)
        .then(() => mixpanel.reset())
        .catch(showError);
    },
    [auth, showError],
  );

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        flexShrink={0}
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-4))`}
      >
        <Center>
          <LogoFull h={16} w={48} />
        </Center>
      </Container>

      <Container
        flex={1}
        maxW="lg"
        minH={0}
        overflow="auto"
        py={2}
      >
        <VStack
          alignItems="stretch"
          gap={4}
          h="100%"
          justifyContent="center"
        >
          <Text fontSize="xl" fontWeight="bold" textAlign="center">
            {t('text1', { name: lastProfileVersionDoc.name })}
          </Text>

          <Text textAlign="center">
            {t('text2')}
          </Text>

          <Text fontSize="sm" textAlign="center">
            {t('text3')}
          </Text>
        </VStack>
      </Container>

      <Container
        maxW="lg"
        pb={`max(${insets.bottom}, var(--chakra-space-2))`}
        pt={2}
      >
        <HStack gap={2}>
          <IconButton
            aria-label={t('logoutButton.loading')}
            colorScheme="red"
            icon={<Icon as={LuLogOut} />}
            onClick={handleLogOut}
          />

          <Button
            flex={1}
            onClick={onReapplyClick}
          >
            {t('reapplyButton.default')}
          </Button>
        </HStack>
      </Container>
    </VStack>
  );
}
