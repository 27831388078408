import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import MyVenturesScreen from '../MyVenturesScreen';
import TheirVenturesScreen from '../TheirVenturesScreen';

export default function VenturesScreen() {
  const myProfileSnap = useMyProfileSnap();

  if (myProfileSnap.ref.id !== '1Cnp6jjyn5Z6aHdH2ZlnFmJZrWv1') {
    return (
      <MyVenturesScreen />
    );
  }

  return (
    <TheirVenturesScreen />
  );
}
