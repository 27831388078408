import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import AppLanguage from '../common/AppLanguage';
import { UnsplashPicture } from '../common/UnsplashPicture';
import { type ContinentDoc } from './Continent';
import { type CountryDoc } from './Countries';
import { type CurrencyDoc } from './Currency';

export interface DestinationDoc {
  _v: 1;
  activities?: string[];
  continentName?: string;
  continentRef?: DocumentReference<ContinentDoc>;
  countryEmoji?: string;
  countryName: Record<AppLanguage, string>;
  countryRef: DocumentReference<CountryDoc>;
  createdAt: Timestamp;
  currencyName?: string;
  currencyRef?: DocumentReference<CurrencyDoc>;
  description: Record<AppLanguage, string>;
  labels: string[];
  name: Record<AppLanguage, string>;
  picture: UnsplashPicture;
  updatedAt: Timestamp;
}

export const isDestinationDoc = (
  data?: DocumentData,
): data is DestinationDoc => true;

export const isDestinationRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<DestinationDoc> => ref.parent.id === 'destinations';

function isDestinationSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<DestinationDoc>;
function isDestinationSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<DestinationDoc>;
function isDestinationSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<DestinationDoc> | QueryDocumentSnapshot<DestinationDoc> {
  return isDestinationRef(snap.ref);
}

export { isDestinationSnap };

export const useDestinationsCollectionRef = () => {
  const firestore = useFirestore();
  return collection(firestore, 'destinations') as CollectionReference<DestinationDoc>;
};
