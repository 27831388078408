import { Center, Text, VStack } from '@chakra-ui/react';
import { orderBy, query, where } from 'firebase/firestore';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestoreCollection } from 'reactfire';

import {
  ConversationStatus,
  useConversationsCollectionRef,
} from '../../collections/Conversations';
import Catch from '../../components/Catch';
import ConversationSnapProvider from '../../components/snapProviders/ConversationSnapProvider';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Conversation, { ConversationLoading } from './Conversation';

export function ConversationListMain() {
  const { t } = useTranslation('ChatsScreen', { keyPrefix: 'ConversationList' });

  const myProfileSnap = useMyProfileSnap();

  const conversationsCollectionRef = useConversationsCollectionRef();
  const { data: conversationsSnap } = useFirestoreCollection(
    query(
      conversationsCollectionRef,
      where('participantRefs', 'array-contains', myProfileSnap.ref),
      where('status', '==', ConversationStatus.OPENED),
      orderBy('lastActionAt', 'desc'),
    ),
  );

  if (!conversationsSnap.docs.length) {
    return (
      <Center height="100%">
        <Text textAlign="center">
          {t('emptyList.body')}
        </Text>
      </Center>
    );
  }

  return (
    <VStack alignItems="stretch" spacing={4}>
      {conversationsSnap.docs.map((conversationSnap) => (
        <ConversationSnapProvider conversationSnap={conversationSnap} key={conversationSnap.id}>
          <Conversation />
        </ConversationSnapProvider>
      ))}
    </VStack>
  );
}

export function ConversationListLoading() {
  return (
    <VStack alignItems="stretch" spacing={4}>
      <ConversationLoading />
      <ConversationLoading />
      <ConversationLoading />
    </VStack>
  );
}

export default function ConversationList() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<ConversationListLoading />}>
        <ConversationListMain />
      </Suspense>
    </Catch>
  );
}
