import {
  Center,
  Container,
  Grid,
  Portal,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  doc,
  limit,
  orderBy,
  query,
  setDoc,
  Timestamp,
  where,
} from 'firebase/firestore';
import { defaults, slice } from 'lodash';
import moment from 'moment';
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { List, ListRowProps } from 'react-virtualized';
import { useFirestoreCollection } from 'reactfire';

import { ApplicationStatus, useApplicationsCollectionRef } from '../../collections/Applications';
import { useProfilesCollectionRef } from '../../collections/Profiles';
import { TripStatus, useTripsCollectionRef } from '../../collections/Trips';
import Gender from '../../common/Gender';
import TripAlgoliaSearchRecord from '../../common/TripAlgoliaSearchRecord';
import { useAlgoliaSearchClient } from '../../components/AlgoliaSearchClientProvider';
import Catch from '../../components/Catch';
import LogoIcon from '../../components/LogoIcon';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import useShowError from '../../hooks/useShowError';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import TripRow, { TripRowLoading } from './TripRow';
import TripWrapper from './TripWrapper';

export type Props = {
  height: number;
  width: number;
};

export function TripGridMain({ height, width }: Props) {
  const { t } = useTranslation('TheirTripsScreen', { keyPrefix: 'TripList' });
  const showError = useShowError();

  const { tripId } = useParams<{ tripId?: string }>();

  const client = useAlgoliaSearchClient();
  const tripsIndex = useMemo(() => client.initIndex('trips'), [client]);

  const myProfileSnap = useMyProfileSnap();
  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  const preferences = useMemo(
    () => defaults(
      {},
      myProfileDoc?.preferences ?? {},
      {
        age: {
          max: 100,
          min: 18,
        },
        height: {
          max: 300,
          min: 100,
        },
        weight: {
          max: 300,
          min: 30,
        },
      },
    ),
    [myProfileDoc?.preferences],
  );

  const filters = useMemo(
    () => {
      const nextFilters: (string | string[])[] = [];
      nextFilters.push(`status: ${TripStatus.PUBLISHED}`);
      nextFilters.push(`organizer.gender: ${Gender.MALE}`);

      return nextFilters.join(' AND ');
    },
    [],
  );

  const numericFilters = useMemo(
    () => {
      const nextOptionalFilters: (string | string[])[] = [];

      nextOptionalFilters.push(`organizer.age: ${preferences.age.min} TO ${preferences.age.max}`);
      nextOptionalFilters.push(`organizer.height: ${preferences.height.min} TO ${preferences.height.max}`);
      nextOptionalFilters.push(`organizer.weight: ${preferences.weight.min} TO ${preferences.weight.max}`);

      return nextOptionalFilters.join(', ');
    },
    [
      preferences.age.max,
      preferences.age.min,
      preferences.height.max,
      preferences.height.min,
      preferences.weight.max,
      preferences.weight.min,
    ],
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [trips, setTrips] = useState<TripAlgoliaSearchRecord[]>([]);

  const applicationsCollectionRef = useApplicationsCollectionRef();
  const { data: applicationsSnap } = useFirestoreCollection(
    query(
      applicationsCollectionRef,
      where('applicantRef', '==', myProfileSnap.ref),
      orderBy('sentAt', 'desc'),
      limit(1000),
    ),
  );

  useEffect(
    () => {
      setIsLoading(true);
      tripsIndex.search<TripAlgoliaSearchRecord>('', {
        clickAnalytics: true,
        // enablePersonalization: true,
        filters,
        length: 1000,
        numericFilters,
        offset: 0,
        userToken: myProfileSnap.ref.id,
      }).finally(() => {
        setIsLoading(false);
      }).then((response) => {
        setTrips(response.hits);
      }).catch(showError);
    },
    [filters, numericFilters, myProfileSnap.ref.id, showError, tripsIndex],
  );

  const { height: wHeight, width: wWidth } = useWindowDimensions();

  const [openTrips, setOpenTrips] = useState<TripAlgoliaSearchRecord[]>([]);

  useEffect(
    () => {
      if (!tripId) {
        return;
      }

      tripsIndex
        .getObject<TripAlgoliaSearchRecord>(tripId)
        .finally(() => {
          setIsLoading(false);
        }).then((response) => {
          setOpenTrips([response]);
        }).catch(showError);
    },
    [showError, tripId, tripsIndex],
  );

  const [startIndex, setStartIndex] = useState<number>(0);

  const handleClick = useCallback(
    (index: number) => {
      setStartIndex(index);
      setOpenTrips(slice(trips, index, Math.min(trips.length, index + 5)));
    },
    [trips],
  );

  const handleExit = useCallback(
    () => {
      setStartIndex(0);
      setOpenTrips([]);
    },
    [],
  );

  const handleSwipeLeft = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async () => {
      setStartIndex(startIndex + 1);
      setOpenTrips(slice(trips, startIndex + 1, Math.min(trips.length, startIndex + 6)));
    },
    [startIndex, trips],
  );

  const profilesCollectionRef = useProfilesCollectionRef();
  const tripsCollectionRef = useTripsCollectionRef();

  const handleApply = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (tripRecord: TripAlgoliaSearchRecord) => {
      const applicationRef = doc(applicationsCollectionRef);

      await setDoc(
        applicationRef,
        {
          _v: 1,
          applicantRef: myProfileSnap.ref,
          expiresAt: Timestamp.fromDate(moment.utc().add(1, 'year').toDate()),
          organizerRef: doc(profilesCollectionRef, tripRecord.organizer.id),
          sentAt: Timestamp.now(),
          status: ApplicationStatus.SENT,
          tripRef: doc(tripsCollectionRef, tripRecord.objectID),
        },
      );
    },
    [
      applicationsCollectionRef,
      myProfileSnap.ref,
      profilesCollectionRef,
      tripsCollectionRef,
    ],
  );

  const handleSwipeRight = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (tripRecord: TripAlgoliaSearchRecord) => {
      await handleApply(tripRecord);

      setStartIndex(startIndex + 1);
      setOpenTrips(slice(trips, startIndex + 1, Math.min(trips.length, startIndex + 6)));
    },
    [handleApply, startIndex, trips],
  );

  const rowRenderer = useCallback(
    ({ index, style }: ListRowProps) => (
      <TripRow
        // eslint-disable-next-line max-len
        applicationStatus={applicationsSnap.docs.find((s) => s.data().tripRef?.id === trips[index].objectID)?.data().status}
        key={trips[index].objectID}
        onClick={() => handleClick(index)}
        onSwipeRight={() => { handleApply(trips[index]).catch(() => { }); }}
        style={style}
        tripRecord={trips[index]}
      />
    ),
    [applicationsSnap.docs, handleApply, handleClick, trips],
  );

  if (trips.length) {
    return (
      <>
        <List
          height={height}
          overscanRowCount={3}
          rowCount={trips.length}
          rowHeight={200}
          // eslint-disable-next-line react/no-unstable-nested-components
          rowRenderer={rowRenderer}
          width={width}
        />

        <Portal>
          {openTrips.map((tripRecord, i) => (
            <TripWrapper
              // eslint-disable-next-line max-len
              applicationStatus={applicationsSnap.docs.find((s) => s.data().tripRef?.id === tripRecord.objectID)?.data().status}
              height={wHeight}
              index={i}
              key={tripRecord.objectID}
              onExit={handleExit}
              onSwipeLeft={handleSwipeLeft}
              onSwipeRight={handleSwipeRight}
              queryId=""
              searchResultPosition={0}
              top={0}
              tripRecord={tripRecord}
              width={wWidth}
              zIndex={(openTrips.length - i) * 10 + 100}
            />
          ))}
        </Portal>
      </>
    );
  }

  if (isLoading) {
    return (
      <Container height="100%" maxW="lg">
        <Center height="100%">
          <LogoIcon boxSize={16} />
        </Center>
      </Container>
    );
  }

  return (
    <Container height="100%" maxW="lg">
      <Center height="100%">
        <Text textAlign="center">
          {t('emptyList.body')}
        </Text>
      </Center>
    </Container>
  );
}

export function TripGridLoading() {
  return (
    <VStack alignItems="stretch" className="myTripsList" gap={8}>
      <VStack alignItems="stretch" gap={2}>
        <Skeleton h={5} />

        <Grid gap={4} gridAutoRows="1fr" templateColumns="repeat(2, 1fr)">
          <TripRowLoading />
          <TripRowLoading />
          <TripRowLoading />
          <TripRowLoading />
        </Grid>
      </VStack>
    </VStack>
  );
}

export default function TripGrid(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<TripGridLoading />}>
        <TripGridMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
