import {
  Center,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import { Suspense, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LuChevronLeft } from 'react-icons/lu';
import { Link, useNavigate } from 'react-router-dom';

import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import LogoIcon from '../../components/LogoIcon';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import VenueForm from './VenueForm';

export function VenueCreateScreenMain() {
  useEffect(() => { mixpanel.track_pageview({ screen: 'VenueCreateScreen' }); }, []);

  const insets = useInsets();

  const { t } = useTranslation('VenueCreateScreen');

  const navigate = useNavigate();
  const onComplete = useCallback(
    () => {
      navigate('/venues');
    },
    [navigate],
  );

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack gap={4}>
          <IconButton
            aria-label={t('backButton.default')}
            as={Link}
            icon={<Icon as={LuChevronLeft} />}
            to=".."
          />

          <Heading
            flex={1}
            fontSize="4xl"
            lineHeight="40px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {t('title')}
          </Heading>
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        maxW="lg"
        overflowY="auto"
        style={{
          paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
        }}
      >
        <VenueForm
          onComplete={onComplete}
        />
      </Container>
    </VStack>
  );
}

export default function VenueCreateScreen() {
  const { t } = useTranslation('VenueCreateScreen');

  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <Helmet>
          <title>{t('title')}</title>
        </Helmet>
        <VenueCreateScreenMain />
      </Suspense>
    </Catch>
  );
}
