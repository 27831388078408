import {
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  VStack,
} from '@chakra-ui/react';
import {
  arrayRemove,
  arrayUnion,
  doc,
  refEqual,
  setDoc,
  Timestamp,
  writeBatch,
} from 'firebase/firestore';
import _ from 'lodash';
import {
  ChangeEvent,
  FormEvent,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LuSendHorizonal } from 'react-icons/lu';
import { useFirestore } from 'reactfire';

import { MessageType, useMessagesCollectionRef } from '../../collections/Messages';
import Catch from '../../components/Catch';
import { useConversationSnap } from '../../components/snapProviders/ConversationSnapProvider';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import useShowError from '../../hooks/useShowError';

export function MessageInputMain() {
  const conversationSnap = useConversationSnap();
  const messagesCollectionRef = useMessagesCollectionRef();
  const myProfileSnap = useMyProfileSnap();

  const [value, setValue] = useState<string>('');

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    [],
  );

  const handleFocus = useCallback(
    () => {
      setDoc(
        conversationSnap.ref,
        { typingParticipantRefs: arrayUnion(myProfileSnap.ref) },
        { merge: true },
      ).catch(() => { });
    },
    [conversationSnap.ref, myProfileSnap.ref],
  );

  const handleBlur = useCallback(
    () => {
      setDoc(
        conversationSnap.ref,
        { typingParticipantRefs: arrayRemove(myProfileSnap.ref) },
        { merge: true },
      ).catch(() => { });
    },
    [conversationSnap.ref, myProfileSnap.ref],
  );

  useEffect(
    () => {
      const handler = () => {
        setDoc(
          conversationSnap.ref,
          { typingParticipantRefs: arrayRemove(myProfileSnap.ref) },
          { merge: true },
        ).catch(() => { });
      };

      window.addEventListener('blur', handler);

      return () => {
        window.removeEventListener('blur', handler);
      };
    },
    [conversationSnap.ref, myProfileSnap.ref],
  );

  const showError = useShowError();

  const [isSending, setSending] = useState<boolean>(false);

  const conversationDoc = useMemo(() => conversationSnap.data(), [conversationSnap]);

  const firestore = useFirestore();

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLDivElement>) => {
      e.preventDefault();
      setSending(true);

      const batch = writeBatch(firestore);

      batch.set(
        doc(messagesCollectionRef),
        {
          _v: 1,
          conversationRef: conversationSnap.ref,
          createdAt: Timestamp.now(),
          notReadByRefs: _.filter(
            conversationDoc.participantRefs,
            (ref) => !refEqual(ref, myProfileSnap.ref),
          ),
          readByRefs: [],
          senderRef: myProfileSnap.ref,
          text: value,
          type: MessageType.TEXT,
          updatedAt: Timestamp.now(),
        },
      );

      batch.set(
        conversationSnap.ref,
        {
          lastActionAt: Timestamp.now(),
          notReadByRefs: arrayUnion(..._.filter(
            conversationDoc.participantRefs,
            (ref) => !refEqual(ref, myProfileSnap.ref),
          )),
        },
        { merge: true },
      );

      batch
        .commit()
        .finally(() => setSending(false))
        .catch(showError);

      setValue('');
    },
    [
      conversationDoc.participantRefs,
      conversationSnap.ref,
      firestore,
      messagesCollectionRef,
      myProfileSnap.ref,
      showError,
      value,
    ],
  );

  return (
    <VStack as="form" onSubmit={handleSubmit}>
      <InputGroup size="lg">
        <Input
          enterKeyHint="send"
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          pr="3rem"
          value={value}
        />

        <InputRightElement width="3rem">
          <IconButton
            aria-label="Send"
            h="2.5rem"
            icon={<Icon as={LuSendHorizonal} />}
            isLoading={isSending}
            size="sm"
            type="submit"
            variant="ghost"
            w="2.5rem"
          />
        </InputRightElement>
      </InputGroup>
    </VStack>
  );
}

export function MessageInputLoading() {
  return (
    <Skeleton h={10} />
  );
}

export default function MessageInput() {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <MessageInputMain />
      </Suspense>
    </Catch>
  );
}
