import {
  Badge,
  Box,
  BoxProps,
  Container,
  HStack,
  Icon,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { LuLanguages, LuSparkles } from 'react-icons/lu';
import { PiGenderIntersexBold } from 'react-icons/pi';

import { useProfilesCollectionRef } from '../../../collections/Profiles';
import AppLanguage from '../../../common/AppLanguage';
import Gender from '../../../common/Gender';
import ReservationAlgoliaSearchRecord from '../../../common/ReservationAlgoliaSearchRecord';
import Sexuality from '../../../common/Sexuality';
import TierIcon from '../../../components/TierIcon';
import OrganizerAvatar from './OrganizerAvatar';
import WealthBadge from './WealthBadge';

export type Props = {
  queryId: string | undefined;
  reservationRecord: ReservationAlgoliaSearchRecord;
  searchResultPosition: number;
} & BoxProps;

export default function InfoCell({
  queryId,
  reservationRecord,
  searchResultPosition,
  ...boxProps
}: Props) {
  const { i18n, t } = useTranslation('TheirReservationsScreen', { keyPrefix: 'Reservation.InfoCell' });
  const { t: lt } = useTranslation('Languages');
  const { t: st } = useTranslation('Sexuality');
  const { t: zt } = useTranslation('Zodiac');

  const profilesCollectionRef = useProfilesCollectionRef();

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      className="reservationInfo"
    >
      <Container
        h="100%"
        maxW="lg"
        pb="calc(max(env(safe-area-inset-bottom), var(--chakra-space-4)) + var(--chakra-space-12) + var(--chakra-space-4))"
        pr={12}
        pt="calc(max(env(safe-area-inset-top), var(--chakra-space-4)) + var(--chakra-space-12) + var(--chakra-space-4))"
      >
        <VStack
          alignItems="stretch"
          gap={4}
          h="100%"
        >
          <Wrap>
            <Badge>
              {reservationRecord.venue.name}
            </Badge>

            <Badge>
              {reservationRecord.venue.city?.name}
            </Badge>
          </Wrap>

          <Wrap>
            {
              reservationRecord.organizer.sexuality === Sexuality.BISEXUAL ? (
                <Badge alignItems="center" colorScheme="purple" display="flex" gap={1}>
                  <Icon as={PiGenderIntersexBold} size={5} />
                  {st(reservationRecord.organizer.sexuality)}
                </Badge>
              ) : null
            }

            {reservationRecord.organizer.languages.map((l) => (
              <Badge alignItems="center" display="flex" gap={1} key={l}>
                <Icon as={LuLanguages} size={5} />
                {lt(l)}
              </Badge>
            ))}

            <Badge>
              {reservationRecord.organizer.height}
              cm
            </Badge>

            <Badge>
              {reservationRecord.organizer.weight}
              kg
            </Badge>

            <Badge>
              {reservationRecord.organizer.age}
              y
            </Badge>

            {/* eslint-disable-next-line max-len */}
            {reservationRecord.organizer.gender === Gender.MALE && reservationRecord.organizer.wealth ? (
              <WealthBadge wealth={reservationRecord.organizer.wealth} />
            ) : null}

            <Badge alignItems="center" display="flex" gap={1}>
              <Icon as={LuSparkles} size={5} />
              {zt(reservationRecord.organizer.zodiac)}
            </Badge>
          </Wrap>

          <Text
            flex={1}
            fontSize="sm"
            minH={0}
            overflow="hidden"
          >
            {/* eslint-disable-next-line max-len */}
            {reservationRecord.translations?.description[i18n.language as AppLanguage] ?? reservationRecord.description}
          </Text>

          <HStack gap={4}>
            <OrganizerAvatar
              organizerRef={doc(profilesCollectionRef, reservationRecord.organizer.id)}
            />

            <VStack alignItems="stretch" flex={1} gap={1} minW={0}>
              <HStack>
                <TierIcon boxSize={4} tier={reservationRecord.venue.tier} />

                <Text
                  flexShrink={1}
                  fontSize="md"
                  lineHeight={1}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {/* eslint-disable-next-line max-len */}
                  {reservationRecord.organizer.translations?.name[i18n.language as AppLanguage] ?? reservationRecord.organizer.name}
                  {', '}
                  {reservationRecord.organizer.age}
                </Text>
              </HStack>

              <Text fontSize="xs" lineHeight={1} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                { _.compact([
                  reservationRecord.venue.name,
                  reservationRecord.venue.city.name,
                ]).join(', ') }
              </Text>

              <Text fontSize="xs" lineHeight={1} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                {t('startsAt', {
                  formatParams: { startsAt: { dateStyle: 'short', timeStyle: 'short' } },
                  startsAt: moment(reservationRecord.startsAt * 1000).toDate(),
                })}
              </Text>
            </VStack>
          </HStack>
        </VStack>
      </Container>
    </Box>
  );
}
