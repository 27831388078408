import {
  AspectRatio,
  Badge,
  Box,
  Button,
  Grid,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import {
  doc,
  DocumentReference,
  QueryDocumentSnapshot,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import { Formik } from 'formik';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaInstagram } from 'react-icons/fa';
import { TbMichelinBibGourmand, TbMichelinStar } from 'react-icons/tb';
import * as yup from 'yup';

import {
  useVenturesCollectionRef,
  VentureDoc,
  VentureLabel,
  VentureStatus,
} from '../../collections/Ventures';
import { VenueDistinction, VenueDoc } from '../../collections/Venues';
import { getPhotoSizeUrl } from '../../common/getPhotoSizeUrl';
import CheckboxFormControl from '../../components/CheckboxFormControl';
import InstagramFormControl from '../../components/InstagramFormControl';
import NumberFormControl from '../../components/NumberFormControl';
import PicturesFormControl from '../../components/PicturesFormControl';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import TextareaFormControl from '../../components/TextareaFormControl';
import TextFormControl from '../../components/TextFormControl';

export type Props = {
  onComplete: (ventureRef: DocumentReference<VentureDoc>) => void;
  venueSnap: QueryDocumentSnapshot<VenueDoc>;
};

export default function VentureForm({ onComplete, venueSnap }: Props) {
  const { t } = useTranslation('VentureCreateScreen', { keyPrefix: 'VentureForm' });

  const venueDoc = useMemo(() => venueSnap.data(), [venueSnap]);

  const schema = useMemo(
    () => yup.object().shape({
      description: yup
        .string()
        .label(t('description.label'))
        .required(),
      femaleParticipantPlaces: yup
        .number()
        .min(0)
        .max(100)
        .label(t('femaleParticipantPlaces.label'))
        .required(),
      instagramTag: yup
        .string()
        .matches(/^[a-zA-Z0-9._]+$/, t('instagramTag.matchesError'))
        .label(t('instagramTag.label'))
        .required(),
      labels: yup
        .array()
        .label(t('labels.label'))
        .min(1)
        .max(20)
        .of(
          yup
            .string()
            .label(t('labels.item.label'))
            .oneOf(Object.values(VentureLabel))
            .required(),
        )
        .required(),
      maleParticipantPlaces: yup
        .number()
        .min(0)
        .max(100)
        .label(t('maleParticipantPlaces.label'))
        .required(),
      maleParticipantPriceAmount: yup
        .number()
        .min(10)
        .max(1000)
        .label(t('maleParticipantPrice.amount.label'))
        .required(),
      name: yup
        .string()
        .label(t('description.label'))
        .required(),
      pictures: yup
        .array()
        .label(t('pictures.label'))
        .compact()
        .min(1)
        .max(6)
        .of(
          yup
            .object()
            .label(t('pictures.item.label'))
            .shape({
              blurHash: yup.string(),
              imgixUrl: yup.string().required(),
              storageRef: yup.string().required(),
            })
            .required(),
        )
        .required(),
      startsAt: yup
        .string()
        .label(t('startsAt.label'))
        .required(),
    }),
    [t],
  );

  const myProfileSnap = useMyProfileSnap();

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      description: '',
      femaleParticipantPlaces: 6,
      instagramTag: '',
      labels: [],
      maleParticipantPlaces: 3,
      maleParticipantPriceAmount: 100,
      name: '',
      pictures: [],
      startsAt: moment
        .utc()
        .add(7, 'days')
        .set({
          hour: 19,
          millisecond: 0,
          minute: 0,
          second: 0,
        })
        .format('YYYY-MM-DDTHH:mm:ss'),
    }),
    [],
  );

  const venturesCollectionRef = useVenturesCollectionRef();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      const ventureRef = doc(venturesCollectionRef);

      await setDoc(
        ventureRef,
        {
          _v: 1,
          createdAt: Timestamp.now(),
          description: values.description,
          femaleParticipantPlaces: values.femaleParticipantPlaces,
          femaleParticipants: [],
          labels: values.labels,
          maleParticipantPlaces: values.maleParticipantPlaces,
          maleParticipantPrice: {
            amount: values.maleParticipantPriceAmount,
            currency: 'EUR',
          },
          maleParticipants: [],
          name: values.name,
          organizerRef: myProfileSnap.ref,
          pictures: values.pictures,
          publishedAt: Timestamp.now(),
          startsAt: Timestamp.fromDate(new Date(values.startsAt)),
          status: VentureStatus.PUBLISHED,
          updatedAt: Timestamp.now(),
          venueRef: venueSnap.ref,
        },
      );

      mixpanel.track('Venture Created', {
        createdAt: new Date(),
        description: values.description,
        femaleParticipantPlaces: values.femaleParticipantPlaces,
        femaleParticipants: [],
        labels: values.labels,
        maleParticipantPlaces: values.maleParticipantPlaces,
        maleParticipantPrice: {
          amount: values.maleParticipantPriceAmount,
          currency: 'EUR',
        },
        maleParticipants: [],
        name: values.name,
        organizerRef: myProfileSnap.ref,
        pictures: values.pictures,
        publishedAt: new Date(),
        startsAt: new Date(values.startsAt),
        status: VentureStatus.PUBLISHED,
        updatedAt: new Date(),
        venueId: venueSnap.id,
      });

      onComplete(ventureRef);
    },
    [
      venueSnap,
      venturesCollectionRef,
      myProfileSnap.ref,
      onComplete,
    ],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        isValidating,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          gap={4}
          h="100%"
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
        >
          <VStack alignItems="stretch" flex={1} gap={4} overflow="auto">
            <Grid autoRows="1fr" gap={2} templateColumns="repeat(3, 1fr)">
              {venueDoc.pictures.map((picture) => (
                <AspectRatio key={picture.imgixUrl} ratio={9 / 16}>
                  <Image
                    borderRadius="md"
                    objectFit="cover"
                    src={getPhotoSizeUrl({
                      height: 320,
                      uri: picture.imgixUrl,
                      width: 180,
                    })}
                  />
                </AspectRatio>
              ))}
            </Grid>

            <Text>
              {venueDoc.description.en}
            </Text>

            <HStack gap={1}>
              {venueDoc.distinctions.includes(VenueDistinction.MICHELIN_BIB_GOURMAND) && (
                <Icon as={TbMichelinBibGourmand} boxSize={7} />
              )}

              {venueDoc.distinctions.includes(VenueDistinction.MICHELIN_ONE_STAR) && (
                <Icon as={TbMichelinStar} boxSize={7} />
              )}

              {venueDoc.distinctions.includes(VenueDistinction.MICHELIN_TWO_STAR) && (
                <>
                  <Icon as={TbMichelinStar} boxSize={7} />
                  <Icon as={TbMichelinStar} boxSize={7} />
                </>
              )}

              {venueDoc.distinctions.includes(VenueDistinction.MICHELIN_THREE_STAR) && (
                <>
                  <Icon as={TbMichelinStar} boxSize={7} />
                  <Icon as={TbMichelinStar} boxSize={7} />
                  <Icon as={TbMichelinStar} boxSize={7} />
                </>
              )}
            </HStack>

            <Wrap>
              {venueDoc.labels.map((label) => (
                <Badge key={label}>
                  {label}
                </Badge>
              ))}
            </Wrap>

            {venueDoc.instagramTag ? (
              <Box>
                <Button
                  as="a"
                  href={`https://instagram.com/${venueDoc.instagramTag}`}
                  leftIcon={<Icon as={FaInstagram} />}
                  target="_blank"
                  variant="link"
                >
                  {venueDoc.instagramTag}
                </Button>
              </Box>
            ) : null}

            <PicturesFormControl
              label={t('pictures.label')}
              name="pictures"
            />

            <TextFormControl
              label={t('name.label')}
              name="name"
            />

            <TextareaFormControl
              label={t('description.label')}
              name="description"
              placeholder={t('description.placeholder')}
              rows={10}
            />

            <InstagramFormControl
              label={t('instagramTag.label')}
              name="instagramTag"
              type="text"
            />

            <CheckboxFormControl
              label={t('labels.label')}
              name="labels"
              options={{
                /* eslint-disable perfectionist/sort-objects */
                [VentureLabel.NIGHTCLUB]: t(`labels.option.${VentureLabel.NIGHTCLUB}`),
                /* eslint-enable perfectionist/sort-objects */
              }}
            />

            <TextFormControl
              label={t('startsAt.label')}
              name="startsAt"
              type="datetime-local"
            />

            <NumberFormControl
              label={t('femaleParticipantPlaces.label')}
              max={100}
              min={0}
              name="femaleParticipantPlaces"
              step={1}
            />

            <NumberFormControl
              label={t('maleParticipantPlaces.label')}
              max={100}
              min={0}
              name="maleParticipantPlaces"
              step={1}
            />

            <NumberFormControl
              label={t('maleParticipantPriceAmount.label')}
              max={1000}
              min={0}
              name="maleParticipantPriceAmount"
              step={100}
            />
          </VStack>

          <Button
            colorScheme={isValid ? undefined : 'red'}
            isLoading={isValidating || isSubmitting}
            loadingText={t('createButton.loading')}
            onClick={() => handleSubmit()}
          >
            {t('createButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
