import {
  Center,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import { doc, DocumentReference } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LuChevronLeft } from 'react-icons/lu';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import { useDestinationsCollectionRef } from '../../collections/Destinations';
import { TripDoc } from '../../collections/Trips';
import AppLanguage from '../../common/AppLanguage';
import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import LogoIcon from '../../components/LogoIcon';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import TripForm from './TripForm';

export function TripCreateScreenMain() {
  useEffect(() => { mixpanel.track_pageview({ screen: 'TripCreateScreen' }); }, []);

  const insets = useInsets();

  const { i18n, t } = useTranslation('TripCreateScreen');

  const { destinationId } = useParams<{ destinationId: string }>();

  const destinationsCollectionRef = useDestinationsCollectionRef();
  const destinationRef = useMemo(
    () => doc(destinationsCollectionRef, destinationId),
    [destinationId, destinationsCollectionRef],
  );

  const { data: destinationSnap } = useFirestoreDoc(destinationRef);

  if (!destinationSnap.exists()) {
    throw new Error('Destination does not exist');
  }

  const destinationDoc = useMemo(() => destinationSnap.data(), [destinationSnap]);

  const navigate = useNavigate();
  const onComplete = useCallback(
    (tripRef: DocumentReference<TripDoc>) => {
      navigate(`/trips/${tripRef.id}/payment`);
    },
    [navigate],
  );

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack gap={4}>
          <IconButton
            aria-label={t('backButton.default')}
            as={Link}
            icon={<Icon as={LuChevronLeft} />}
            to=".."
          />

          <Heading
            flex={1}
            fontSize="4xl"
            lineHeight="40px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {destinationDoc.name[i18n.language as AppLanguage]}
          </Heading>
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        maxW="lg"
        overflowY="auto"
        style={{
          paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
        }}
      >
        <TripForm
          destinationSnap={destinationSnap}
          onComplete={onComplete}
        />
      </Container>
    </VStack>
  );
}

export default function TripCreateScreen() {
  const { t } = useTranslation('TripCreateScreen');

  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <Helmet>
          <title>{t('title')}</title>
        </Helmet>
        <TripCreateScreenMain />
      </Suspense>
    </Catch>
  );
}
