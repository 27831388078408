/* eslint-disable perfectionist/sort-enums */
enum Wealth {
  BROKE = 0,
  AVERAGE = 1,
  RICH = 2,
  AFFLUENT = 3,
  WEALTHY = 4,
  MILLIONAIRE = 5,
}

export default Wealth;
