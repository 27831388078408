import {
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  Skeleton,
  VStack,
} from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LuCalendarPlus } from 'react-icons/lu';
import { Link } from 'react-router-dom';

import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import VentureList, { VentureListLoading } from './VentureList';

export function MyVenturesScreenMain() {
  useEffect(() => { mixpanel.track_pageview({ screen: 'MyVenturesScreen' }); }, []);

  const insets = useInsets();

  const { t } = useTranslation('MyVenturesScreen');

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <VStack alignItems="stretch" gap={0} h="100%">
        <Container
          maxW="lg"
          pb={2}
          pt={`max(${insets.top}, var(--chakra-space-2))`}
        >
          <HStack gap={4}>
            <Heading
              flex={1}
              fontSize="4xl"
              lineHeight="40px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {t('title')}
            </Heading>

            <IconButton
              aria-label={t('addVentureButton.default')}
              as={Link}
              className="createVentureButton"
              icon={<Icon as={LuCalendarPlus} />}
              to="add"
            />
          </HStack>
        </Container>

        <Container
          flex={1}
          height="100%"
          maxW="lg"
          overflowY="auto"
          style={{
            paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
          }}
        >
          <VentureList />
        </Container>
      </VStack>
    </>
  );
}

export function MyVenturesScreenLoading() {
  const insets = useInsets();

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack gap={4}>
          <Skeleton flex={1} height={10} />
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        maxW="lg"
        overflowY="auto"
        style={{
          paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
        }}
      >
        <VentureListLoading />
      </Container>
    </VStack>
  );
}

export default function MyVenturesScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<MyVenturesScreenLoading />}>
        <MyVenturesScreenMain />
      </Suspense>
    </Catch>
  );
}
