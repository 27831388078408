import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AvatarGroup,
  Button,
  Heading,
  HStack,
  Icon,
  IconButton,
  Skeleton,
  SkeletonCircle,
  useDisclosure,
} from '@chakra-ui/react';
import { serverTimestamp, setDoc } from 'firebase/firestore';
import _ from 'lodash';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuChevronLeft, LuHeartOff } from 'react-icons/lu';
import { Link, useNavigate } from 'react-router-dom';

import { ConversationClosedBy, ConversationStatus } from '../../collections/Conversations';
import Catch from '../../components/Catch';
import { useConversationSnap } from '../../components/snapProviders/ConversationSnapProvider';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import useShowError from '../../hooks/useShowError';
import ParticipantAvatar from './ParticipantAvatar';
import ParticipantName from './ParticipantName';

export function ChatHeaderMain() {
  const { t } = useTranslation('ChatScreen');
  const myProfileSnap = useMyProfileSnap();
  const conversationSnap = useConversationSnap();
  const conversationDoc = useMemo(() => conversationSnap.data(), [conversationSnap]);

  const participantRefs = useMemo(
    () => _.filter(
      conversationDoc.participantRefs,
      (r) => r.id !== myProfileSnap.ref.id,
    ),
    [conversationDoc.participantRefs, myProfileSnap.ref.id],
  );

  const showError = useShowError();
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  const [closing, setClosing] = useState<boolean>(false);
  const handleCloseConfirmClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      setClosing(true);
      setDoc(conversationSnap.ref, {
        closedAt: serverTimestamp(),
        closedBy: ConversationClosedBy.GUEST,
        status: ConversationStatus.CLOSED,
      }, { merge: true })
        .finally(() => setClosing(false))
        .then(() => navigate('..'))
        .catch(showError);
    },
    [conversationSnap.ref, navigate, showError],
  );

  return (
    <HStack gap={4}>
      <IconButton
        aria-label={t('backButton.default')}
        as={Link}
        icon={<Icon as={LuChevronLeft} />}
        to=".."
      />

      <AvatarGroup max={2}>
        {participantRefs.map((participantRef) => (
          <ParticipantAvatar key={participantRef.id} participantRef={participantRef} />
        ))}
      </AvatarGroup>

      <Heading
        flex={1}
        fontSize="4xl"
        lineHeight="40px"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {participantRefs.map((participantRef) => (
          <ParticipantName
            key={participantRef.id}
            participantRef={participantRef}
          />
        ))}
      </Heading>

      <IconButton
        aria-label={t('closeButton.default')}
        colorScheme="red"
        icon={<Icon as={LuHeartOff} />}
        onClick={onOpen}
      />

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogContent
          mx={4}
        >
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t('closeAlertModal.title')}
          </AlertDialogHeader>

          <AlertDialogBody>
            {t('closeAlertModal.body')}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose} ref={cancelRef} variant="ghost">
              {t('closeAlertModal.cancelButton.default')}
            </Button>

            <Button
              isLoading={closing}
              loadingText={t('closeAlertModal.confirmButton.loading')}
              ml={3}
              onClick={handleCloseConfirmClick}
            >
              {t('closeAlertModal.confirmButton.default')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </HStack>
  );
}

export function ChatHeaderLoading() {
  return (
    <HStack gap={4}>
      <Skeleton h={10} w={10} />
      <SkeletonCircle boxSize={10} />
      <Skeleton flex={1} h={10} />
      <Skeleton h={10} w={10} />
    </HStack>
  );
}

export default function ChatHeader() {
  return (
    <Catch fallback={null}>
      <Suspense fallback={<ChatHeaderLoading />}>
        <ChatHeaderMain />
      </Suspense>
    </Catch>
  );
}
