import { useColorMode } from '@chakra-ui/react';
import { useEffect } from 'react';

export default function TelegramThemeUpdater() {
  const { colorMode } = useColorMode();

  useEffect(
    () => {
      switch (colorMode) {
        case 'dark': {
          window.Telegram.WebApp.setHeaderColor('#212121');
          window.Telegram.WebApp.setBackgroundColor('#212121');
          break;
        }
        case 'light':
        default: {
          window.Telegram.WebApp.setHeaderColor('#FFFFFF');
          window.Telegram.WebApp.setBackgroundColor('#FFFFFF');
          break;
        }
      }
    },
    [colorMode],
  );

  return null;
}
