import {
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  VStack,
} from '@chakra-ui/react';
import { useField } from 'formik';
import _ from 'lodash';
import { useCallback } from 'react';

import useShowError from '../hooks/useShowError';

export interface Props {
  label: string;
  name: string;
  options: Record<string, string>;
}

export default function CheckboxFormControl({
  label,
  name,
  options,
}: Props) {
  const showError = useShowError();

  const [input, meta, helper] = useField<string[]>(name);

  const handleChange = useCallback(
    (value: string[]) => {
      helper.setValue(value)
        .catch(showError);
    },
    [helper, showError],
  );

  return (
    <FormControl isInvalid={!!meta.error}>
      <FormLabel>{label}</FormLabel>
      <CheckboxGroup onChange={handleChange} value={input.value}>
        <VStack alignItems="stretch">
          {_.toPairs(options).map(([key, value]) => (
            <Checkbox key={key} value={key}>{value}</Checkbox>
          ))}
        </VStack>
      </CheckboxGroup>
      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}
