import { Badge, BadgeProps } from '@chakra-ui/react';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Gender from '../../common/Gender';
import Catch from '../Catch';
import { useProfileSnap } from '../snapProviders/ProfileSnapProvider';

export type Props = BadgeProps;

export function EyeColorBadgeMain({
  ...badgeProps
}: Props) {
  const profileSnap = useProfileSnap();
  const profileDoc = useMemo(() => profileSnap.data(), [profileSnap]);

  const { t } = useTranslation('EyeColor');

  if (profileDoc.gender !== Gender.FEMALE) {
    return null;
  }

  if (!profileDoc.eyeColor) {
    return null;
  }

  return (
  // eslint-disable-next-line react/jsx-props-no-spreading
    <Badge {...badgeProps}>
      {t(`${profileDoc.eyeColor}.label`)}
    </Badge>
  );
}

export default function EyeColorBadge(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <EyeColorBadgeMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
