import { Button, Container, VStack } from '@chakra-ui/react';
import {
  doc,
  DocumentReference,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import { Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useCitiesCollectionRef } from '../../collections/Cities';
import {
  useVenuesCollectionRef,
  VenueDistinction,
  VenueDoc,
  VenueLabel,
  VenueType,
} from '../../collections/Venues';
import AppLanguage from '../../common/AppLanguage';
import Tier from '../../common/Tier';
import CheckboxFormControl from '../../components/CheckboxFormControl';
import CityFormControl from '../../components/CityFormControl';
import InstagramFormControl from '../../components/InstagramFormControl';
import PicturesFormControl from '../../components/PicturesFormControl';
import TextareaFormControl from '../../components/TextareaFormControl';
import TextFormControl from '../../components/TextFormControl';
import TierFormControl from '../../components/TierFormControl';

export type Props = {
  onComplete: (tripRef: DocumentReference<VenueDoc>) => void;
};

export default function VenueForm({ onComplete }: Props) {
  const { t } = useTranslation('VenueCreateScreen', { keyPrefix: 'VenueForm' });

  const schema = useMemo(
    () => yup.object().shape({
      cityId: yup
        .string()
        .label(t('cityId.label'))
        .required(),
      description: yup
        .string()
        .label(t('description.label'))
        .required(),
      distinctions: yup
        .array()
        .label(t('distinctions.label'))
        .min(0)
        .max(20)
        .of(
          yup
            .string()
            .label(t('distinctions.item.label'))
            .oneOf(Object.values(VenueDistinction))
            .required(),
        )
        .required(),
      instagramTag: yup
        .string()
        .matches(/^[a-zA-Z0-9._]+$/, t('instagramTag.matchesError'))
        .label(t('instagramTag.label'))
        .required(),
      labels: yup
        .array()
        .label(t('labels.label'))
        .min(1)
        .max(20)
        .of(
          yup
            .string()
            .label(t('labels.item.label'))
            .oneOf(Object.values(VenueLabel))
            .required(),
        )
        .required(),
      name: yup
        .string()
        .label(t('name.label'))
        .required(),
      pictures: yup
        .array()
        .label(t('pictures.label'))
        .compact()
        .min(1)
        .max(6)
        .of(
          yup
            .object()
            .label(t('pictures.item.label'))
            .shape({
              blurHash: yup.string(),
              imgixUrl: yup.string().required(),
              storageRef: yup.string().required(),
            })
            .required(),
        )
        .required(),
      tier: yup
        .number()
        .min(Tier.HERMES)
        .max(Tier.ZEUS)
        .label(t('tier.label'))
        .required(),
      types: yup
        .array()
        .label(t('types.label'))
        .min(1)
        .max(20)
        .of(
          yup
            .string()
            .label(t('types.item.label'))
            .oneOf(Object.values(VenueType))
            .required(),
        )
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      cityId: 'WAW',
      description: '',
      distinctions: [],
      instagramTag: '',
      labels: [],
      name: '',
      pictures: [],
      tier: Tier.HERMES,
      types: [VenueType.RESTAURANT],
    }),
    [],
  );

  const venuesCollectionRef = useVenuesCollectionRef();
  const citiesCollectionRef = useCitiesCollectionRef();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      const venueRef = doc(venuesCollectionRef);
      const cityRef = doc(citiesCollectionRef, values.cityId);

      await setDoc(
        venueRef,
        {
          _v: 1,
          cityRef,
          createdAt: Timestamp.now(),
          description: { en: values.description } as Record<AppLanguage, string>,
          distinctions: values.distinctions,
          instagramTag: values.instagramTag || undefined,
          labels: values.labels,
          name: values.name,
          pictures: values.pictures,
          tier: values.tier,
          types: values.types,
          updatedAt: Timestamp.now(),
        },
      );

      onComplete(venueRef);
    },
    [citiesCollectionRef, onComplete, venuesCollectionRef],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        isValidating,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          flex={1}
          gap={0}
          minH={0}
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Container
            flex={1}
            maxW="lg"
            overflowY="auto"
            py={2}
          >
            <VStack alignItems="stretch" flex={1} gap={4} overflow="auto">
              <PicturesFormControl
                label={t('pictures.label')}
                name="pictures"
              />

              <TextFormControl
                label={t('name.label')}
                name="name"
              />

              <TextareaFormControl
                label={t('description.label')}
                name="description"
              />

              <InstagramFormControl
                label={t('instagramTag.label')}
                name="instagramTag"
                type="text"
              />

              <CityFormControl
                aroundLatLngViaIP
                label={t('cityId.label')}
                name="cityId"
                placeholder={t('cityId.placeholder')}
              />

              <CheckboxFormControl
                label={t('types.label')}
                name="types"
                options={Object.values(VenueType).reduce((r, c) => ({ ...r, [c]: t(`types.option.${c}`) }), {})}
              />

              <CheckboxFormControl
                label={t('labels.label')}
                name="labels"
                options={Object.values(VenueLabel).reduce((r, c) => ({ ...r, [c]: t(`labels.option.${c}`) }), {})}
              />

              <CheckboxFormControl
                label={t('distinctions.label')}
                name="distinctions"
                options={Object.values(VenueDistinction).reduce((r, c) => ({ ...r, [c]: t(`distinctions.option.${c}`) }), {})}
              />

              <TierFormControl
                label={t('tier.label')}
                name="tier"
              />
            </VStack>
          </Container>

          <Container
            maxW="lg"
            py={2}
          >
            <Button
              colorScheme={isValid ? undefined : 'red'}
              isLoading={isValidating || isSubmitting}
              loadingText={isValidating ? t('createButton.validating') : t('createButton.loading')}
              type="submit"
              w="100%"
            >
              {isValid ? t('createButton.default') : t('createButton.invalid')}
            </Button>
          </Container>
        </VStack>
      )}
    </Formik>
  );
}
