import { useMemo } from 'react';

import Gender from '../../common/Gender';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import MyTripsScreen from '../MyTripsScreen';
import TheirTripsScreen from '../TheirTripsScreen';

export default function TripsScreen() {
  const myProfileSnap = useMyProfileSnap();
  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  switch (myProfileDoc.gender) {
    case Gender.FEMALE: {
      return (
        <TheirTripsScreen />
      );
    }
    case Gender.MALE: {
      return (
        <MyTripsScreen />
      );
    }
    default: {
      throw new Error('Unknown Gender');
    }
  }
}
