import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { ChangeEvent, useCallback } from 'react';

import useShowError from '../hooks/useShowError';

export type Props = {
  label: string;
  name: string;
} & TextareaProps;

export default function TextareaFormControl({ label, name, ...textareaProps }: Props) {
  const showError = useShowError();

  const [input, meta, helper] = useField<string>(name);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      helper.setValue(e.target.value)
        .catch(showError);
    },
    [helper, showError],
  );

  return (
    <FormControl isInvalid={!!meta.error}>
      <FormLabel>{label}</FormLabel>
      <Textarea
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...textareaProps}
        onChange={handleChange}
        value={input.value}
      />
      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}
