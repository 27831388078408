import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import AppLanguage from '../common/AppLanguage';
import Tier from '../common/Tier';
import { type DestinationDoc } from './Destinations';
import { type ProfileDoc } from './Profiles';

export enum TripStatus {
  CREATED = 'CREATED',
  EXPIRED = 'EXPIRED',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export interface TripDoc {
  _v: 1;
  checkoutSessionUrl?: string;
  createdAt: Timestamp;
  departure: string;
  description: string;
  destinationRef: DocumentReference<DestinationDoc>;
  expiresAt: Timestamp;
  indexedAt?: Timestamp;
  organizerRef: DocumentReference<ProfileDoc>;
  publishedAt?: Timestamp;
  status: TripStatus;
  tier: Tier;
  translations?: {
    description: Record<AppLanguage, string>;
  };
  unpublishedAt?: Timestamp;
  updatedAt: Timestamp;
}

export const isTripDoc = (
  data?: DocumentData,
): data is TripDoc => true;

export const isTripRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<TripDoc> => ref.parent.id === 'trips';

function isTripSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<TripDoc>;
function isTripSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<TripDoc>;
function isTripSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<TripDoc> | QueryDocumentSnapshot<TripDoc> {
  return isTripRef(snap.ref);
}

export { isTripSnap };

export const getTripsCollectionRef = (firestore: Firestore) => collection(firestore, 'trips') as CollectionReference<TripDoc>;

export const useTripsCollectionRef = () => {
  const firestore = useFirestore();
  return getTripsCollectionRef(firestore);
};
