import { QueryDocumentSnapshot } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext } from 'react';

import { ReservationDoc } from '../../collections/Reservations';

type Props = {
  reservationSnap: QueryDocumentSnapshot<ReservationDoc>;
};

const ReservationSnapContext = createContext<null | QueryDocumentSnapshot<ReservationDoc>>(null);

export const useReservationSnap = () => {
  const ctx = useContext(ReservationSnapContext);

  if (!ctx) {
    throw new Error('ReservationSnapProvider');
  }

  return ctx;
};

export default function ReservationSnapProvider({
  children,
  reservationSnap,
}: PropsWithChildren<Props>) {
  return (
    <ReservationSnapContext.Provider value={reservationSnap}>
      {children}
    </ReservationSnapContext.Provider>
  );
}
