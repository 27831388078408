import { Button, Icon, IconButton } from '@chakra-ui/react';
import { signOut } from 'firebase/auth';
import mixpanel from 'mixpanel-browser';
import { Suspense, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LuLogOut, LuSettings } from 'react-icons/lu';
import { useAuth } from 'reactfire';

import Catch from '../../components/Catch';
import useShowError from '../../hooks/useShowError';

export function SettingsMenuMain() {
  const { t } = useTranslation('SettingsScreen', { keyPrefix: 'SettingsMenu' });

  const showError = useShowError();

  const auth = useAuth();

  const handleLogOut = useCallback(
    () => {
      signOut(auth)
        .then(() => mixpanel.reset())
        .catch(showError);
    },
    [auth, showError],
  );

  return (
    <Button
      colorScheme="red"
      justifyContent="start"
      leftIcon={<Icon as={LuLogOut} />}
      onClick={handleLogOut}
      variant="ghost"
    >
      {t('logOutButton.default')}
    </Button>
  );
}

export default function SettingsMenu() {
  const { t } = useTranslation('SettingsScreen', { keyPrefix: 'SettingsMenu' });

  return (
    <Catch fallback={null}>
      <Suspense
        fallback={(
          <IconButton
            aria-label={t('settingsButton.default')}
            icon={<Icon as={LuSettings} />}
            isLoading
          />
        )}
      >
        <SettingsMenuMain />
      </Suspense>
    </Catch>
  );
}
