import {
  Box,
  BoxProps,
  Icon,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import { animated, useSprings } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { clamp } from 'lodash';
import mixpanel from 'mixpanel-browser';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  LuGalleryVertical,
  LuHeart,
  LuHexagon,
  LuText,
  LuX,
} from 'react-icons/lu';

import { ApplicationStatus } from '../../../collections/Applications';
import ReservationAlgoliaSearchRecord from '../../../common/ReservationAlgoliaSearchRecord';
import Catch from '../../../components/Catch';
import LogoFull from '../../../components/LogoFull';
import StoragePictureCell from '../../../components/StoragePictureCell';
import InfoCell from './InfoCell';

export type Props = {
  // eslint-disable-next-line react/require-default-props
  applicationStatus?: ApplicationStatus;
  height: number;
  onExit: () => void;
  onSwipeLeft: () => void;
  onSwipeRight: () => void;
  queryId: string | undefined;
  reservationRecord: ReservationAlgoliaSearchRecord;
  searchResultPosition: number;
  width: number;
} & BoxProps;

export function ReservationMain({
  applicationStatus,
  height,
  onExit,
  onSwipeLeft,
  onSwipeRight,
  queryId,
  reservationRecord,
  searchResultPosition,
  width,
  ...boxProps
}: Props) {
  const ref = useRef<HTMLDivElement>(null);

  const { t } = useTranslation('TheirReservationsScreen', { keyPrefix: 'Reservation' });

  const animationIndex = useRef(0);

  const [pages, api] = useSprings(reservationRecord.organizer.pictures.length + 1, (i) => ({
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: `rgb(from var(--chakra-colors-white) r g b / ${i === 0 ? 1 : (i === 1 ? 0.5 : 0.25)})`,
    display: 'block',
    y: i * height,
  }));

  const bind = useDrag(({
    active, cancel, direction: [,yDir], movement: [,my],
  }) => {
    if (active && Math.abs(my) > 100) {
      animationIndex.current = clamp(
        animationIndex.current + (yDir > 0 ? -1 : 1),
        0,
        reservationRecord.organizer.pictures.length,
      );
      cancel();
    }

    api.start((i) => {
      if (i < animationIndex.current - 1 || i > animationIndex.current + 1) {
        return {
          backgroundColor: 'rgb(from var(--chakra-colors-white) r g b / 0.25)',
          display: 'none',
        };
      }

      const y = (i - animationIndex.current) * height + (active ? my : 0);
      const backgroundColor = `rgb(from var(--chakra-colors-white) r g b / ${i !== animationIndex.current ? 0.5 : 1})`;
      return {
        backgroundColor,
        display: 'block',
        y,
      };
    });
  }, {
    axis: 'y',
    preventDefault: true,
    threshold: 20,
  });

  const handleDislikeClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      mixpanel.track('Reservation Dislike Clicked');

      onSwipeLeft();
    },
    [onSwipeLeft],
  );

  const handleLikeClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      mixpanel.track('Reservation Like Clicked');

      onSwipeRight();
    },
    [onSwipeRight],
  );

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      background="var(--chakra-colors-chakra-body-bg)"
      className="reservation"
      h={`${height}px`}
      position="relative"
      w={`${width}px`}
    >
      <IconButton
        aria-label={t('exitButton.default')}
        icon={<Icon as={LuGalleryVertical} />}
        onClick={onExit}
        position="absolute"
        right={4}
        size="lg"
        top="max(env(safe-area-inset-top), var(--chakra-space-4))"
        variant="solid"
        zIndex={10}
      />

      <LogoFull
        color="white"
        h={12}
        left={4}
        position="absolute"
        top="max(env(safe-area-inset-top), var(--chakra-space-4))"
        w={36}
        zIndex={10}
      />

      <VStack
        alignItems="center"
        bottom="max(env(safe-area-inset-bottom), var(--chakra-space-4))"
        gap={4}
        justifyContent="center"
        position="absolute"
        right={4}
        top="max(env(safe-area-inset-top), var(--chakra-space-4))"
        zIndex={10}
      >
        {pages.map(({ backgroundColor }, i) => (
          <Box
            as={animated.div}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            style={{ color: backgroundColor }}
            transition="color 0.15s ease-in-out"
          >
            {(i >= pages.length - 1) ? (<Icon as={LuText} boxSize={4} display="block" />) : null}
            {(i < pages.length - 1) ? (<Icon as={LuHexagon} boxSize={4} display="block" />) : null}
          </Box>
        ))}
      </VStack>

      <IconButton
        aria-label={t('dislikeButton.default')}
        bottom="max(env(safe-area-inset-bottom), var(--chakra-space-4))"
        icon={<Icon as={LuX} />}
        left={4}
        onClick={handleDislikeClick}
        position="absolute"
        size="lg"
        variant="solid"
        zIndex={10}
      />

      <IconButton
        aria-label={t('applyButton.default')}
        bottom="max(env(safe-area-inset-bottom), var(--chakra-space-4))"
        className="reservationAction"
        colorScheme="pink"
        icon={<Icon as={LuHeart} />}
        isDisabled={!!applicationStatus}
        onClick={handleLikeClick}
        position="absolute"
        right={4}
        size="lg"
        variant="solid"
        zIndex={10}
      />

      <Box
        h={`${height}px`}
        overflow="hidden"
        position="relative"
        ref={ref}
        w={`${width}px`}
      >
        {pages.map(({ display, y }, i) => {
          if (i === pages.length - 1) {
            return (
              <InfoCell
                as={animated.div}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...bind()}
                height={height}
                key="info"
                position="absolute"
                queryId={queryId}
                reservationRecord={reservationRecord}
                searchResultPosition={searchResultPosition}
                style={{
                  display: display as unknown as string,
                  touchAction: 'none',
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  /* @ts-ignore */
                  y,
                }}
                width={width}
              />
            );
          }

          return (
            <StoragePictureCell
              as={animated.div}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...bind()}
              height={height}
              key={reservationRecord.organizer.pictures[i].imgixUrl}
              picture={reservationRecord.organizer.pictures[i]}
              position="absolute"
              style={{
                display: display as unknown as string,
                touchAction: 'none',
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                /* @ts-ignore */
                y,
              }}
              width={width}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export default function Reservation(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <ReservationMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
