import {
  AspectRatio,
  Badge,
  Button,
  Grid,
  HStack,
  Image,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import {
  doc,
  DocumentReference,
  QueryDocumentSnapshot,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import { Formik } from 'formik';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { DestinationDoc } from '../../collections/Destinations';
import { TripDoc, TripStatus, useTripsCollectionRef } from '../../collections/Trips';
import AppLanguage from '../../common/AppLanguage';
import { getPhotoSizeUrl } from '../../common/getPhotoSizeUrl';
import Tier from '../../common/Tier';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import TextareaFormControl from '../../components/TextareaFormControl';
import TextFormControl from '../../components/TextFormControl';
import TierFormControl from '../../components/TierFormControl';
import TierIcon from '../../components/TierIcon';

export type Props = {
  destinationSnap: QueryDocumentSnapshot<DestinationDoc>;
  onComplete: (tripRef: DocumentReference<TripDoc>) => void;
};

export default function TripForm({ destinationSnap, onComplete }: Props) {
  const { i18n, t } = useTranslation('TripCreateScreen', { keyPrefix: 'TripForm' });
  const { t: tt } = useTranslation('Tier');

  const destinationDoc = useMemo(() => destinationSnap.data(), [destinationSnap]);

  const schema = useMemo(
    () => yup.object().shape({
      departure: yup
        .string()
        .label(t('departure.label'))
        .required(),
      description: yup
        .string()
        .label(t('description.label'))
        .required(),
      tier: yup
        .number()
        .label(t('tier.label'))
        .required(),
    }),
    [t],
  );

  const myProfileSnap = useMyProfileSnap();

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      departure: moment
        .utc()
        .startOf('month')
        .add(1, 'month')
        .format('YYYY-MM-DD'),
      description: '',
      tier: Tier.HERMES,
    }),
    [],
  );

  const tripsCollectionRef = useTripsCollectionRef();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      const tripRef = doc(tripsCollectionRef);

      await setDoc(
        tripRef,
        {
          _v: 1,
          createdAt: Timestamp.now(),
          departure: values.departure,
          description: values.description,
          destinationRef: destinationSnap.ref,
          expiresAt: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 365),
          organizerRef: myProfileSnap.ref,
          status: TripStatus.CREATED,
          tier: values.tier,
          updatedAt: Timestamp.now(),
        },
      );

      mixpanel.track('Trip Created', {
        createdAt: new Date(),
        departure: values.departure,
        description: values.description,
        destinationId: destinationSnap.id,
        organizerId: myProfileSnap.ref.id,
        status: TripStatus.CREATED,
        updatedAt: new Date(),
      });

      onComplete(tripRef);
    },
    [
      destinationSnap,
      onComplete,
      myProfileSnap.ref,
      tripsCollectionRef,
    ],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        isValidating,
        values,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          gap={4}
          h="100%"
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
        >
          <VStack alignItems="stretch" flex={1} gap={4} overflow="auto">
            <Grid autoRows="1fr" gap={2} templateColumns="repeat(3, 1fr)">
              <AspectRatio ratio={9 / 16}>
                <Image
                  borderRadius="md"
                  objectFit="cover"
                  src={getPhotoSizeUrl({
                    height: 320,
                    uri: destinationDoc.picture.urls.raw,
                    width: 180,
                  })}
                />
              </AspectRatio>
            </Grid>

            <HStack>
              <Text fontSize="xl">
                {destinationDoc.countryEmoji}
              </Text>

              <Text fontSize="xl">
                {destinationDoc.countryName[i18n.language as AppLanguage]}
              </Text>
            </HStack>

            <Text>
              {destinationDoc.description[i18n.language as AppLanguage]}
            </Text>

            <Wrap>
              {destinationDoc.labels.map((label) => (
                <Badge key={label}>{label}</Badge>
              ))}
            </Wrap>

            <TextFormControl
              label={t('departure.label')}
              name="departure"
              type="date"
            />

            <TextareaFormControl
              label={t('description.label')}
              name="description"
              placeholder={t('description.placeholder')}
              rows={10}
            />

            <TierFormControl
              label={t('tier.label')}
              name="tier"
            />

            <Text fontSize="sm">
              {values.tier === Tier.HERMES && tt('Trip.male.hermes.body')}
              {values.tier === Tier.APOLLO && tt('Trip.male.apollo.body')}
              {values.tier === Tier.POSEIDON && tt('Trip.male.poseidon.body')}
              {values.tier === Tier.ZEUS && tt('Trip.male.zeus.body')}
            </Text>

            <VStack alignItems="stretch" gap={1}>
              <Text as="strong" fontSize="sm">
                {tt('Trip.accommodations')}
              </Text>

              <Text fontSize="sm">
                {values.tier === Tier.HERMES && tt('Trip.male.hermes.accommodations')}
                {values.tier === Tier.APOLLO && tt('Trip.male.apollo.accommodations')}
                {values.tier === Tier.POSEIDON && tt('Trip.male.poseidon.accommodations')}
                {values.tier === Tier.ZEUS && tt('Trip.male.zeus.accommodations')}
              </Text>
            </VStack>

            <VStack alignItems="stretch" gap={1}>
              <Text as="strong" fontSize="sm">
                {tt('Trip.transportation')}
              </Text>

              <Text fontSize="sm">
                {values.tier === Tier.HERMES && tt('Trip.male.hermes.transportation')}
                {values.tier === Tier.APOLLO && tt('Trip.male.apollo.transportation')}
                {values.tier === Tier.POSEIDON && tt('Trip.male.poseidon.transportation')}
                {values.tier === Tier.ZEUS && tt('Trip.male.zeus.transportation')}
              </Text>
            </VStack>

            <VStack alignItems="stretch" gap={1}>
              <Text as="strong" fontSize="sm">
                {tt('Trip.food')}
              </Text>

              <Text fontSize="sm">
                {values.tier === Tier.HERMES && tt('Trip.male.hermes.food')}
                {values.tier === Tier.APOLLO && tt('Trip.male.apollo.food')}
                {values.tier === Tier.POSEIDON && tt('Trip.male.poseidon.food')}
                {values.tier === Tier.ZEUS && tt('Trip.male.zeus.food')}
              </Text>
            </VStack>

            <VStack alignItems="stretch" gap={1}>
              <Text as="strong" fontSize="sm">
                {tt('Trip.activities')}
              </Text>

              <Text fontSize="sm">
                {values.tier === Tier.HERMES && tt('Trip.male.hermes.activities')}
                {values.tier === Tier.APOLLO && tt('Trip.male.apollo.activities')}
                {values.tier === Tier.POSEIDON && tt('Trip.male.poseidon.activities')}
                {values.tier === Tier.ZEUS && tt('Trip.male.zeus.activities')}
              </Text>
            </VStack>
          </VStack>

          <Button
            colorScheme={isValid ? undefined : 'red'}
            isLoading={isValidating || isSubmitting}
            leftIcon={<TierIcon tier={values.tier} />}
            loadingText={t('createButton.loading')}
            onClick={() => handleSubmit()}
          >
            {t('createButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
