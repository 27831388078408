import {
  Center,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
} from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import {
  ChangeEvent,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LuChevronLeft, LuSearch } from 'react-icons/lu';
import { Link } from 'react-router-dom';

import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import LogoIcon from '../../components/LogoIcon';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import VenueList from './VenueList';

export function VentureCreateScreenMain() {
  useEffect(() => { mixpanel.track_pageview({ screen: 'VentureCreateScreen' }); }, []);

  const insets = useInsets();

  const { t } = useTranslation('VentureCreateInitScreen');

  const [queryInputValue, setQueryInputValue] = useState<string>('');
  const [query, setQuery] = useState<string>(queryInputValue);

  const onQueryInputValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setQueryInputValue(e.target.value);
    },
    [],
  );

  useEffect(
    () => {
      const timeout = setTimeout(
        () => setQuery(queryInputValue),
        600,
      );

      return () => {
        clearTimeout(timeout);
      };
    },
    [queryInputValue],
  );

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <VStack alignItems="stretch" gap={0} h="100%">
        <Container
          maxW="lg"
          pb={2}
          pt={`max(${insets.top}, var(--chakra-space-2))`}
        >
          <HStack gap={4}>
            <IconButton
              aria-label={t('backButton.default')}
              as={Link}
              icon={<Icon as={LuChevronLeft} />}
              to=".."
            />

            <Heading
              flex={1}
              fontSize="4xl"
              lineHeight="40px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {t('title')}
            </Heading>
          </HStack>
        </Container>

        <Container
          maxW="lg"
          py={2}
        >
          <InputGroup>
            <InputLeftElement>
              <Icon as={LuSearch} />
            </InputLeftElement>

            <Input
              onChange={onQueryInputValueChange}
              placeholder={t('searchInput.placeholder')}
              value={queryInputValue}
            />
          </InputGroup>
        </Container>

        <Container
          flex={1}
          maxW="lg"
          overflowY="auto"
          py={2}
        >
          <VenueList
            query={query}
          />
        </Container>
      </VStack>
    </>
  );
}

export default function VentureCreateScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <VentureCreateScreenMain />
      </Suspense>
    </Catch>
  );
}
