import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  useRadioGroup,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { useCallback } from 'react';

import Gender from '../../common/Gender';
import Sexuality from '../../common/Sexuality';
import useShowError from '../../hooks/useShowError';
import BothCard from './BothCard';
import GirlsCard from './GirlsCard';
import GuysCard from './GuysCard';

export interface Props {
  gender: Gender;
  // eslint-disable-next-line react/require-default-props
  helperText?: string;
  label: string;
  name: string;
}

export default function SexualityFormControl({
  gender,
  helperText,
  label,
  name,
}: Props) {
  const showError = useShowError();

  const [input, meta, helper] = useField<Sexuality>(name);

  const handleChange = useCallback(
    (value: Sexuality) => {
      helper.setValue(value)
        .catch(showError);
    },
    [helper, showError],
  );

  const { getRadioProps, getRootProps } = useRadioGroup({
    name,
    onChange: handleChange,
    value: input.value,
  });

  const group = getRootProps();

  const radioStraight = getRadioProps({ value: Sexuality.STRAIGHT });
  const radioBisexual = getRadioProps({ value: Sexuality.BISEXUAL });

  return (
    <FormControl isInvalid={!!meta.error}>
      <FormLabel>{label}</FormLabel>

      <HStack
        alignItems="stretch"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...group}
        gap={4}
      >
        {gender === Gender.MALE ? (
          <GirlsCard
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...radioStraight}
          />
        ) : (
          <GuysCard
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...radioStraight}
          />
        )}

        <BothCard
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...radioBisexual}
        />
      </HStack>

      {helperText ? (
        <FormHelperText>
          {helperText}
        </FormHelperText>
      ) : null}

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}
