import { QueryDocumentSnapshot } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext } from 'react';

import { TripDoc } from '../../collections/Trips';

type Props = {
  tripSnap: QueryDocumentSnapshot<TripDoc>;
};

const TripSnapContext = createContext<null | QueryDocumentSnapshot<TripDoc>>(null);

export const useTripSnap = () => {
  const ctx = useContext(TripSnapContext);

  if (!ctx) {
    throw new Error('TripSnapProvider');
  }

  return ctx;
};

export default function TripSnapProvider({
  children,
  tripSnap,
}: PropsWithChildren<Props>) {
  return (
    <TripSnapContext.Provider value={tripSnap}>
      {children}
    </TripSnapContext.Provider>
  );
}
