import {
  Avatar,
  Box,
  BoxProps,
  Button,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuHeart } from 'react-icons/lu';

import { ApplicationStatus } from '../../../collections/Applications';
import AppLanguage from '../../../common/AppLanguage';
import { getPhotoSizeUrl } from '../../../common/getPhotoSizeUrl';
import getPrimaryPhoto from '../../../common/getPrimaryPhoto';
import { StoragePicture } from '../../../common/StoragePicture';
import VentureAlgoliaSearchRecord from '../../../common/VentureAlgoliaSearchRecord';
import Catch from '../../../components/Catch';
import i18n from '../../../i18n';

export type Props = {
  // eslint-disable-next-line react/require-default-props
  applicationStatus?: ApplicationStatus;
  onSwipeRight: () => void;
  ventureRecord: VentureAlgoliaSearchRecord;
} & BoxProps;

export function VentureRowMain({
  applicationStatus, onSwipeRight, ventureRecord, ...boxProps
}: Props) {
  const { t } = useTranslation('TheirVenturesScreen', { keyPrefix: 'Venture' });

  const src = useMemo(
    () => getPhotoSizeUrl({
      height: 320,
      uri: ventureRecord.pictures[0].imgixUrl,
      width: 180,
    }),
    [ventureRecord.pictures],
  );

  const primaryPictureSrc = useMemo(
    () => {
      const uri = getPrimaryPhoto(
        ventureRecord.organizer.pictures as [StoragePicture],
        ventureRecord.organizer.primaryPictureIndex,
      ).imgixUrl;

      if (!uri) {
        return undefined;
      }

      return getPhotoSizeUrl({
        faces: true,
        height: 16,
        uri,
        width: 16,
      });
    },
    [ventureRecord.organizer.pictures, ventureRecord.organizer.primaryPictureIndex],
  );

  const handleLikeClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      mixpanel.track('Venture Like Clicked');

      onSwipeRight();
    },
    [onSwipeRight],
  );

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      py={2}
    >
      <HStack
        alignItems="stretch"
        borderRadius="md"
        gap={0}
        h="100%"
        overflow="hidden"
      >
        <Box flexGrow={0} flexShrink={0} h="100%">
          <Image
            h="100%"
            objectFit="cover"
            src={src}
          />
        </Box>

        <VStack alignItems="stretch" flex={1} minW={0} p={3}>
          <HStack>
            <Text
              flexShrink={1}
              fontSize="xl"
              fontWeight="bold"
              lineHeight={1}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {ventureRecord.name}
            </Text>
          </HStack>

          <HStack>
            <Text
              flexGrow={1}
              flexShrink={1}
              fontSize="14px"
              lineHeight="14px"
              opacity={0.75}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {ventureRecord.venue.city.name}
            </Text>

            <Text
              flexGrow={0}
              flexShrink={0}
              fontSize="14px"
              lineHeight="14px"
              opacity={0.75}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {t('InfoCell.startsAt', {
                formatParams: { startsAt: { dateStyle: 'short', timeStyle: 'short' } },
                startsAt: moment(ventureRecord.startsAt * 1000).toDate(),
              })}
            </Text>
          </HStack>

          <Text
            flexShrink={1}
            fontSize="14px"
            h="80px"
            lineHeight="16px"
            noOfLines={5}
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {/* eslint-disable-next-line max-len */}
            {ventureRecord.translations?.description[i18n.language as AppLanguage] ?? ventureRecord.description}
          </Text>

          <HStack>
            <Avatar
              boxSize={6}
              flexGrow={0}
              flexShrink={0}
              size="xs"
              src={primaryPictureSrc}
            />

            <Text
              flexGrow={1}
              flexShrink={1}
              fontSize="16px"
              lineHeight="24px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {/* eslint-disable-next-line max-len */}
              {ventureRecord.organizer.translations?.name[i18n.language as AppLanguage] ?? ventureRecord.organizer.name}
              {', '}
              {ventureRecord.organizer.age}
            </Text>

            <Button
              flexGrow={0}
              flexShrink={0}
              isDisabled={!!applicationStatus}
              leftIcon={<Icon as={LuHeart} />}
              onClick={handleLikeClick}
              size="xs"
            >
              Apply
            </Button>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
}

export function VentureRowLoading({ ...boxProps }: BoxProps) {
  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      Loading...
    </Box>
  );
}

export default function VentureRow({ applicationStatus, ventureRecord, ...boxProps }: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={(
        <VentureRowLoading
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
        />
      )}
      >
        <VentureRowMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
          applicationStatus={applicationStatus}
          ventureRecord={ventureRecord}
        />
      </Suspense>
    </Catch>
  );
}
