import { Badge, BadgeProps } from '@chakra-ui/react';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Gender from '../../common/Gender';
import PantiesSize from '../../common/PantiesSize';
import Catch from '../Catch';
import { useProfileSnap } from '../snapProviders/ProfileSnapProvider';

export type Props = BadgeProps;

export function PantiesSizeBadgeMain({
  ...badgeProps
}: Props) {
  const profileSnap = useProfileSnap();
  const profileDoc = useMemo(() => profileSnap.data(), [profileSnap]);

  const { t } = useTranslation('PantiesSize');

  if (profileDoc.gender !== Gender.FEMALE) {
    return null;
  }

  switch (profileDoc.pantiesSize) {
    case PantiesSize.XXS: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('XXS.label')}
        </Badge>
      );
    }
    case PantiesSize.XS: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('XS.label')}
        </Badge>
      );
    }
    case PantiesSize.S: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('S.label')}
        </Badge>
      );
    }
    case PantiesSize.M: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('M.label')}
        </Badge>
      );
    }
    case PantiesSize.L: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('L.label')}
        </Badge>
      );
    }
    case PantiesSize.XL: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('XL.label')}
        </Badge>
      );
    }
    case PantiesSize.XXL: {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Badge {...badgeProps}>
          {t('XXL.label')}
        </Badge>
      );
    }
    default: {
      return null;
    }
  }
}

export default function PantiesSizeBadge(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <PantiesSizeBadgeMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
