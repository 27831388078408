import { Button, Text, VStack } from '@chakra-ui/react';
import { deleteDoc } from 'firebase/firestore';
import moment from 'moment';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';

import useShowError from '../hooks/useShowError';
import Catch from './Catch';
import { useMyProfileSnap } from './snapProviders/MyProfileSnapProvider';
import { useProfileSnap } from './snapProviders/ProfileSnapProvider';

export function AdminMain() {
  const showError = useShowError();
  const myProfileSnap = useMyProfileSnap();

  const profileSnap = useProfileSnap();

  const profileDoc = useMemo(() => profileSnap.data(), [profileSnap]);

  const [isDeleting, setDeleting] = useState<boolean>(false);
  const handleDeleteClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      setDeleting(true);
      deleteDoc(profileSnap.ref)
        .finally(() => setDeleting(false))
        .catch(showError);
    },
    [profileSnap.ref, showError],
  );

  if (myProfileSnap.ref.id !== '1Cnp6jjyn5Z6aHdH2ZlnFmJZrWv1') {
    return null;
  }

  return (
    <VStack alignItems="stretch">
      <Text>
        {moment(profileDoc.lastSeenAt.toDate()).format('YYYY-MM-DD HH:mm:ss')}
      </Text>

      <Button
        colorScheme="red"
        isLoading={isDeleting}
        onClick={handleDeleteClick}
        size="sm"
        variant="outline"
      >
        Delete
      </Button>
    </VStack>
  );
}

export default function Admin() {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <AdminMain />
      </Suspense>
    </Catch>
  );
}
