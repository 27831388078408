import {
  Center,
  Container,
  Grid,
  Portal,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  doc,
  limit,
  orderBy,
  query,
  setDoc,
  Timestamp,
  where,
} from 'firebase/firestore';
import { slice } from 'lodash';
import moment from 'moment';
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { List, ListRowProps } from 'react-virtualized';
import { useFirestoreCollection } from 'reactfire';

import { ApplicationStatus, useApplicationsCollectionRef } from '../../collections/Applications';
import { useProfilesCollectionRef } from '../../collections/Profiles';
import { useVenturesCollectionRef, VentureStatus } from '../../collections/Ventures';
import VentureAlgoliaSearchRecord from '../../common/VentureAlgoliaSearchRecord';
import { useAlgoliaSearchClient } from '../../components/AlgoliaSearchClientProvider';
import Catch from '../../components/Catch';
import LogoIcon from '../../components/LogoIcon';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import useShowError from '../../hooks/useShowError';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import VentureRow, { VentureRowLoading } from './VentureRow';
import VentureWrapper from './VentureWrapper';

export type Props = {
  height: number;
  width: number;
};

export function VentureGridMain({ height, width }: Props) {
  const { t } = useTranslation('TheirVenturesScreen', { keyPrefix: 'VentureList' });
  const showError = useShowError();

  const { ventureId } = useParams<{ ventureId?: string }>();

  const client = useAlgoliaSearchClient();
  const venturesIndex = useMemo(() => client.initIndex('ventures'), [client]);

  const myProfileSnap = useMyProfileSnap();

  const filters = useMemo(
    () => {
      const nextFilters: (string | string[])[] = [];
      nextFilters.push(`status: ${VentureStatus.PUBLISHED}`);

      return nextFilters.join(' AND ');
    },
    [],
  );

  const numericFilters = useMemo(
    () => {
      const nextOptionalFilters: (string | string[])[] = [];

      return nextOptionalFilters.join(', ');
    },
    [],
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ventures, setVentures] = useState<VentureAlgoliaSearchRecord[]>([]);

  const applicationsCollectionRef = useApplicationsCollectionRef();
  const { data: applicationsSnap } = useFirestoreCollection(
    query(
      applicationsCollectionRef,
      where('applicantRef', '==', myProfileSnap.ref),
      orderBy('sentAt', 'desc'),
      limit(1000),
    ),
  );

  useEffect(
    () => {
      setIsLoading(true);
      venturesIndex.search<VentureAlgoliaSearchRecord>('', {
        clickAnalytics: true,
        // enablePersonalization: true,
        filters,
        length: 1000,
        numericFilters,
        offset: 0,
        userToken: myProfileSnap.ref.id,
      }).finally(() => {
        setIsLoading(false);
      }).then((response) => {
        setVentures(response.hits);
      }).catch(showError);
    },
    [filters, numericFilters, myProfileSnap.ref.id, showError, venturesIndex],
  );

  const { height: wHeight, width: wWidth } = useWindowDimensions();

  const [openVentures, setOpenVentures] = useState<VentureAlgoliaSearchRecord[]>([]);

  useEffect(
    () => {
      if (!ventureId) {
        return;
      }

      venturesIndex
        .getObject<VentureAlgoliaSearchRecord>(ventureId)
        .finally(() => {
          setIsLoading(false);
        }).then((response) => {
          setOpenVentures([response]);
        }).catch(showError);
    },
    [showError, ventureId, venturesIndex],
  );

  const [startIndex, setStartIndex] = useState<number>(0);

  const handleClick = useCallback(
    (index: number) => {
      setStartIndex(index);
      setOpenVentures(slice(ventures, index, Math.min(ventures.length, index + 5)));
    },
    [ventures],
  );

  const handleExit = useCallback(
    () => {
      setStartIndex(0);
      setOpenVentures([]);
    },
    [],
  );

  const handleSwipeLeft = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async () => {
      setStartIndex(startIndex + 1);
      setOpenVentures(slice(ventures, startIndex + 1, Math.min(ventures.length, startIndex + 6)));
    },
    [startIndex, ventures],
  );

  const profilesCollectionRef = useProfilesCollectionRef();
  const venturesCollectionRef = useVenturesCollectionRef();

  const handleApply = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (ventureRecord: VentureAlgoliaSearchRecord) => {
      const applicationRef = doc(applicationsCollectionRef);

      await setDoc(
        applicationRef,
        {
          _v: 1,
          applicantRef: myProfileSnap.ref,
          expiresAt: Timestamp.fromDate(moment.utc().add(1, 'year').toDate()),
          organizerRef: doc(profilesCollectionRef, ventureRecord.organizer.id),
          sentAt: Timestamp.now(),
          status: ApplicationStatus.SENT,
          ventureRef: doc(venturesCollectionRef, ventureRecord.objectID),
        },
      );
    },
    [
      applicationsCollectionRef,
      myProfileSnap.ref,
      profilesCollectionRef,
      venturesCollectionRef,
    ],
  );

  const handleSwipeRight = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (ventureRecord: VentureAlgoliaSearchRecord) => {
      await handleApply(ventureRecord);

      setStartIndex(startIndex + 1);
      setOpenVentures(slice(ventures, startIndex + 1, Math.min(ventures.length, startIndex + 6)));
    },
    [handleApply, startIndex, ventures],
  );

  const rowRenderer = useCallback(
    ({ index, style }: ListRowProps) => (
      <VentureRow
        // eslint-disable-next-line max-len
        applicationStatus={applicationsSnap.docs.find((s) => s.data().ventureRef?.id === ventures[index].objectID)?.data().status}
        key={ventures[index].objectID}
        onClick={() => handleClick(index)}
        onSwipeRight={() => { handleApply(ventures[index]).catch(() => { }); }}
        style={style}
        ventureRecord={ventures[index]}
      />
    ),
    [applicationsSnap.docs, handleApply, handleClick, ventures],
  );

  if (ventures.length) {
    return (
      <>
        <List
          height={height}
          overscanRowCount={3}
          rowCount={ventures.length}
          rowHeight={200}
          // eslint-disable-next-line react/no-unstable-nested-components
          rowRenderer={rowRenderer}
          width={width}
        />

        <Portal>
          {openVentures.map((ventureRecord, i) => (
            <VentureWrapper
              // eslint-disable-next-line max-len
              applicationStatus={applicationsSnap.docs.find((s) => s.data().ventureRef?.id === ventureRecord.objectID)?.data().status}
              height={wHeight}
              index={i}
              key={ventureRecord.objectID}
              onExit={handleExit}
              onSwipeLeft={handleSwipeLeft}
              onSwipeRight={handleSwipeRight}
              queryId=""
              searchResultPosition={0}
              top={0}
              ventureRecord={ventureRecord}
              width={wWidth}
              zIndex={(openVentures.length - i) * 10 + 100}
            />
          ))}
        </Portal>
      </>
    );
  }

  if (isLoading) {
    return (
      <Container height="100%" maxW="lg">
        <Center height="100%">
          <LogoIcon boxSize={16} />
        </Center>
      </Container>
    );
  }

  return (
    <Container height="100%" maxW="lg">
      <Center height="100%">
        <Text textAlign="center">
          {t('emptyList.body')}
        </Text>
      </Center>
    </Container>
  );
}

export function VentureGridLoading() {
  return (
    <VStack alignItems="stretch" className="myVenturesList" gap={8}>
      <VStack alignItems="stretch" gap={2}>
        <Skeleton h={5} />

        <Grid gap={4} gridAutoRows="1fr" templateColumns="repeat(2, 1fr)">
          <VentureRowLoading />
          <VentureRowLoading />
          <VentureRowLoading />
          <VentureRowLoading />
        </Grid>
      </VStack>
    </VStack>
  );
}

export default function VentureGrid(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<VentureGridLoading />}>
        <VentureGridMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
