import { SkeletonText } from '@chakra-ui/react';
import {
  arrayRemove,
  arrayUnion,
  orderBy,
  query,
  refEqual,
  where,
  writeBatch,
} from 'firebase/firestore';
import _ from 'lodash';
import { Suspense, useEffect, useMemo } from 'react';
import { useFirestore, useFirestoreCollection } from 'reactfire';

import { useMessagesCollectionRef } from '../../collections/Messages';
import Catch from '../../components/Catch';
import { useConversationSnap } from '../../components/snapProviders/ConversationSnapProvider';
import MessageSnapProvider from '../../components/snapProviders/MessageSnapProvider';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import UserMessageItem from './TextMessage';

export function MessageListMain() {
  const conversationSnap = useConversationSnap();
  const messagesCollectionRef = useMessagesCollectionRef();
  const { data: messagesSnap } = useFirestoreCollection(
    query(
      messagesCollectionRef,
      where('conversationRef', '==', conversationSnap.ref),
      orderBy('createdAt', 'desc'),
    ),
  );

  const myProfileSnap = useMyProfileSnap();

  const conversationDoc = useMemo(() => conversationSnap.data(), [conversationSnap]);

  const firestore = useFirestore();

  useEffect(
    () => {
      if (_.some(conversationDoc.notReadByRefs, (ref) => refEqual(ref, myProfileSnap.ref))) {
        const batch = writeBatch(firestore);

        batch.set(conversationSnap.ref, {
          notReadByRefs: arrayRemove(myProfileSnap.ref),
          readByRefs: arrayUnion(myProfileSnap.ref),
        }, { merge: true });

        batch.commit().catch(() => { });
      }
    },
    [conversationDoc.notReadByRefs, conversationSnap.ref, firestore, myProfileSnap.ref],
  );

  return (
    <>
      {messagesSnap.docs.map((messageSnap) => (
        <MessageSnapProvider key={messageSnap.id} messageSnap={messageSnap}>
          <UserMessageItem />
        </MessageSnapProvider>
      ))}
    </>
  );
}

export function MessageListLoading() {
  return (
    <>
      <SkeletonText />
      <SkeletonText />
      <SkeletonText />
    </>
  );
}

export default function MessageList() {
  return (
    <Catch fallback={null}>
      <Suspense fallback={<MessageListLoading />}>
        <MessageListMain />
      </Suspense>
    </Catch>
  );
}
