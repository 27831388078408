import {
  Button,
  Center,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { orderBy, query, where } from 'firebase/firestore';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { LuCalendarPlus } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import { useVenturesCollectionRef } from '../../collections/Ventures';
import Catch from '../../components/Catch';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import VentureSnapProvider from '../../components/snapProviders/VentureSnapProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Venture, { VentureLoading } from './Venture';

export function VentureListMain() {
  const { t } = useTranslation('MyVenturesScreen', { keyPrefix: 'VentureList' });

  const myProfileSnap = useMyProfileSnap();

  const venturesCollectionRef = useVenturesCollectionRef();
  const { data: publishedVenturesSnap } = useFirestoreCollection(
    query(
      venturesCollectionRef,
      where('organizerRef', '==', myProfileSnap.ref),
      orderBy('startsAt', 'asc'),
    ),
  );

  if (!publishedVenturesSnap.docs.length) {
    return (
      <VStack alignItems="stretch" className="myVenturesList" flex={1} gap={4} h="100%">
        <Center flex={1}>
          <Text textAlign="center">{t('emptyList.body')}</Text>
        </Center>

        <Button
          as={Link}
          className="createVentureButton"
          leftIcon={<Icon as={LuCalendarPlus} />}
          to="add"
        >
          {t('addVentureButton.default')}
        </Button>
      </VStack>
    );
  }

  return (
    <VStack alignItems="stretch" className="myVenturesList" flex={1} gap={4} h="100%">
      {publishedVenturesSnap.docs.map((ventureSnap) => (
        <VentureSnapProvider key={ventureSnap.id} ventureSnap={ventureSnap}>
          <Venture />
        </VentureSnapProvider>
      ))}
    </VStack>
  );
}

export function VentureListLoading() {
  return (
    <VStack alignItems="stretch" className="myVenturesList" flex={1} gap={4} h="100%">
      <VentureLoading />
    </VStack>
  );
}

export default function VentureList() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<VentureListLoading />}>
        <VentureListMain />
      </Suspense>
    </Catch>
  );
}
