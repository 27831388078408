import {
  AspectRatio,
  Box,
  Grid,
  Image,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';

import { TripDoc } from '../../collections/Trips';
import { getPhotoSizeUrl } from '../../common/getPhotoSizeUrl';
import Catch from '../../components/Catch';
import TripSnapProvider from '../../components/snapProviders/TripSnapProvider';
import useBlur from '../../hooks/useBlur';
import TripDetails from './TripDetails';

export type Props = {
  tripRef: DocumentReference<TripDoc>;
};

export function TripContextMain({ tripRef }: Props) {
  const { data: tripSnap } = useFirestoreDoc(tripRef);

  if (!tripSnap.exists()) {
    throw new Error('Trip does not exist');
  }

  const tripDoc = useMemo(() => tripSnap.data(), [tripSnap]);

  const { data: destinationSnap } = useFirestoreDoc(tripDoc.destinationRef);

  if (!destinationSnap.exists()) {
    throw new Error('Destination does not exist');
  }

  const destinationDoc = useMemo(() => destinationSnap.data(), [destinationSnap]);

  const src = useMemo(
    () => getPhotoSizeUrl({
      height: 320,
      uri: destinationDoc.picture.urls.raw,
      width: 320,
    }),
    [destinationDoc.picture.urls.raw],
  );

  const fallbackSrc = useBlur({
    blurHash: destinationDoc.picture.blur_hash,
    height: 32,
    width: 32,
  });

  return (
    <TripSnapProvider tripSnap={tripSnap}>
      <Grid gap={4} templateColumns="repeat(2, 1fr)">
        <AspectRatio ratio={1}>
          <Box
            borderRadius="md"
            boxShadow="base"
            h="100%"
            overflow="hidden"
            position="relative"
            w="100%"
          >
            <Image
              fallbackSrc={fallbackSrc}
              h="100%"
              objectFit="cover"
              position="absolute"
              src={src}
              w="100%"
            />
          </Box>
        </AspectRatio>

        <TripDetails />
      </Grid>
    </TripSnapProvider>
  );
}

export default function TripContext(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <TripContextMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
