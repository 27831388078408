import { Center } from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import { Suspense, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import { useReservationsCollectionRef } from '../../collections/Reservations';
import Catch from '../../components/Catch';
import LogoIcon from '../../components/LogoIcon';
import ErrorFallbackScreen from '../ErrorFallbackScreen';

export function ReservationPaymentScreenMain() {
  useEffect(() => { mixpanel.track_pageview({ screen: 'ReservationPaymentScreen' }); }, []);

  const { reservationId } = useParams<{ reservationId: string }>();

  const reservationsCollectionRef = useReservationsCollectionRef();
  const reservationRef = useMemo(
    () => doc(reservationsCollectionRef, reservationId),
    [reservationId, reservationsCollectionRef],
  );

  const { data: reservationSnap } = useFirestoreDoc(reservationRef);

  if (!reservationSnap.exists()) {
    throw new Error('Reservation does not exist');
  }

  const reservationDoc = useMemo(() => reservationSnap.data(), [reservationSnap]);

  useEffect(
    () => {
      if (reservationDoc.checkoutSessionUrl) {
        window.location.replace(reservationDoc.checkoutSessionUrl);
      }
    },
    [reservationDoc.checkoutSessionUrl],
  );

  return (
    <Center h="100%">
      <LogoIcon boxSize={16} />
    </Center>
  );
}

export default function ReservationPaymentScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <ReservationPaymentScreenMain />
      </Suspense>
    </Catch>
  );
}
