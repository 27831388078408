import {
  Box,
  BoxProps,
  Center,
  Icon,
} from '@chakra-ui/react';
import { animated, to, useSpring } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { useCallback, useEffect } from 'react';
import { LuHeart, LuX } from 'react-icons/lu';

import { ApplicationStatus } from '../../collections/Applications';
import VentureAlgoliaSearchRecord from '../../common/VentureAlgoliaSearchRecord';
import Venture from './Venture';

export type Props = {
  // eslint-disable-next-line react/require-default-props
  applicationStatus?: ApplicationStatus;
  height: number;
  index: number;
  onExit: () => void;
  onSwipeLeft: (ventureRecord: VentureAlgoliaSearchRecord) => Promise<void>;
  onSwipeRight: (ventureRecord: VentureAlgoliaSearchRecord) => Promise<void>;
  queryId: string | undefined;
  searchResultPosition: number;
  ventureRecord: VentureAlgoliaSearchRecord;
  width: number;
} & Omit<BoxProps, 'h' | 'height' | 'w' | 'width'>;

export type Values = {
  borderRadius: number;
  dislikeOpacity: number;
  likeOpacity: number;
  rotation: number;
  scale: number;
  x: number;
  y: number;
};

export default function VentureWrapper({
  applicationStatus,
  height,
  index,
  onExit,
  onSwipeLeft,
  onSwipeRight,
  queryId,
  searchResultPosition,
  ventureRecord,
  width,
  ...boxProps
}: Props) {
  const [{
    borderRadius,
    dislikeOpacity,
    likeOpacity,
    rotation,
    scale,
    x,
    y,
  }, api] = useSpring<Values>(() => ({
    borderRadius: 16,
    dislikeOpacity: 0,
    likeOpacity: 0,
    rotation: (Math.random() - 0.5) * 5,
    scale: 0.9,
    x: 0,
    y: -1000,
  }));

  useEffect(
    () => {
      if (index === 0) {
        api.start({
          borderRadius: 0,
          rotation: 0,
          scale: 1,
          y: 0,
        });
        return;
      }

      api.start({
        y: 0,
      });
    },
    [api, index],
  );

  const bind = useDrag(
    ({ active, movement: [mx] }) => {
      if (!active) {
        if (mx < -150) {
          Promise.all(api.start({
            x: -600,
          }))
            .then(() => onSwipeLeft(ventureRecord))
            .catch(() => api.start({
              borderRadius: 0,
              dislikeOpacity: 0,
              likeOpacity: 0,
              rotation: 0,
              scale: 1,
              x: 0,
            }));
          return;
        }

        if (mx > 150) {
          if (!applicationStatus) {
            Promise.all(api.start({
              x: 600,
            }))
              .then(() => onSwipeRight(ventureRecord))
              .catch(() => api.start({
                borderRadius: 0,
                dislikeOpacity: 0,
                likeOpacity: 0,
                rotation: 0,
                scale: 1,
                x: 0,
              }));
            return;
          }
          api.start({
            borderRadius: 0,
            dislikeOpacity: 0,
            likeOpacity: 0,
            rotation: 0,
            scale: 1,
            x: 0,
          });
          return;
        }

        if (index === 0) {
          api.start({
            borderRadius: 0,
            dislikeOpacity: 0,
            likeOpacity: 0,
            rotation: 0,
            scale: 1,
            x: 0,
          });
          return;
        }

        api.start({
          borderRadius: 16,
          dislikeOpacity: 0,
          likeOpacity: 0,
          rotation: 0,
          scale: 0.9,
          x: 0,
        });
        return;
      }

      api.start({
        borderRadius: 16,
        dislikeOpacity: (mx < -50) ? Math.min(1, (Math.abs(mx) - 50) / 50) : 0,
        likeOpacity: (mx > 50) ? Math.min(1, (Math.abs(mx) - 50) / 50) : 0,
        rotation: mx / 50,
        scale: 0.9,
        x: mx,
      });
    },
    {
      axis: 'x',
      threshold: 20,
    },
  );

  const handleSwipeLeft = useCallback(
    () => {
      Promise.all(api.start({
        x: -600,
      }))
        .then(() => onSwipeLeft(ventureRecord))
        .catch(() => api.start({
          borderRadius: 0,
          dislikeOpacity: 0,
          likeOpacity: 0,
          rotation: 0,
          scale: 1,
          x: 0,
        }));
    },
    [api, onSwipeLeft, ventureRecord],
  );

  const handleSwipeRight = useCallback(
    () => {
      Promise.all(api.start({
        x: 600,
      }))
        .then(() => onSwipeRight(ventureRecord))
        .catch(() => api.start({
          borderRadius: 0,
          dislikeOpacity: 0,
          likeOpacity: 0,
          rotation: 0,
          scale: 1,
          x: 0,
        }));
    },
    [api, onSwipeRight, ventureRecord],
  );

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      as={animated.div}
      h={`${height}px`}
      // onClick={onExpandClick}
      overflow="hidden"
      position="absolute"
      style={{
        borderRadius,
        touchAction: 'none',
        transform: to([x, y, scale, rotation], (xx, yy, ss, rr) => `translate3d(${xx}px, ${yy}px, 0) scale(${ss}) rotate(${rr}deg)`),
      }}
      w={`${width}px`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...bind()}
    >
      <Venture
        applicationStatus={applicationStatus}
        height={height}
        onExit={onExit}
        onSwipeLeft={handleSwipeLeft}
        onSwipeRight={handleSwipeRight}
        overflow="hidden"
        queryId={queryId}
        searchResultPosition={searchResultPosition}
        ventureRecord={ventureRecord}
        width={width}
      />

      <Box
        as={animated.div}
        bottom={0}
        left={0}
        position="absolute"
        right={0}
        style={{
          display: to([likeOpacity], (o) => (o ? 'block' : 'none')),
          opacity: likeOpacity,
        }}
        top={0}
      >
        <Center height="100%" width="100%">
          <Icon as={LuHeart} boxSize={64} color="white" />
        </Center>
      </Box>

      <Box
        as={animated.div}
        bottom={0}
        left={0}
        position="absolute"
        right={0}
        style={{
          display: to([dislikeOpacity], (o) => (o ? 'block' : 'none')),
          opacity: dislikeOpacity,
        }}
        top={0}
      >
        <Center height="100%" width="100%">
          <Icon as={LuX} boxSize={64} color="white" />
        </Center>
      </Box>
    </Box>
  );
}
