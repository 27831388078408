import { QueryDocumentSnapshot } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext } from 'react';

import { ConversationDoc } from '../../collections/Conversations';

type Props = {
  conversationSnap: QueryDocumentSnapshot<ConversationDoc>;
};

const ConversationSnapContext = createContext<null | QueryDocumentSnapshot<ConversationDoc>>(null);

export const useConversationSnap = () => {
  const ctx = useContext(ConversationSnapContext);

  if (!ctx) {
    throw new Error('ConversationSnapProvider');
  }

  return ctx;
};

export default function ConversationSnapProvider({
  children,
  conversationSnap,
}: PropsWithChildren<Props>) {
  return (
    <ConversationSnapContext.Provider value={conversationSnap}>
      {children}
    </ConversationSnapContext.Provider>
  );
}
