import { Center, Grid, Text } from '@chakra-ui/react';
import {
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import VenueAlgoliaSearchRecord from '../../common/VenueAlgoliaSearchRecord';
import { useAlgoliaSearchClient } from '../../components/AlgoliaSearchClientProvider';
import Catch from '../../components/Catch';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import useShowError from '../../hooks/useShowError';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Venue, { VenueLoading } from './Venue';

export type Props = {
  query: string;
};

export function VenueListLoading() {
  return (
    <Grid gap={4} gridAutoRows="1fr" templateColumns="repeat(2, 1fr)">
      <VenueLoading />
      <VenueLoading />
      <VenueLoading />
      <VenueLoading />
    </Grid>
  );
}

export function VenueListMain({
  query,
}: Props) {
  const { t } = useTranslation('VentureCreateInitScreen', { keyPrefix: 'VenueList' });
  const showError = useShowError();

  const client = useAlgoliaSearchClient();
  const venuesIndex = useMemo(() => client.initIndex('venues'), [client]);

  const myProfileSnap = useMyProfileSnap();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [venues, setVenues] = useState<VenueAlgoliaSearchRecord[]>([]);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(
    () => {
      setIsLoading(true);
      venuesIndex.search<VenueAlgoliaSearchRecord>(query, {
        aroundLatLngViaIP: true,
        clickAnalytics: true,
        // enablePersonalization: true,
        length: 100,
        offset: 0,
        userToken: myProfileSnap.ref.id,
      }).finally(() => {
        setIsLoading(false);
      }).then((response) => {
        setVenues(response.hits);
        ref.current?.scrollTo({ top: 0 });
      }).catch(showError);
    },
    [myProfileSnap.ref.id, showError, query, venuesIndex],
  );

  if (venues.length) {
    return (
      <Grid gap={4} gridAutoRows="1fr" templateColumns="repeat(2, 1fr)">
        {venues.map((venueRecord) => (
          <Venue
            key={venueRecord.objectID}
            venueRecord={venueRecord}
          />
        ))}
      </Grid>
    );
  }

  if (isLoading) {
    return (
      <VenueListLoading />
    );
  }

  return (
    <Center height="100%">
      <Text textAlign="center">
        {t('emptyList.body')}
      </Text>
    </Center>
  );
}

export default function VenueList(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<VenueListLoading />}>
        <VenueListMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
