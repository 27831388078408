import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AspectRatio,
  Box,
  Button,
  Card,
  Center,
  Grid,
  HStack,
  Icon,
  IconButton,
  Image,
  Portal,
  Skeleton,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  doc,
  getCountFromServer,
  orderBy,
  query,
  QueryDocumentSnapshot,
  setDoc,
  Timestamp,
  where,
  writeBatch,
} from 'firebase/firestore';
import { take } from 'lodash';
import moment from 'moment';
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  LuCalendarX,
  LuCreditCard,
  LuHeart,
  LuUser,
} from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { useFirestore, useFirestoreCollection, useFirestoreDoc } from 'reactfire';

import {
  ApplicationDoc,
  ApplicationRejectedBy,
  ApplicationStatus,
  useApplicationsCollectionRef,
} from '../../collections/Applications';
import {
  ConversationStatus,
  useConversationsCollectionRef,
} from '../../collections/Conversations';
import { TripStatus } from '../../collections/Trips';
import AppLanguage from '../../common/AppLanguage';
import { getPhotoSizeUrl } from '../../common/getPhotoSizeUrl';
import ApplicationCardWrapper from '../../components/ApplicationCardWrapper';
import Catch from '../../components/Catch';
import { useTripSnap } from '../../components/snapProviders/TripSnapProvider';
import useBlur from '../../hooks/useBlur';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Application from './Application';

export function TripMain() {
  const tripSnap = useTripSnap();
  const tripDoc = useMemo(() => tripSnap.data(), [tripSnap]);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  const [unpublishing, setUnpublishing] = useState(false);
  const handleUnpublishClick = useCallback(
    () => {
      setUnpublishing(true);
      setDoc(
        tripSnap.ref,
        {
          status: TripStatus.UNPUBLISHED,
          unpublishedAt: Timestamp.now(),
        },
        { merge: true },
      )
        .finally(() => setUnpublishing(false))
        .then(onClose)
        .catch(() => { });
    },
    [onClose, tripSnap.ref],
  );

  const { i18n, t } = useTranslation('MyTripsScreen', { keyPrefix: 'Trip' });

  const { data: destinationSnap } = useFirestoreDoc(tripDoc.destinationRef);

  if (!destinationSnap.exists()) {
    throw new Error('Destination does not exist');
  }

  const destinationDoc = useMemo(() => destinationSnap.data(), [destinationSnap]);

  const [applicationsCount, setApplicationsCount] = useState<number>();

  const applicationsCollectionRef = useApplicationsCollectionRef();

  useEffect(
    () => {
      getCountFromServer(
        query(
          applicationsCollectionRef,
          where('tripRef', '==', tripSnap.ref),
        ),
      ).then((countSnap) => setApplicationsCount(countSnap.data().count)).catch(() => { });
    },
    [applicationsCollectionRef, tripSnap.ref],
  );

  const { data: applicationsSnap } = useFirestoreCollection(
    query(
      applicationsCollectionRef,
      where('tripRef', '==', tripSnap.ref),
      where('status', '==', ApplicationStatus.SENT),
      orderBy('sentAt', 'asc'),
    ),
  );

  const src = useMemo(
    () => getPhotoSizeUrl({
      height: 40,
      uri: destinationDoc.picture.urls.raw,
      width: 40,
    }),
    [destinationDoc.picture.urls.raw],
  );

  const fallbackSrc = useBlur({
    blurHash: destinationDoc.picture.blur_hash,
    height: 32,
    width: 18,
  });

  const placeholderApplicationsCount = useMemo(
    () => 4 - Math.min(applicationsSnap.docs.length, 4),
    [applicationsSnap.docs.length],
  );

  const visibleApplications = useMemo(
    () => take(applicationsSnap.docs, applicationsSnap.docs.length > 4 ? 3 : 4),
    [applicationsSnap.docs],
  );

  const extraApplicationsCount = useMemo(
    () => applicationsSnap.docs.length - visibleApplications.length,
    [applicationsSnap.docs.length, visibleApplications.length],
  );

  const [fullscreen, setFullscreen] = useState(false);

  const fullscreenApplications = useMemo(
    () => (fullscreen ? take(applicationsSnap.docs, 5) : []),
    [applicationsSnap.docs, fullscreen],
  );

  const { height: wHeight, width: wWidth } = useWindowDimensions();

  const handleOpenFullscreen = useCallback(
    () => { setFullscreen(applicationsSnap.docs.length > 0); },
    [applicationsSnap.docs.length],
  );

  const handleExit = useCallback(
    () => { setFullscreen(false); },
    [],
  );

  const handleSwipeLeft = useCallback(
    async (applicationSnap: QueryDocumentSnapshot<ApplicationDoc>) => {
      await setDoc(
        applicationSnap.ref,
        {
          rejectedAt: Timestamp.now(),
          rejectedBy: ApplicationRejectedBy.ORGANIZER,
          status: ApplicationStatus.REJECTED,
        },
        { merge: true },
      );

      if (applicationsSnap.docs.length <= 1) {
        setFullscreen(false);
      }
    },
    [applicationsSnap.docs.length],
  );

  const firestore = useFirestore();
  const conversationsCollectionRef = useConversationsCollectionRef();
  const handleSwipeRight = useCallback(
    async (applicationSnap: QueryDocumentSnapshot<ApplicationDoc>) => {
      const applicationDoc = applicationSnap.data();

      const batch = writeBatch(firestore);

      const now = Date.now();

      const conversationRef = doc(conversationsCollectionRef);

      batch.set(
        applicationSnap.ref,
        {
          acceptedAt: Timestamp.now(),
          conversationRef,
          status: ApplicationStatus.ACCEPTED,
        },
        { merge: true },
      );

      batch.set(
        conversationRef,
        {
          _v: 1,
          expiresAt: Timestamp.fromMillis(now + 1000 * 60 * 60 * 24 * 365),
          lastActionAt: Timestamp.fromMillis(now),
          notReadByRefs: [applicationDoc.applicantRef, applicationDoc.organizerRef],
          openedAt: Timestamp.fromMillis(now),
          participantRefs: [applicationDoc.applicantRef, applicationDoc.organizerRef],
          readByRefs: [],
          reservationRef: applicationDoc.reservationRef,
          status: ConversationStatus.OPENED,
          tripRef: applicationDoc.tripRef,
          typingParticipantRefs: [],
          ventureRef: applicationDoc.ventureRef,
        },
      );

      await batch.commit();

      if (applicationsSnap.docs.length <= 1) {
        setFullscreen(false);
      }
    },
    [
      applicationsSnap.docs.length,
      conversationsCollectionRef,
      firestore,
    ],
  );

  return (
    <VStack alignItems="stretch" gap={2}>
      <HStack gap={2}>
        <Image
          borderRadius="md"
          fallbackSrc={fallbackSrc}
          h={10}
          objectFit="cover"
          src={src}
          w={10}
        />

        <VStack alignItems="stretch" flex={1} gap={0}>
          <HStack>
            <Text
              flex={1}
              lineHeight="short"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {destinationDoc.name[i18n.language as AppLanguage]}
            </Text>

            <HStack flexShrink={0} gap={1}>
              <Icon as={LuHeart} />

              {typeof applicationsCount === 'number' ? (
                <Text lineHeight="short">
                  {applicationsCount}
                </Text>
              ) : (
                <Skeleton h={4} w={3} />
              )}

            </HStack>
          </HStack>

          <HStack gap={1}>
            <Text
              flexShrink={0}
              fontSize="sm"
              lineHeight="short"
            >
              {destinationDoc.countryEmoji}
            </Text>

            <Text
              flex={1}
              fontSize="sm"
              lineHeight="short"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {destinationDoc.countryName[i18n.language as AppLanguage]}
            </Text>

            <Text
              flexShrink={0}
              fontSize="sm"
              lineHeight="short"
            >
              {t('departure', {
                departure: moment(tripDoc.departure).toDate(),
                formatParams: { departure: { dateStyle: 'short' } },
              })}
            </Text>
          </HStack>
        </VStack>

        {tripDoc.status === TripStatus.CREATED ? (
          <IconButton
            aria-label={t('payButton.default')}
            as={Link}
            colorScheme="green"
            icon={<Icon as={LuCreditCard} />}
            to={`/trips/${tripSnap.id}/payment`}
            variant="outline"
          />
        ) : null}

        {tripDoc.status === TripStatus.EXPIRED ? (
          <IconButton
            aria-label={t('payButton.default')}
            icon={<Icon as={LuCreditCard} />}
            isDisabled
            variant="outline"
          />
        ) : null}

        {tripDoc.status === TripStatus.PUBLISHED ? (
          <>
            <IconButton
              aria-label={t('unpublishButton.default')}
              colorScheme="red"
              icon={<Icon as={LuCalendarX} />}
              onClick={onOpen}
              variant="outline"
            />

            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
            >
              <AlertDialogOverlay
                backdropFilter="saturate(180%) blur(20px)"
                backgroundColor="rgb(from var(--chakra-colors-chakra-body-bg) r g b / 0.5)"
              />

              <AlertDialogContent
                bg="chakra-body-bg"
                mx={4}
              >
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  {t('unpublishAlertModal.title')}
                </AlertDialogHeader>

                <AlertDialogBody>
                  {t('unpublishAlertModal.body')}
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button onClick={onClose} ref={cancelRef} variant="ghost">
                    {t('unpublishAlertModal.cancelButton.default')}
                  </Button>

                  <Button
                    colorScheme="red"
                    isLoading={unpublishing}
                    loadingText={t('unpublishAlertModal.confirmButton.loading')}
                    ml={3}
                    onClick={handleUnpublishClick}
                  >
                    {t('unpublishAlertModal.confirmButton.default')}
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </>
        ) : null}

        {tripDoc.status === TripStatus.UNPUBLISHED ? (
          <IconButton
            aria-label={t('unpublishButton.default')}
            icon={<Icon as={LuCalendarX} />}
            isDisabled
            variant="outline"
          />
        ) : null}
      </HStack>

      {tripDoc.status === TripStatus.PUBLISHED || visibleApplications.length ? (
        <Grid autoRows="1fr" gap={2} onClick={handleOpenFullscreen} templateColumns="repeat(4, 1fr)">
          {visibleApplications.map((applicationSnap) => (
            <Application applicationSnap={applicationSnap} key={applicationSnap.id} />
          ))}

          {(extraApplicationsCount > 0) ? (
            <AspectRatio ratio={9 / 16}>
              <Card>
                <Center h="100%" w="100%">
                  <HStack gap={1}>
                    <Icon as={LuUser} />

                    <Text>
                      {extraApplicationsCount}
                    </Text>
                  </HStack>
                </Center>
              </Card>
            </AspectRatio>
          ) : null}

          {new Array(placeholderApplicationsCount).fill(0).map((a, i) => (
          // eslint-disable-next-line react/no-array-index-key
            <AspectRatio key={i} ratio={9 / 16}>
              <Box borderColor="var(--chakra-colors-chakra-body-text)" borderRadius="md" borderStyle="dashed" borderWidth={1} />
            </AspectRatio>
          ))}
        </Grid>
      ) : null}

      <Portal>
        {fullscreenApplications.map((applicationSnap, i) => (
          <ApplicationCardWrapper
            applicationSnap={applicationSnap}
            height={wHeight}
            index={i}
            key={applicationSnap.id}
            onExit={handleExit}
            onSwipeLeft={handleSwipeLeft}
            onSwipeRight={handleSwipeRight}
            top={0}
            width={wWidth}
            zIndex={(visibleApplications.length - i) * 10 + 100}
          />
        ))}
      </Portal>
    </VStack>
  );
}

export function TripLoading() {
  return null;
}

export default function Trip() {
  return (
    <Catch fallback={null}>
      <Suspense fallback={<TripLoading />}>
        <TripMain />
      </Suspense>
    </Catch>
  );
}
