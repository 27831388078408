import {
  Button,
  Center,
  Container,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { signOut } from 'firebase/auth';
import { QueryDocumentSnapshot, setDoc, Timestamp } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LuLogOut } from 'react-icons/lu';
import { useAuth } from 'reactfire';

import { ProfileVersionDoc, ProfileVersionStatus } from '../../collections/Profiles';
import { useInsets } from '../../components/InsetsProvider';
import LogoFull from '../../components/LogoFull';
import useShowError from '../../hooks/useShowError';

export type Props = {
  lastProfileVersionSnap: QueryDocumentSnapshot<ProfileVersionDoc>;
};

export default function ProfileVersionReceived({ lastProfileVersionSnap }: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileVersionReceived' });

  const lastProfileVersionDoc = useMemo(
    () => lastProfileVersionSnap.data(),
    [lastProfileVersionSnap],
  );

  const showError = useShowError();
  const [isWithdrawing, setWithdrawing] = useState<boolean>(false);
  const handleWithdrawClick = useCallback(
    () => {
      setWithdrawing(true);
      setDoc(lastProfileVersionSnap.ref, {
        status: ProfileVersionStatus.WITHDRAWN,
        withdrawnAt: Timestamp.now(),
      }, { merge: true })
        .finally(() => setWithdrawing(false))
        .catch(showError);
    },
    [lastProfileVersionSnap.ref, showError],
  );

  const insets = useInsets();

  const auth = useAuth();

  const handleLogOut = useCallback(
    () => {
      signOut(auth)
        .then(() => mixpanel.reset())
        .catch(showError);
    },
    [auth, showError],
  );

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        flexShrink={0}
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-4))`}
      >
        <Center>
          <LogoFull h={16} w={48} />
        </Center>
      </Container>

      <Container
        flex={1}
        maxW="lg"
        minH={0}
        overflow="auto"
        py={2}
      >
        <VStack
          alignItems="stretch"
          gap={4}
          h="100%"
          justifyContent="center"
        >
          <Text fontSize="xl" fontWeight="bold" textAlign="center">
            {t('thankYou', { name: lastProfileVersionDoc.name })}
          </Text>

          <Text textAlign="center">
            {t('confirmation')}
          </Text>

          <Text fontSize="sm" textAlign="center">
            <Trans
              components={[
                <Text as="a" fontWeight="bold" href={`https://instagram.com/${lastProfileVersionDoc.instagramTag}`} target="_blank">{`@${lastProfileVersionDoc.instagramTag}`}</Text>,
                <Text as="a" fontWeight="bold" href="https://instagram.com/qupidu.travel" target="_blank">@qupidu.travel</Text>,
              ]}
              i18nKey="process"
              t={t}
            />
          </Text>
        </VStack>
      </Container>

      <Container
        maxW="lg"
        pb={`max(${insets.bottom}, var(--chakra-space-2))`}
        pt={2}
      >
        <HStack gap={2}>
          <IconButton
            aria-label={t('logoutButton.loading')}
            colorScheme="red"
            icon={<Icon as={LuLogOut} />}
            onClick={handleLogOut}
          />

          <Button
            flex={1}
            isLoading={isWithdrawing}
            loadingText={t('withdrawButton.loading')}
            onClick={handleWithdrawClick}
          >
            {t('withdrawButton.default')}
          </Button>
        </HStack>
      </Container>
    </VStack>
  );
}
