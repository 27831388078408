import { QuerySnapshot } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext } from 'react';

import { ApplicationDoc } from '../../collections/Applications';

type Props = {
  applicationsSnap: QuerySnapshot<ApplicationDoc>;
};

const MyApplicationsSnapContext = createContext<null | QuerySnapshot<ApplicationDoc>>(null);

export const useMyApplicationsSnap = () => {
  const ctx = useContext(MyApplicationsSnapContext);

  if (!ctx) {
    throw new Error('MyApplicationsSnapProviderProvider');
  }

  return ctx;
};

export default function MyApplicationsSnapProvider({
  applicationsSnap,
  children,
}: PropsWithChildren<Props>) {
  return (
    <MyApplicationsSnapContext.Provider value={applicationsSnap}>
      {children}
    </MyApplicationsSnapContext.Provider>
  );
}
