import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export type Data = {
  returnUrl: string;
};

export type Result = string;

export default function useStripeCreateBillingPortalSession() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'stripe-createBillingPortalSession'), [functions]);
}
