import {
  Box,
  BoxProps,
  Container,
  Table,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestoreDoc } from 'reactfire';

import { ApplicationDoc } from '../../collections/Applications';
import { isProfileComplete, ProfileDoc } from '../../collections/Profiles';
import { cupSizeLabels } from '../../common/CupSize';
import { hairLengthLabels } from '../../common/HairLength';
import { pantiesSizeLabels } from '../../common/PantiesSize';

export type Props = {
  applicantSnap: QueryDocumentSnapshot<ProfileDoc>;
  applicationSnap: QueryDocumentSnapshot<ApplicationDoc>;
} & BoxProps;

export default function InfoCell({
  applicantSnap,
  applicationSnap,
  ...boxProps
}: Props) {
  const { t: lt } = useTranslation('Languages');
  const { t: zt } = useTranslation('Zodiac');
  const { t: cst } = useTranslation('CupSize');
  const { t: pst } = useTranslation('PantiesSize');
  const { t: ect } = useTranslation('EyeColor');
  const { t: hct } = useTranslation('HairColor');
  const { t: hlt } = useTranslation('HairLength');
  const { t: rst } = useTranslation('RelationshipStyle');
  const { t: st } = useTranslation('Sexuality');

  const applicantDoc = useMemo(
    () => applicantSnap.data(),
    [applicantSnap],
  );

  if (!isProfileComplete(applicantDoc)) {
    throw new Error('Applicant profile is not complete');
  }

  const { data: originSnap } = useFirestoreDoc(applicantDoc.originRef);

  if (!originSnap.exists()) {
    throw new Error('Origin not found');
  }

  const originDoc = useMemo(
    () => originSnap.data(),
    [originSnap],
  );

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      className="tripInfo"
    >
      <Container
        h="100%"
        maxW="lg"
        pb="calc(max(env(safe-area-inset-bottom), var(--chakra-space-4)) + var(--chakra-space-12) + var(--chakra-space-4))"
        pr={12}
        pt="calc(max(env(safe-area-inset-top), var(--chakra-space-4)) + var(--chakra-space-12) + var(--chakra-space-4))"
      >
        <Table size="sm" variant="simple">
          <Tbody>
            <Tr>
              <Td>
                Name
              </Td>
              <Td>
                {applicantDoc.name}
              </Td>
            </Tr>
            <Tr>
              <Td>
                Age
              </Td>
              <Td>
                {applicantDoc.age}
                y
              </Td>
            </Tr>
            <Tr>
              <Td>
                Height
              </Td>
              <Td>
                {applicantDoc.height}
                cm
              </Td>
            </Tr>
            <Tr>
              <Td>
                Weight
              </Td>
              <Td>
                {applicantDoc.weight}
                kg
              </Td>
            </Tr>
            <Tr>
              <Td>
                Bikini
              </Td>
              <Td>
                {applicantDoc.cupSize ? cst(`${cupSizeLabels[applicantDoc.cupSize]}.label`) : '-'}
                {' / '}
                {applicantDoc.pantiesSize ? pst(`${pantiesSizeLabels[applicantDoc.pantiesSize]}.label`) : '-'}
              </Td>
            </Tr>
            <Tr>
              <Td>
                Eyes
              </Td>
              <Td>
                {applicantDoc.eyeColor ? ect(`${applicantDoc.eyeColor}.label`) : '-'}
              </Td>
            </Tr>
            <Tr>
              <Td>
                Hair
              </Td>
              <Td>
                {applicantDoc.hairColor ? hct(`${applicantDoc.hairColor}.label`) : '-'}
                {' / '}
                {applicantDoc.hairLength ? hlt(`${hairLengthLabels[applicantDoc.hairLength]}.label`) : '-'}
              </Td>
            </Tr>
            <Tr>
              <Td>
                Zodiac
              </Td>
              <Td>
                {zt(applicantDoc.zodiac)}
              </Td>
            </Tr>
            <Tr>
              <Td>
                Languages
              </Td>
              <Td whiteSpace="normal">
                {applicantDoc.languages.map((l) => lt(l)).join(', ')}
              </Td>
            </Tr>
            <Tr>
              <Td>
                Relationship Style
              </Td>
              <Td>
                {applicantDoc.relationshipStyle ? rst(`${applicantDoc.relationshipStyle}.label`) : '-'}
              </Td>
            </Tr>
            <Tr>
              <Td>
                Sexuality
              </Td>
              <Td>
                {applicantDoc.sexuality ? st(`${applicantDoc.sexuality}`) : '-'}
              </Td>
            </Tr>
            <Tr>
              <Td>
                Origin
              </Td>
              <Td whiteSpace="normal">
                {_.compact([
                  originDoc.name,
                  originDoc.cityName,
                  originDoc.countryName,
                ]).join(', ')}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Container>
    </Box>
  );
}
