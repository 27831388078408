import {
  collection,
  CollectionReference,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { isNil } from 'lodash';
import { useFirestore } from 'reactfire';

import AppLanguage from '../common/AppLanguage';
import CupSize from '../common/CupSize';
import EyeColor from '../common/EyeColor';
import Gender from '../common/Gender';
import HairColor from '../common/HairColor';
import HairLength from '../common/HairLength';
import Language from '../common/Language';
import PantiesSize from '../common/PantiesSize';
import RelationshipStyle from '../common/RelationshipStyle';
import Sexuality from '../common/Sexuality';
import { StoragePicture } from '../common/StoragePicture';
import Tier from '../common/Tier';
import Wealth from '../common/Wealth';
import Zodiac from '../common/Zodiac';
import { type AirportDoc } from './Airports';
import { type TripDoc } from './Trips';

export enum ProfileStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export interface ProfileDoc {
  _v: 1;
  age?: number;
  bannedAt?: Timestamp;
  createdAt: Timestamp;
  cupSize?: CupSize;
  expiresAt: Timestamp;
  eyeColor?: EyeColor;
  gender: Gender;
  h3Cell1?: string;
  h3Cell2?: string;
  h3Cell3?: string;
  h3Cell4?: string;
  h3Cell5?: string;
  h3Cell6?: string;
  h3Cell7?: string;
  hairColor?: HairColor;
  hairLength?: HairLength;
  height?: number;
  indexedAt?: Timestamp;
  instagramTag?: string;
  isBanned?: boolean;
  languages?: Language[];
  lastNotificationAt: Timestamp;
  lastSeenAt: Timestamp;
  linkedinTag?: string;
  name?: string;
  originRef?: DocumentReference<AirportDoc>;
  pantiesSize?: PantiesSize;
  pictures?: StoragePicture[];
  preferences?: {
    age?: {
      max: number;
      min: number;
    };
    height?: {
      max: number;
      min: number;
    };
    tier?: {
      max: Tier;
      min: Tier;
    };
    weight?: {
      max: number;
      min: number;
    };
  };
  primaryPictureIndex?: number;
  relationshipStyle?: RelationshipStyle;
  score: number;
  scoredAt: Timestamp;
  sexuality: Sexuality;
  status: ProfileStatus;
  stripeAccountId?: string;
  stripePromotionCode?: string;
  tiktokTag?: string;
  translations?: {
    name: Record<AppLanguage, string>;
  };
  updatedAt: Timestamp;
  wealth?: Wealth;
  weight?: number;
  zodiac?: Zodiac;
}

export type ProfileCompleteDoc = { pictures: [StoragePicture, ...StoragePicture[]] } & ProfileDoc & Required<Pick<ProfileDoc, 'age' | 'height' | 'languages' | 'name' | 'originRef' | 'weight' | 'zodiac'>>;

export const isProfileComplete = (profileDoc: ProfileDoc): profileDoc is ProfileCompleteDoc => {
  if (isNil(profileDoc.age)) {
    return false;
  }

  if (isNil(profileDoc.height)) {
    return false;
  }

  if (isNil(profileDoc.languages)) {
    return false;
  }

  if (isNil(profileDoc.name)) {
    return false;
  }

  if (isNil(profileDoc.originRef)) {
    return false;
  }

  if (isNil(profileDoc.pictures) || !profileDoc.pictures.length) {
    return false;
  }

  if (isNil(profileDoc.weight)) {
    return false;
  }

  if (isNil(profileDoc.zodiac)) {
    return false;
  }

  return true;
};

export const isProfileDoc = (
  data?: DocumentData,
): data is ProfileDoc => true;

export const isProfileRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ProfileDoc> => ref.parent.id === 'profiles';

function isProfileSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<ProfileDoc>;
function isProfileSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<ProfileDoc>;
function isProfileSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<ProfileDoc> | QueryDocumentSnapshot<ProfileDoc> {
  return isProfileRef(snap.ref);
}

export { isProfileSnap };

export const getProfilesCollectionRef = (firestore: Firestore) => collection(firestore, 'profiles') as CollectionReference<ProfileDoc>;

export const useProfilesCollectionRef = () => {
  const firestore = useFirestore();
  return getProfilesCollectionRef(firestore);
};

export interface ProfileHiddenDoc {
  _v: 1;
  appLanguage?: AppLanguage;
  birthDate?: string;
  fcmTokens?: string[];
  profileDeck?: DocumentReference<ProfileDoc>[];
  profileSuggestions?: string[];
  timezone?: string;
  tripDeck?: DocumentReference<TripDoc>[];
}

export const isProfileHiddenDoc = (
  data?: DocumentData,
): data is ProfileHiddenDoc => true;

export const isProfileHiddenRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ProfileHiddenDoc> => ref.id === 'internal' && ref.parent.id === 'hidden' && ref.parent.parent !== null && isProfileRef(ref.parent.parent);

function isProfileHiddenSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<ProfileHiddenDoc>;
function isProfileHiddenSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<ProfileHiddenDoc>;
function isProfileHiddenSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<ProfileHiddenDoc> | QueryDocumentSnapshot<ProfileHiddenDoc> {
  return isProfileHiddenRef(snap.ref);
}

export { isProfileHiddenSnap };

export const getProfileHiddenRef = (profileRef: DocumentReference<ProfileDoc>) => doc(collection(profileRef, 'internal'), 'hidden') as DocumentReference<ProfileHiddenDoc>;

export enum ProfileVersionStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  SENT = 'SENT',
  WITHDRAWN = 'WITHDRAWN',
}

export type ProfileVersionDoc = {
  _v: 1;
  approvedAt?: Timestamp;
  instagramTag: string;
  rejectedAt?: Timestamp;
  rejectionReasons?: string[];
  sentAt: Timestamp;
  status: ProfileVersionStatus;
  withdrawnAt?: Timestamp;
} & Pick<ProfileCompleteDoc, 'cupSize' | 'eyeColor' | 'gender' | 'hairColor' | 'hairLength' | 'height' | 'languages' | 'linkedinTag' | 'name' | 'originRef' | 'pantiesSize' | 'pictures' | 'relationshipStyle' | 'sexuality' | 'tiktokTag' | 'wealth' | 'weight'> & Pick<ProfileHiddenDoc, 'birthDate'>;

export const isProfileVersionDoc = (
  data?: DocumentData,
): data is ProfileVersionDoc => true;

export const isProfileVersionRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ProfileVersionDoc> => ref.parent.id === 'profileVersions' && ref.parent.parent !== null && isProfileRef(ref.parent.parent);

function isProfileVersionSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<ProfileVersionDoc>;
function isProfileVersionSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<ProfileVersionDoc>;
function isProfileVersionSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<ProfileVersionDoc> | QueryDocumentSnapshot<ProfileVersionDoc> {
  return isProfileVersionRef(snap.ref);
}

export { isProfileVersionSnap };

export const getProfileVersionsCollectionRef = (profileRef: DocumentReference<ProfileDoc>) => collection(profileRef, 'profileVersions') as CollectionReference<ProfileVersionDoc>;
