import { Avatar, Icon } from '@chakra-ui/react';
import _ from 'lodash';
import { Suspense, useMemo } from 'react';
import { LuUser } from 'react-icons/lu';

import { getPhotoSizeUrl } from '../../common/getPhotoSizeUrl';
import Catch from '../../components/Catch';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';

function ProfileButtonMain() {
  const myProfileSnap = useMyProfileSnap();
  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  const src = useMemo(
    () => (myProfileDoc?.pictures?.length ? getPhotoSizeUrl({
      height: 48 * 3,
      uri: (_.find(myProfileDoc.pictures, 'primary') ?? myProfileDoc.pictures[0]).imgixUrl,
      width: 48 * 3,
    }) : undefined),
    [myProfileDoc],
  );

  return (
    <Avatar
      boxSize={6}
      icon={<Icon as={LuUser} />}
      src={src}
    />
  );
}

export default function ProfileButton() {
  return (
    <Catch fallback={<Icon as={LuUser} boxSize={6} />}>
      <Suspense fallback={<Icon as={LuUser} boxSize={6} />}>
        <ProfileButtonMain />
      </Suspense>
    </Catch>
  );
}
