import {
  BoxProps,
  HStack,
  Icon,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  doc,
  getCountFromServer,
  query,
  where,
} from 'firebase/firestore';
import {
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LuVenetianMask } from 'react-icons/lu';

import { useVenturesCollectionRef } from '../../../collections/Ventures';
import { useVenuesCollectionRef } from '../../../collections/Venues';
import VenueAlgoliaSearchRecord from '../../../common/VenueAlgoliaSearchRecord';
import Catch from '../../../components/Catch';
import TierIcon from '../../../components/TierIcon';

export type Props = {
  venueRecord: VenueAlgoliaSearchRecord;
} & BoxProps;

export function VenueDetailsMain({ venueRecord, ...boxProps }: Props) {
  const venuesCollectionRef = useVenuesCollectionRef();
  const venueRef = useMemo(
    () => doc(venuesCollectionRef, venueRecord.objectID),
    [venuesCollectionRef, venueRecord.objectID],
  );

  const venturesCollectionRef = useVenturesCollectionRef();
  const [venturesCount, setVenturesCount] = useState<number>();
  useEffect(
    () => {
      getCountFromServer(
        query(
          venturesCollectionRef,
          where('venueRef', '==', venueRef),
        ),
      ).then((countSnap) => setVenturesCount(countSnap.data().count)).catch(() => { });
    },
    [venueRef, venturesCollectionRef],
  );

  return (
    <VStack
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      alignItems="stretch"
      gap={2}
      p={2}
    >
      <VStack alignItems="stretch" gap={0}>
        <HStack>
          <Text
            flex={1}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {venueRecord.name}
          </Text>

          <TierIcon tier={venueRecord.tier} />
        </HStack>

        <HStack gap={1}>
          <Text
            flex={1}
            fontSize="sm"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {venueRecord.city?.name}
            {', '}
            {venueRecord.country?.name}
          </Text>

          <HStack flexShrink={0} gap={1}>
            {typeof venturesCount === 'number' ? (
              <Text>
                {venturesCount}
              </Text>
            ) : (
              <Skeleton h={4} w={3} />
            )}

            <Text>
              &times;
            </Text>

            <Icon as={LuVenetianMask} />
          </HStack>
        </HStack>
      </VStack>
    </VStack>
  );
}

export default function VenueDetails(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <VenueDetailsMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
