import { useMemo } from 'react';

import Gender from '../../common/Gender';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import MyReservationsScreen from '../MyReservationsScreen';
import TheirReservationsScreen from '../TheirReservationsScreen';

export default function ReservationsScreen() {
  const myProfileSnap = useMyProfileSnap();
  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  switch (myProfileDoc.gender) {
    case Gender.FEMALE: {
      return (
        <TheirReservationsScreen />
      );
    }
    case Gender.MALE: {
      return (
        <MyReservationsScreen />
      );
    }
    default: {
      throw new Error('Unknown Gender');
    }
  }
}
