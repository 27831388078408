import { QueryDocumentSnapshot } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext } from 'react';

import { ProfileDoc } from '../../collections/Profiles';

type Props = {
  profileSnap: QueryDocumentSnapshot<ProfileDoc>;
};

const MyProfileSnapContext = createContext<null | QueryDocumentSnapshot<ProfileDoc>>(null);

export const useMyProfileSnap = () => {
  const ctx = useContext(MyProfileSnapContext);

  if (!ctx) {
    throw new Error('MyProfileSnapProvider');
  }

  return ctx;
};

export default function MyProfileSnapProvider({
  children,
  profileSnap,
}: PropsWithChildren<Props>) {
  return (
    <MyProfileSnapContext.Provider value={profileSnap}>
      {children}
    </MyProfileSnapContext.Provider>
  );
}
