import { IconButton, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link, useMatch } from 'react-router-dom';

import ProfileAvatar from './ProfileAvatar';

export default function ProfileButton() {
  const { t } = useTranslation('MainLayout');

  const match = useMatch('/profile/*');

  return (
    <VStack alignItems="center" spacing={0}>
      <IconButton
        aria-label={t('profileButton.default')}
        as={Link}
        className="profileButton"
        icon={<ProfileAvatar />}
        isActive={!!match}
        isRound
        size="lg"
        to="/profile"
        variant="ghost"
      />
    </VStack>
  );
}
