import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export interface Data {
  imageUrls: string[];
}

export type Result = Record<string, boolean>;

export default function useValidateFemalePictures() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'validateFemalePictures'), [functions]);
}
