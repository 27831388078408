import {
  Box,
  Center,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
} from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { Suspense, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LuChevronLeft } from 'react-icons/lu';
import { Link, useParams } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import { useConversationsCollectionRef } from '../../collections/Conversations';
import Catch from '../../components/Catch';
import InsetsProvider, { useInsets } from '../../components/InsetsProvider';
import LogoIcon from '../../components/LogoIcon';
import ConversationSnapProvider from '../../components/snapProviders/ConversationSnapProvider';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import useDimensions from '../../hooks/useDimensions';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import ProfileList from './ProfileList';

export function ConversationParticipantsScreenMain() {
  useEffect(() => { mixpanel.track_pageview({ screen: 'ConversationParticipantsScreen' }); }, []);

  const { t } = useTranslation('ConversationParticipantsScreen');

  const insets = useInsets();

  const { conversationId } = useParams<{ conversationId: string }>();

  const conversationsCollectionRef = useConversationsCollectionRef();
  const conversationRef = useMemo(
    () => doc(conversationsCollectionRef, conversationId),
    [conversationId, conversationsCollectionRef],
  );
  const { data: conversationSnap } = useFirestoreDoc(conversationRef);

  if (!conversationSnap.exists()) {
    throw new Error('Conversation not found');
  }

  const conversationDoc = useMemo(() => conversationSnap.data(), [conversationSnap]);

  const myProfileSnap = useMyProfileSnap();

  const participantRefs = useMemo(
    () => _.filter(
      conversationDoc.participantRefs,
      (r) => r.id !== myProfileSnap.ref.id,
    ),
    [conversationDoc.participantRefs, myProfileSnap.ref.id],
  );

  const [ref, { height, width }] = useDimensions();

  return (
    <ConversationSnapProvider conversationSnap={conversationSnap}>
      <Box
        h="100%"
        position="relative"
      >
        <Box
          left={0}
          position="absolute"
          right={0}
          top={`max(${insets.top}, var(--chakra-space-2))`}
          zIndex={50}
        >
          <Container maxW="lg">
            <HStack gap={4}>
              <IconButton
                aria-label={t('backButton.default')}
                as={Link}
                icon={<Icon as={LuChevronLeft} />}
                to=".."
              />

              <Heading
                flex={1}
                fontSize="4xl"
                lineHeight="40px"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {t('title')}
              </Heading>
            </HStack>
          </Container>
        </Box>

        <InsetsProvider
          bottom={insets.bottom}
          left={insets.left}
          right={insets.right}
          top={`calc(max(${insets.top}, var(--chakra-space-2)) + var(--chakra-space-10))`}
        >
          <Container h="100%" maxW="lg" px={0} ref={ref}>
            {height && width ? (
              <ProfileList
                height={height}
                profileRefs={participantRefs}
                width={width}
              />
            ) : null}
          </Container>
        </InsetsProvider>
      </Box>
    </ConversationSnapProvider>
  );
}

export default function ConversationParticipantsScreen() {
  const { t } = useTranslation('ConversationParticipantsScreen');

  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <Helmet>
          <title>{t('title')}</title>
        </Helmet>
        <ConversationParticipantsScreenMain />
      </Suspense>
    </Catch>
  );
}
