import { Box, Container, VStack } from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import { Suspense, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import { useConversationsCollectionRef } from '../../collections/Conversations';
import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import ConversationSnapProvider from '../../components/snapProviders/ConversationSnapProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import ChatHeader, { ChatHeaderLoading } from './ChatHeader';
import MessageInput, { MessageInputLoading } from './MessageInput';
import MessageList, { MessageListLoading } from './MessageList';
import TripContext from './TripContext';
import TypingParticipants from './TypingParticipants';

export function ChatScreenMain() {
  useEffect(() => { mixpanel.track_pageview({ screen: 'ChatScreen' }); }, []);

  const { t } = useTranslation('ChatScreen');

  const insets = useInsets();

  const { conversationId } = useParams<{ conversationId: string }>();

  const conversationsCollectionRef = useConversationsCollectionRef();
  const conversationRef = useMemo(
    () => doc(conversationsCollectionRef, conversationId),
    [conversationId, conversationsCollectionRef],
  );

  const { data: conversationSnap } = useFirestoreDoc(conversationRef);

  if (!conversationSnap.exists()) {
    throw new Error('Conversation not found');
  }

  const conversationDoc = useMemo(() => conversationSnap.data(), [conversationSnap]);

  return (
    <ConversationSnapProvider conversationSnap={conversationSnap}>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <VStack alignItems="stretch" gap={0} h="100%">
        <Container
          maxW="lg"
          pb={2}
          pt={`max(${insets.top}, var(--chakra-space-2))`}
        >
          <ChatHeader />
        </Container>

        <Container flex={1} maxW="lg" minH={0} py={2}>
          <VStack alignItems="stretch" flexDirection="column-reverse" h="100%" overflow="auto">
            <TypingParticipants />

            <Box
              h={1}
              style={{
                overflowAnchor: 'none',
              }}
            />

            <MessageList />

            {conversationDoc.tripRef ? (
              <TripContext key={conversationDoc.tripRef.id} tripRef={conversationDoc.tripRef} />
            ) : null}
          </VStack>
        </Container>

        <Container maxW="lg" py={2}>
          <MessageInput />
        </Container>
      </VStack>
    </ConversationSnapProvider>
  );
}

export function ChatScreenLoading() {
  const insets = useInsets();

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <ChatHeaderLoading />
      </Container>

      <Container flex={1} maxW="lg" minH={0} py={2}>
        <VStack alignItems="stretch" flexDirection="column-reverse" h="100%" overflow="auto">
          <MessageListLoading />
        </VStack>
      </Container>

      <Container maxW="lg" py={2}>
        <MessageInputLoading />
      </Container>
    </VStack>
  );
}

export default function ChatScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<ChatScreenLoading />}>
        <ChatScreenMain />
      </Suspense>
    </Catch>
  );
}
