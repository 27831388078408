import {
  Box,
  Center,
  Container,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { orderBy, query, where } from 'firebase/firestore';
import { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import { ApplicationStatus, useApplicationsCollectionRef } from '../../collections/Applications';
import {
  ConversationStatus,
  useConversationsCollectionRef,
} from '../../collections/Conversations';
import Catch from '../../components/Catch';
import InsetsProvider, { useInsets } from '../../components/InsetsProvider';
import LogoIcon from '../../components/LogoIcon';
import MyApplicationsSnapProvider from '../../components/snapProviders/MyApplicationsSnapProvider';
import MyConversationsSnapProvider from '../../components/snapProviders/MyConversationsSnapProvider';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import ChatsButton from './ChatsButton';
import ProfileButton from './ProfileButton';
import ReservationsButton from './ReservationsButton';
import TripsButton from './TripsButton';
// import VenturesButton from './VenturesButton';

export function MainLayoutMain() {
  const insets = useInsets();

  const myProfileSnap = useMyProfileSnap();

  const conversationsCollectionRef = useConversationsCollectionRef();
  const applicationsCollectionRef = useApplicationsCollectionRef();

  const { data: applicationsSnap } = useFirestoreCollection(
    query(
      applicationsCollectionRef,
      where('organizerRef', '==', myProfileSnap.ref),
      where('status', '==', ApplicationStatus.SENT),
      orderBy('sentAt', 'desc'),
    ),
  );

  const { data: conversationsSnap } = useFirestoreCollection(
    query(
      conversationsCollectionRef,
      where('participantRefs', 'array-contains', myProfileSnap.ref),
      where('status', '==', ConversationStatus.OPENED),
      orderBy('lastActionAt', 'desc'),
    ),
  );

  useEffect(
    () => {
      const conversationsCount = conversationsSnap.size;
      const applicationsCount = applicationsSnap.size;

      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      if ((navigator as any).setAppBadge && (navigator as any).clearAppBadge) {
        if ((applicationsCount + conversationsCount) > 0) {
          // eslint-disable-next-line max-len
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call
          (navigator as any)
            .setAppBadge(applicationsCount + conversationsCount)
            .catch(() => { });
        } else {
          // eslint-disable-next-line max-len
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call
          (navigator as any)
            .clearAppBadge()
            .catch(() => { });
        }
      }
    },
    [applicationsSnap.size, conversationsSnap.size],
  );

  return (
    <MyApplicationsSnapProvider applicationsSnap={applicationsSnap}>
      <MyConversationsSnapProvider conversationsSnap={conversationsSnap}>
        <VStack alignItems="stretch" gap={0} h="100%">
          <Box flex={1} minH={0} overflow="hidden">
            <InsetsProvider
              bottom="0px"
              left={insets.left}
              right={insets.right}
              top={insets.top}
            >
              <Outlet />
            </InsetsProvider>
          </Box>

          <Container
            flexShrink={0}
            maxW="lg"
            pb={`max(${insets.bottom}, var(--chakra-space-2))`}
            pt={2}
          >
            <HStack gap={2} justifyContent="space-between">
              <TripsButton />
              <ReservationsButton />
              {/* <VenturesButton /> */}
              <ChatsButton />
              <ProfileButton />
            </HStack>
          </Container>
        </VStack>
      </MyConversationsSnapProvider>
    </MyApplicationsSnapProvider>
  );
}

export default function MainLayout() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <MainLayoutMain />
      </Suspense>
    </Catch>
  );
}
