import {
  Avatar,
  Box,
  BoxProps,
  Button,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuHeart } from 'react-icons/lu';

import { ApplicationStatus } from '../../../collections/Applications';
import AppLanguage from '../../../common/AppLanguage';
import { getPhotoSizeUrl } from '../../../common/getPhotoSizeUrl';
import getPrimaryPhoto from '../../../common/getPrimaryPhoto';
import ReservationAlgoliaSearchRecord from '../../../common/ReservationAlgoliaSearchRecord';
import { StoragePicture } from '../../../common/StoragePicture';
import TierMap from '../../../common/TierMap';
import Catch from '../../../components/Catch';
import i18n from '../../../i18n';

export type Props = {
  // eslint-disable-next-line react/require-default-props
  applicationStatus?: ApplicationStatus;
  onSwipeRight: () => void;
  reservationRecord: ReservationAlgoliaSearchRecord;
} & BoxProps;

export function ReservationRowMain({
  applicationStatus, onSwipeRight, reservationRecord, ...boxProps
}: Props) {
  const { t } = useTranslation('TheirReservationsScreen', { keyPrefix: 'Reservation' });

  const src = useMemo(
    () => getPhotoSizeUrl({
      height: 320,
      uri: reservationRecord.venue.pictures[0].imgixUrl,
      width: 180,
    }),
    [reservationRecord.venue.pictures],
  );

  const primaryPictureSrc = useMemo(
    () => {
      const uri = getPrimaryPhoto(
        reservationRecord.organizer.pictures as [StoragePicture],
        reservationRecord.organizer.primaryPictureIndex,
      ).imgixUrl;

      if (!uri) {
        return undefined;
      }

      return getPhotoSizeUrl({
        faces: true,
        height: 16,
        uri,
        width: 16,
      });
    },
    [reservationRecord.organizer.pictures, reservationRecord.organizer.primaryPictureIndex],
  );

  const handleLikeClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      mixpanel.track('Reservation Like Clicked');

      onSwipeRight();
    },
    [onSwipeRight],
  );

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      py={2}
    >
      <HStack
        _dark={{
          backgroundColor: `rgb(from var(--chakra-colors-${TierMap[reservationRecord.venue.tier].colorScheme}-200) r g b / 0.16)`,
        }}
        _light={{
          backgroundColor: `var(--chakra-colors-${TierMap[reservationRecord.venue.tier].colorScheme}-100)`,
        }}
        alignItems="stretch"
        borderRadius="md"
        gap={0}
        h="100%"
        overflow="hidden"
      >
        <Box flexGrow={0} flexShrink={0} h="100%">
          <Image
            h="100%"
            objectFit="cover"
            src={src}
          />
        </Box>

        <VStack alignItems="stretch" flex={1} minW={0} p={3}>
          <HStack>
            <Icon
              _dark={{
                color: `var(--chakra-colors-${TierMap[reservationRecord.venue.tier].colorScheme}-200)`,
              }}
              _light={{
                color: `var(--chakra-colors-${TierMap[reservationRecord.venue.tier].colorScheme}-800)`,
              }}
              as={TierMap[reservationRecord.venue.tier].icon}
              boxSize={5}
            />

            <Text
              _dark={{
                color: `var(--chakra-colors-${TierMap[reservationRecord.venue.tier].colorScheme}-200)`,
              }}
              _light={{
                color: `var(--chakra-colors-${TierMap[reservationRecord.venue.tier].colorScheme}-800)`,
              }}
              flexShrink={1}
              fontSize="xl"
              fontWeight="bold"
              lineHeight={1}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {reservationRecord.venue.name}
            </Text>
          </HStack>

          <HStack>
            <Text
              flexGrow={1}
              flexShrink={1}
              fontSize="14px"
              lineHeight="14px"
              opacity={0.75}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {reservationRecord.venue.city.name}
            </Text>

            <Text
              flexGrow={0}
              flexShrink={0}
              fontSize="14px"
              lineHeight="14px"
              opacity={0.75}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {t('InfoCell.startsAt', {
                formatParams: { startsAt: { dateStyle: 'short', timeStyle: 'short' } },
                startsAt: moment(reservationRecord.startsAt * 1000).toDate(),
              })}
            </Text>
          </HStack>

          <Text
            flexShrink={1}
            fontSize="14px"
            h="80px"
            lineHeight="16px"
            noOfLines={5}
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {/* eslint-disable-next-line max-len */}
            {reservationRecord.translations?.description[i18n.language as AppLanguage] ?? reservationRecord.description}
          </Text>

          <HStack>
            <Avatar
              boxSize={6}
              flexGrow={0}
              flexShrink={0}
              size="xs"
              src={primaryPictureSrc}
            />

            <Text
              flexGrow={1}
              flexShrink={1}
              fontSize="16px"
              lineHeight="24px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {/* eslint-disable-next-line max-len */}
              {reservationRecord.organizer.translations?.name[i18n.language as AppLanguage] ?? reservationRecord.organizer.name}
              {', '}
              {reservationRecord.organizer.age}
            </Text>

            <Button
              colorScheme={TierMap[reservationRecord.venue.tier].colorScheme}
              flexGrow={0}
              flexShrink={0}
              isDisabled={!!applicationStatus}
              leftIcon={<Icon as={LuHeart} />}
              onClick={handleLikeClick}
              size="xs"
            >
              Apply
            </Button>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
}

export function ReservationRowLoading({ ...boxProps }: BoxProps) {
  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      Loading...
    </Box>
  );
}

export default function ReservationRow({
  applicationStatus,
  reservationRecord,
  ...boxProps
}: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={(
        <ReservationRowLoading
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
        />
      )}
      >
        <ReservationRowMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
          applicationStatus={applicationStatus}
          reservationRecord={reservationRecord}
        />
      </Suspense>
    </Catch>
  );
}
