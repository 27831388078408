import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import AppLanguage from '../common/AppLanguage';
import { type StoragePicture } from '../common/StoragePicture';
import { type ProfileDoc } from './Profiles';
import { type VenueDoc } from './Venues';

export enum VentureStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export enum VentureLabel {
  NIGHTCLUB = 'NIGHTCLUB',
}

export type VentureDoc = {
  _v: 1;
  createdAt: Timestamp;
  description: string;
  femaleParticipantPlaces: number;
  femaleParticipants: DocumentReference<ProfileDoc>[];
  indexedAt?: Timestamp;
  instagramTag?: string;
  labels: VentureLabel[];
  maleParticipantPlaces: number;
  maleParticipantPrice: { amount: number, currency: string; };
  maleParticipants: DocumentReference<ProfileDoc>[];
  name: string;
  organizerRef: DocumentReference<ProfileDoc>;
  pictures: StoragePicture[];
  publishedAt: Timestamp;
  startsAt: Timestamp;
  status: VentureStatus;
  translations?: {
    description: Record<AppLanguage, string>;
    name: Record<AppLanguage, string>;
  };
  unpublishedAt?: Timestamp;
  updatedAt: Timestamp;
  venueRef: DocumentReference<VenueDoc>;
};

export const isVentureDoc = (
  doc?: DocumentData,
): doc is VentureDoc => true;

export const isVentureRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<VentureDoc> => ref.parent.id === 'ventures';

function isVentureSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<VentureDoc>;
function isVentureSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<VentureDoc>;
function isVentureSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<VentureDoc> | QueryDocumentSnapshot<VentureDoc> {
  return isVentureRef(snap.ref);
}

export { isVentureSnap };

export const getVenturesCollectionRef = (firestore: Firestore) => collection(firestore, 'ventures') as CollectionReference<VentureDoc>;

export const useVenturesCollectionRef = () => {
  const firestore = useFirestore();
  return getVenturesCollectionRef(firestore);
};
