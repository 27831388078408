import {
  Container,
  Heading,
  HStack,
  Skeleton,
  VStack,
} from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import ConversationList, { ConversationListLoading } from './ConversationList';

export function ChatsScreenMain() {
  useEffect(() => { mixpanel.track_pageview({ screen: 'ChatsScreen' }); }, []);

  const insets = useInsets();

  const { t } = useTranslation('ChatsScreen');

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <VStack alignItems="stretch" gap={0} h="100%">
        <Container
          maxW="lg"
          pb={2}
          pt={`max(${insets.top}, var(--chakra-space-2))`}
        >
          <HStack gap={4}>
            <Heading
              fontSize="4xl"
              lineHeight="40px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {t('title')}
            </Heading>
          </HStack>
        </Container>

        <Container
          flex={1}
          maxW="lg"
          overflowY="auto"
          py={2}
        >
          <ConversationList />
        </Container>
      </VStack>
    </>
  );
}

export function ChatsScreenLoading() {
  const insets = useInsets();

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack gap={4}>
          <Skeleton flex={1} height={10} />
        </HStack>
      </Container>

      <Container
        flex={1}
        maxW="lg"
        overflowY="auto"
        py={2}
      >
        <ConversationListLoading />
      </Container>
    </VStack>
  );
}

export default function ChatsScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<ChatsScreenLoading />}>
        <ChatsScreenMain />
      </Suspense>
    </Catch>
  );
}
