import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export interface Data {
  imageUrl: string;
}

export type Result = Record<string, boolean>;

export default function useValidatePicture() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'validatePicture'), [functions]);
}
