import { DocumentReference } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext } from 'react';

import { ProfileDoc } from '../collections/Profiles';

const OnSwipeLeftContext = createContext<((
  profileRef: DocumentReference<ProfileDoc>
) => void) | null>(null);

const OnSwipeRightContext = createContext<((
  profileRef: DocumentReference<ProfileDoc>
) => void) | null>(null);

export const useOnSwipeLeft = () => useContext(OnSwipeLeftContext);

export const useOnSwipeRight = () => useContext(OnSwipeRightContext);

export type Props = {
  onSwipeLeft: (profileRef: DocumentReference<ProfileDoc>) => void;
  onSwipeRight: (profileRef: DocumentReference<ProfileDoc>) => void;
};

export function SwipeActionsProvider({
  children,
  onSwipeLeft,
  onSwipeRight,
}: PropsWithChildren<Props>) {
  return (
    <OnSwipeLeftContext.Provider value={onSwipeLeft}>
      <OnSwipeRightContext.Provider value={onSwipeRight}>
        {children}
      </OnSwipeRightContext.Provider>
    </OnSwipeLeftContext.Provider>
  );
}
